import React from "react";
import "./about.style.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import Text from "react-text";

class Tnc extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header-about">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-about"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
          <div className="about_wow">
            <div className="know_more">
              {localStorage.getItem("language") != "ar" ? (
                <Text>
                  {" "}
                  <h3>Cancellation Policy</h3>
                  <ul>
                    <li>
                      Tickets once purchased cannot be cancelled, exchanged or
                      refunded.
                    </li>
                    <li>
                      Tickets purchased for a particular multiplex are valid for
                      that multiplex only.
                    </li>
                  </ul>
                  <h3>Privacy Policy</h3>
                  <ul>
                    <li>
                      Children below the age of 18 years cannot be admitted for
                      movies certified 'R18'.
                    </li>
                    <li>
                      Please carry proof of age for movies certified 'R18'.
                    </li>
                    <li>
                      In case a ticket is lost or misplaced, a duplicate ticket
                      cannot be issued.
                    </li>
                    <li>
                      The holder of a physical ticket/E-Ticket with registered
                      Mobile Number of that particular transaction is deemed to
                      be the owner of the ticket.
                    </li>
                    <li>
                      Wow Cinemas does not take responsibility for loss or
                      damage to articles that patrons may unknowingly carry
                      inside the Auditorium.
                    </li>
                  </ul>
                  <h3>Terms and Conditions</h3>
                  <ul>
                    <li>
                      Please purchase tickets for children above the age of 3
                      years.
                    </li>
                    <li>
                      Outside food and beverages are not allowed inside the
                      cinema premises.
                    </li>
                    <li>
                      A convenience fee per ticket is levied on all tickets
                      booked online.
                    </li>
                    <li>
                      Ticket holders are required to abide by the policies laid
                      down by the Wow Cinemas Management.
                    </li>
                    <li>Entry is allowed only for valid ticket holders.</li>

                    <li>
                      Patrons under the influence of alcohol or drugs will not
                      be allowed inside the Cinema Premises.
                    </li>
                    <li>
                      Seat Layout Page for Wow Cinemas is for representational
                      purpose only and actual seat layout might vary.
                    </li>
                    <li>
                      Items like laptop, cameras, knives, weapons, lighter,
                      match box, cigarettes, firearms and all types of
                      inflammable objects are strictly prohibited.
                    </li>
                    <li>
                      Items like carry-bags, eatables, helmets, handbags are not
                      allowed inside the theatres and are strictly prohibited.
                    </li>
                    <li>
                      Entry will be denied for patron/s with any symptom related
                      to COVID-19.
                    </li>
                    <li>
                      All patrons shall be solely responsible and liable for any
                      risks pertaining to the Covid-19 virus upon entry to
                      theatre premises. Wow Cinemas or its Directors, officers,
                      employees or its group companies or insurers and any other
                      related parties shall not be liable directly or indirectly
                      in any manner whatsoever if any patron at any time is
                      infected with the Covid-19 virus or for any injury whether
                      personal or incidental or otherwise and/or for any
                      special, indirect, indirect, punitive, incidental, or
                      consequential damages or any damages whatsoever.
                    </li>
                    <li>Please wear a mask at all times.</li>
                    <li>
                      Non-invasive temperature check is mandatory at Cinema
                      entry.
                    </li>
                    <li>Please follow all social distancing norms.</li>
                  </ul>
                </Text>
              ) : (
                <Text>
                  <h3>سياسة الإلغاء</h3>
                  <ul>
                    <li>
                      التذاكر بمجرد شرائها لا يمكن إلغاؤها أو استبدالها أو
                      استرداد ثمنها
                    </li>
                    <li>
                      التذاكر المشتراة لمضاعفة معينة صالحة لهذا المضاعف فقط
                    </li>
                  </ul>
                  <h3>سياسة الخصوصية</h3>
                  <ul>
                    <li>
                      لا يمكن قبول الأطفال الذين تقل أعمارهم عن 18 عامًا لمشاهدة
                      أفلام مصدق عليها "R18"
                    </li>
                    <li>يرجى إحضار دليل على عمر الأفلام المصدق عليها "R18"</li>
                    <li>
                      في حالة ضياع التذكرة أو وضعها في غير محلها ، لا يمكن إصدار
                      تذكرة مكررة
                    </li>
                    <li>
                      يعتبر حامل التذكرة الفعلية / التذكرة الإلكترونية برقم
                      الهاتف المحمول المسجل لتلك المعاملة المعينة هو مالك
                      التذكرة
                    </li>
                    <li>
                      لا تتحمل Wow Cinemas مسؤولية فقد أو تلف المقالات التي قد
                      يحملها المستفيدون دون قصد داخل القاعة
                    </li>
                  </ul>
                  <h3>سياسة الخصوصية</h3>
                  <ul>
                    <li>يرجى شراء تذاكر للأطفال فوق سن 3 سنوات</li>
                    <li>
                      لا يُسمح بإدخال الأطعمة والمشروبات من الخارج داخل مباني
                      السينما
                    </li>
                    <li>
                      يتم فرض رسوم الراحة لكل تذكرة على جميع التذاكر المحجوزة
                      عبر الإنترنت
                    </li>
                    <li>
                      يتعين على حاملي التذاكر الالتزام بالسياسات المنصوص عليها
                      من قبل إدارة Wow Cinemas
                    </li>
                    <li>الدخول مسموح فقط لحاملي التذاكر الصالحة</li>

                    <li>
                      لن يتم السماح للمستفيدين تحت تأثير الكحول أو المخدرات داخل
                      دور السينما
                    </li>

                    <li>
                      صفحة تخطيط المقعد لـ Wow Cinemas هي لغرض تمثيلي فقط وقد
                      يختلف تخطيط المقعد الفعلي
                    </li>
                    <li>
                      عناصر مثل الكمبيوتر المحمول والكاميرات والسكاكين والأسلحة
                      والولاعة وعلبة الثقاب والسجائر والأسلحة النارية وجميع
                      أنواع الأشياء القابلة للاشتعال ممنوعة منعا باتا
                    </li>
                    <li>
                      لا يُسمح بدخول أغراض مثل الحقائب والأطعمة والخوذات وحقائب
                      اليد داخل المسارح وهي ممنوعة منعا باتا
                    </li>
                    <li>
                      سيتم رفض الدخول للمستفيد / المستفيدين الذين لديهم أي أعراض
                      متعلقة بـ COVID-19
                    </li>
                    <li>
                      يتحمل جميع المستفيدين وحدهم المسؤولية عن أي مخاطر تتعلق
                      بفيروس Covid-19 عند الدخول إلى مباني المسرح. لن تكون Wow
                      Cinemas أو مديريها أو مسؤوليها أو موظفيها أو شركات
                      مجموعتها أو شركات التأمين وأي أطراف أخرى ذات صلة مسؤولة
                      بشكل مباشر أو غير مباشر بأي شكل من الأشكال إذا كان أي
                      مستفيد في أي وقت مصابًا بفيروس Covid-19 أو عن أي إصابة
                      سواء شخصية أو عرضية أو غير ذلك و / أو عن أي أضرار خاصة أو
                      غير مباشرة أو غير مباشرة أو عقابية أو عرضية أو تبعية أو أي
                      أضرار على الإطلاق
                    </li>
                    <li>يرجى ارتداء قناع في جميع الأوقات</li>
                    <li>
                      فحص درجة الحرارة غير الغازية إلزامي عند دخول السينما
                    </li>
                    <li>يرجى اتباع جميع معايير التباعد الاجتماعي</li>
                  </ul>
                </Text>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Tnc;
