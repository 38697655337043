import React from "react";
import "./about.style.css";
import Carousel from "react-multi-carousel";
// import axios from "axios";
import Text from "react-text";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


import 'react-accessible-accordion/dist/fancy-example.css';

class AppFaq extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 1,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };
        return (
            <>
                <div className="header">
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container-banner"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="banner-custom-dot-list-style"
                    >
                        <img
                            src={require("../../assets/about_four.png").default}
                            className="d-block w-100"
                            alt="img"
                        />
                    </Carousel>
                    <div className="about_wow">
                        <div className="know_more">
                            <h3>
                                FAQs

                            </h3>



                            <Accordion allowZeroExpanded={true} className="movie_info">
                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            What are the different types of Movie Ratings?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Please be informed about the details for each rating: G, PG,
                                            PG12, R12, R15 and R18. Please note that it is against the
                                            law for underage people to enter Restricted Movies, even
                                            when accompanied by an adult. You may be asked for
                                            identification to prove your age.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            What is the minimum age to pay for a ticket?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Children under 3 years old can enter the cinema for free.
                                            Please note: this does not entitle the child to a seat and
                                            he/she is expected to sit in the lap of the accompanying
                                            adult. 3D Glasses will need to be rented as an extra.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            What are the entry rules for Junior auditoriums?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Children must be accompanied by an adult at all times.
                                            Adults are not permitted to enter the Junior auditorium
                                            without a child.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Will cinema staff ask for ID for censored movies?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            It is at the sole discretion of cinema staff to ask for
                                            photo Identification as proof of age. You will be refused
                                            entry to the movie if you cannot provide an identification
                                            document to verify your age.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Do Wow Cinemas provide spaces for wheelchairs?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Our cinemas have allocated wheelchair seats in our Premium
                                            auditoriums on the first row of the screens. Wheelchair
                                            seats can be purchased on our app, website, kiosk and
                                            counter.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>



                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I see what subtitles are displayed on a movie?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes, the subtitles are listed on the movie information
                                            pages.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            In which languages do our movies screen?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            We offer movies in English, Hindi, Arabic, Malayalam, Tamil,
                                            Telugu, Tagalog and many more languages.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Where can I find information on upcoming movies?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Please visit our Coming Soon page as it is constantly
                                            updated and includes all the information about the upcoming
                                            movies. Please note that if a movie is not listed here, the
                                            release date of that film has not yet been confirmed. We add
                                            more movies once the dates are confirmed.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I bring food from outside into the cinemas?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Food and beverages purchased outside the cinema are not
                                            permitted inside the auditorium.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I take photos & videos inside the auditorium?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            It is strictly forbidden to record or take photos during the
                                            movie. However, you may take photos and videos before and
                                            after.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I bring shopping bags to the auditorium?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes, shopping bags are permitted inside our auditoriums.
                                            They should not obstruct the passageways in any way.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I book a cinema/party room for a private event?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes, our cinemas cater to any occasion and can be booked
                                            with custom packages for private screenings, conferences,
                                            staff outings or birthday parties. For more information,
                                            send us an email at : raja.kundu@wowcinemas.om{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            {" "}
                                            Can I place my advertisement on the auditorium screens or in
                                            the cinema lobby?{" "}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes, for more information, send us an email at :
                                            raja.kundu@wowcinemas.om{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I refund my tickets?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Tickets booked online or over-the-counter cannot be
                                            refunded. Tickets can be exchanged for different movies
                                            currently being shown at the theater on the same day if
                                            similar category seats are available.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Can I print out my online tickets at the cinema?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes, please visit the kiosk. For further assistance, please
                                            ask customer service executives.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Are there any age restrictions?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            We are following the rules and regulations set forth by the
                                            local government. Customers not adhering to the safety
                                            procedures will be kindly asked to leave the cinema.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            Do I need to wear a face mask?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            Yes. It is mandatory to wear a mask at all times to ensure
                                            your safety and the safety of those around you What physical
                                            distancing measures are you taking? We have introduced a
                                            minimum distancing program in all our auditoriums and lobby..{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className="movie_rating">
                                        <AccordionItemButton >
                                            How many tickets can I book online?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {" "}
                                            You may purchase a maximum of 10 tickets per booking. You
                                            may complete as many bookings as you would like.{" "}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>






                            </Accordion>


                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default AppFaq;