import React from "react";
import Text from "react-text";
import * as Constants from "../../Constants/index";
import "./Offers.style.css";
import axios from "axios";

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // offerspic: false,
      offerstype: "",
      offers: [],
      d: "",
    };
  }

  componentDidMount() {
    let formDataUP = new FormData(); //formdata object
    formDataUP.append("av", Constants.av);
    formDataUP.append("pt", Constants.pt);
    formDataUP.append("did", Constants.did);

    axios.post(Constants.api + "deals/mobile", formDataUP).then((resp) => {
      //  casetype array
      if (resp.data.result === "success") {
        this.setState({
          loading: 0,
          offers: resp.data.output,
        });
        console.log(resp);
      } else {
        console.log(resp.data.output);
        this.setState({
          loading: 0,
        });
      }
      //movie array
    });
  }

  render() {
    // let offerClick = (type) => {
    //   this.setState({
    //     offerspic: true,
    //     offerstype: type,
    //   });
    // };
    return (
      <>
        <div className="offers">
          <div className="container">
            <div class="gray-box">
              <div class="offer">
                <div class="row offer-heading border-bot">
                  <h3>
                    {localStorage.getItem("language") != "ar" ? (
                      "WOW Offers"
                    ) : (
                      <Text> واو عروض </Text>
                    )}
                  </h3>
                </div>
                <div class="row offer-preview-display main-offers-display">
                  {this.state.offers.map(function (item, i) {
                    let t = i + 1;
                    let type = "offer" + t;
                    return (
                      <>
                        <div className="voucher-tags main-offers-tags">
                          <a
                            // onClick={() => offerClick(item.id)}
                            href={`/offerdetail/${item.id}`}
                          >
                            <div className="voucher-tag-html">
                              <div className="voucher-tag-img">
                                <img src={item.image} />
                              </div>
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Offers;
// <div className="offers-pic">
//   <div className="back-button">
//     <a
//       href="#"
//       onClick={() =>
//         this.setState({
//           offerspic: "",
//         })
//       }
//     >
//       {" "}
//       BACK{" "}
//     </a>
//   </div>
//   {this.state.offerspic === "offer1" ? (
//     <div className="offers-pic-container">
//       <img src={require("../../assets/big-offer1.png").default} />
//       <img src={require("../../assets/big-offer2.png").default} />
//     </div>
//   ) : (
//     <div className="offers-pic-container">
//       <img src={require("../../assets/big-offer4.png").default} />
//       <img src={require("../../assets/big-offer3.png").default} />
//     </div>
//   )}
// </div>
