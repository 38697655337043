import React, { Component } from "react";
import Header from "../../components/header/header.component";
import NowShowing from "../../components/now-showing/NowShowing.component";
import UpcomingMovies from "../../components/upcoming-movies/UpcomingMovies.component";
import Trailer from "../../components/trailer/Trailer.component";
import Factors from "../../components/factors/Factors.component";
import Offers from "../../components/offers/Offers.component";
import Top from "../../components/top/Top.component";
import News from "../../components/news/News.component";
import Footer from "../../components/footer/footer.component";
import ProgressBar from "react-bootstrap/ProgressBar";
import Text from "react-text";
import * as Constants from "../../Constants/index";
import axios from "axios";
import "./homepage.style.css";
import TrailerModal from "../../components/trailer/TrailerModal.component";
import Offershome from "../../components/offershome/offershome.component";
import swal from "sweetalert";

class Homepage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    profileOutput: {},
    loyaltyVoucher: [],
    usedloyaltyVoucher: [],
    showTrailer: false,
    tab: this.props ? this.props.tab : 0,
    points: {}
  };
  componentDidMount() {
    // swal({
    //   title: 'Website is Under Maintainance',
    //   text: 'Please visit the cinema and book your tickets. Sorry for the inconvenience caused.',
    //   icon: 'info',
    //   buttons: false,        // Hide the confirm button
    //   timer: 2000000,           // Alert will auto-close after 2 seconds
    //   className: 'small-alert' // Custom class for styling
    // });
    if (this.props && this.props.tab) {
      if (this.state.tab == "ns" || this.state.tab == "up") {
        let e = document.getElementById("now-show");

        e.scrollIntoView();
      }
    }
    const getLoyalty = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/profile", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "string",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                profileOutput: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getLoyalty();
    const getLoyaltyVouchers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/vouchers", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "V",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                loyaltyVoucher: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getLoyaltyVouchers();
    const getusedLoyaltyVouchers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/vouchers", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "U",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                usedloyaltyVoucher: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getusedLoyaltyVouchers();
    const getMemberPoints = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/points", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                points: resp.data.output,
              });
              console.log(resp.data.output)
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getMemberPoints();
  }
  render() {
    console.log(window.location.href.includes("#now-show"));

    return (
      <>
        <div className="homepage">
          <Header />
          <NowShowing tab={this.state.tab} />

          {localStorage.getItem("userid") != 0 &&
            localStorage.getItem("userid") &&
            !localStorage.getItem("guest") ? (

            <div class="wow_club">
              <div class="container">
                <div class="wow_hello">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <div class="wow_text_info">
                        <h1>
                          {localStorage.getItem("language") != "ar" ? (
                            "Hello"
                          ) : (
                            <Text>أهلا</Text>
                          )}{" "}
                          {localStorage.getItem("username")
                            ? localStorage.getItem("username")
                            : ""}

                        </h1>
                        <p>
                          {localStorage.getItem("language") != "ar" ? (
                            "Your Wow Club Earnings"
                          ) : (
                            <Text>أرباحك من نادي واو</Text>
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="wow_points">
                        <div className="gold">
                          <div class="point_img">
                            <img
                              src={require("../../assets/coins.svg").default}
                            />
                            <p>

                              {/* {localStorage.getItem("language") != "ar" ? (
                                "POINTS"
                              ) : (
                                <Text>نقاط</Text>
                              )} */}
                            </p>
                          </div>&nbsp;
                          <div class="point_text">
                            <h1>
                              {" "}
                              {this.state.points
                                ? this.state.points.pointsTxt
                                : ""}

                            </h1>
                          </div>


                        </div>

                        <div className="silver">

                          <div class="point_img point-silver">
                            <img
                              src={require("../../assets/coins-img - Copy.png").default}
                            />
                            <p>
                              {/* {localStorage.getItem("language") != "ar" ? (
                                "TIERS"
                              ) : (
                                <Text>نقاط</Text>
                              )} */}
                            </p>
                          </div>&nbsp;
                          <div class="point_text">
                            <h1>
                              {" "}

                              {/* {this.state.points
                                ? this.state.points.tier
                                : ""}<br />Tier */}
                              {this.state.points
                                ? this.state.points.pntValTxt
                                : ""}
                            </h1>
                          </div>

                        </div>


                      </div>
                    </div>

                    {/* <div class="col-md-3">
                      <div class="wow_points">
                        <div class="point_text">
                          <h1>{this.state.loyaltyVoucher.length}</h1>
                        </div>

                        <div class="point_img">
                          <img
                            src={require("../../assets/voucher.svg").default}
                          />
                          <p>
                            {localStorage.getItem("language") != "ar" ? (
                              "voucher"
                            ) : (
                              <Text>فاتورة</Text>
                            )}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div class="border-line"></div>
                </div>
                {this.state.points.s == "true" ?
                  <>
                    <div class="user_points_win">
                      <div class="user_img">
                        <img src={require("../../assets/user.svg").default} />
                      </div>
                      <div class="point_win">
                        <h2>
                        </h2>
                      </div>

                      <div class="points_text">
                        <p>
                          {localStorage.getItem("language") != "ar" ? (
                            "Earn "
                          ) : (
                            <Text>يكسب </Text>
                          )}
                          {this.state.points.point_left
                            ?
                            '*' + this.state.points.point_left : ''}

                          {localStorage.getItem("language") != "ar" ? (
                            " points more to redeem now"
                          ) : (
                            <Text> نقاط أكثر لاستردادها الآن</Text>
                          )}
                        </p>
                      </div>
                    </div>

                    <div class="flex-parent" style={{ display: "none" }}>
                      {this.state.usedloyaltyVoucher
                        ? this.state.usedloyaltyVoucher.map(function (item, i) {
                          return (
                            <div className="voucher-progress ">
                              <div class="user_img">
                                <img
                                  src={require("../../assets/user.svg").default}
                                />
                              </div>
                              <div className="voucher-img">
                                <img
                                  src={
                                    require("../../assets/voucher.png").default
                                  }
                                  alt="logo"
                                />
                              </div>
                              <ProgressBar
                                now={100}
                                className="voucher-prog-bar"
                              />
                            </div>
                          );
                        })
                        : ""}
                      {this.state.loyaltyVoucher
                        ? this.state.loyaltyVoucher.map(function (item, i) {
                          return (
                            <div className="voucher-progress active">
                              <div class="user_img">
                                <img
                                  src={require("../../assets/user.svg").default}
                                />
                              </div>
                              <div className="voucher-img">
                                <img
                                  src={
                                    require("../../assets/voucher.png").default
                                  }
                                  alt="logo"
                                />
                              </div>
                              <ProgressBar
                                now={100}
                                className="voucher-prog-bar"
                              />
                            </div>
                          );
                        })
                        : ""}
                      <div className="voucher-progress ">
                        <div className="voucher-img">
                          <img
                            src={require("../../assets/voucher.png").default}
                            alt="logo"
                          />
                        </div>
                        <ProgressBar now={0} className="voucher-prog-bar" />
                      </div>
                    </div></>
                  : ''}
              </div>
            </div>




          ) : (
            ""
          )}





          {/* <UpcomingMovies /> */}
          <Trailer />
          <Factors />
          {/* <Offershome /> */}
          <Top />
          <News />
          <Footer />




        </div>
      </>
    );
  }
}

export default Homepage;
