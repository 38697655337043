import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import Loading from "../../Constants/Loading";
import axios from "axios";
import "../moviesessions/MovieSession.css";
import "./upcomingdetails.style.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { FaClock, FaShareAlt } from "react-icons/fa";
import TrailerModal from "../trailer/TrailerModal.component";
import "../movie-details/MovieDetails.style.css";
import moment from "moment";
import MovieInfoDetails from "../movie-info-detail/MovieInfoDetails.component";
import swal from "sweetalert";
import Text from "react-text";
import Modal from "react-modal";

class UpcomingDetails extends Component {
  state = {
    movieid: this.props.match.params.movieid,
    moviedetail: {},
    showTrailer: false,
    trailerUrl: "",
  };

  componentDidMount() {
    let formDataUP = new FormData(); //formdata object
    formDataUP.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataUP.append("av", Constants.av);
    formDataUP.append("pt", Constants.pt);
    formDataUP.append("did", Constants.did);
    formDataUP.append("userid", 0);
    formDataUP.append("lang", "ALL");
    const upcomingDetailsRequest = async () => {
      try {
        this.setState({
          loading: 1,
        });
        const resp = await axios
          .post(Constants.api + "content/comingsoon", formDataUP, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let upcomingArray = [];
              Object.keys(resp.data.output).forEach(function (key) {
                upcomingArray.push(resp.data.output[key]);
              });
              let moviedet = {};
              upcomingArray.forEach((item) => {
                if (this.state.movieid == item.id) {
                  moviedet = { ...item };
                }
              });
              console.log(moviedet);
              this.setState({ moviedetail: moviedet });
              //   this.setState({
              //     upcoming: upcomingArray,
              //   });
              //   console.log("comingsoon");
              //   console.log(this.state.upcoming);
              // } else {
              //   console.log(resp.data.msg);
              //   this.setState({
              //     loading: 0,
              //   });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    upcomingDetailsRequest();
  }
  render() {
    const moviedetailinfo = { ...this.state.moviedetail };
    return (
      <>
        <div className="booking">
          <div
            className="booking-upper upper-upcoming"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url(${moviedetailinfo.mih})`,
            }} /* style={{backgroundImage: `url(${this.state.output.mih})`}} */
          >
            <div
              className="movie-details container"
              // style={{ backgroundImage: `url(${mih})` }}
            >
              <div className="booking-poster">
                <img src={moviedetailinfo.miv} alt="img" />
              </div>
              <div className="booking-movie-details">
                <h3>{moviedetailinfo.n}</h3>
                <p>
                  {/* {len ? <FaClock /> : ""} {len} | {lng} | {cert} | {gnr} */}
                  {localStorage.getItem("language") != "ar" ? (
                    "Starring"
                  ) : (
                    <Text>بطولة</Text>
                  )}
                  : <span className="light-weight">{moviedetailinfo.ca}</span>
                  <br />
                  {localStorage.getItem("language") != "ar" ? (
                    "Director"
                  ) : (
                    <Text>مخرج</Text>
                  )}
                  :&nbsp;
                  <span className="light-weight">{moviedetailinfo.d}</span> |
                  {localStorage.getItem("language") != "ar" ? (
                    "Genre"
                  ) : (
                    <Text>النوع</Text>
                  )}
                  :&nbsp;
                  <span className="light-weight">
                    {moviedetailinfo.genres && moviedetailinfo.genres.length > 0
                      ? moviedetailinfo.genres
                      : moviedetailinfo.genre}
                  </span>{" "}
                  |
                  {localStorage.getItem("language") != "ar" ? (
                    "Language"
                  ) : (
                    <Text>لغة</Text>
                  )}
                  :&nbsp;
                  <span className="light-weight">{moviedetailinfo.lng}</span>
                </p>
                <p>
                  {localStorage.getItem("language") != "ar" ? (
                    "Releasing On"
                  ) : (
                    <Text>الافراج في</Text>
                  )}
                  :{" "}
                  <span className="light-weight">
                    {moment(moviedetailinfo.releaseDate).format("DD MMMM YYYY")}
                  </span>
                </p>
                {moviedetailinfo.t != "" ? (
                  <div>
                    <button
                      className="btn btn-normal"
                      onClick={() =>
                        this.setState({
                          showTrailer: true,
                          trailerUrl: moviedetailinfo.t,
                        })
                      }
                    >
                      <a href="#">
                        {localStorage.getItem("language") != "ar" ? (
                          "Watch Trailer"
                        ) : (
                          <Text>مشاهدة اعلان</Text>
                        )}
                      </a>
                    </button>{" "}
                    <FaShareAlt className="share hidden" />
                  </div>
                ) : (
                  ""
                )}
                {/* <button className="btn btn-normal"><a href="#">Watch Trailer</a></button> <FaShareAlt className="share"/> */}
              </div>
            </div>
          </div>
          <div className="booking-lower lower-upcoming">
            <h3 className="syn-heading">
              {localStorage.getItem("language") != "ar" ? (
                "Synopsis"
              ) : (
                <Text>ملخص</Text>
              )}
              :
            </h3>
            <p>{moviedetailinfo.syn}</p>
          </div>
        </div>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.trailerUrl} />
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default UpcomingDetails;
