const MOVIE_DATA = [
  {
    id: 1,
    title: 'Mission Impossible: Fallout',
    language: 'English, Hindi',
    genre: 'action',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'MIF.png',
    slug: 'MI',
    linkUrl: 'booking/MI',
    releasing: '13 Aug 2020'
  },
  {
    id: 2,
    title: 'Men in Black: International',
    language: 'English',
    genre: 'Sci-fi',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'MIB.png',
    slug: 'MIB',
    linkUrl: 'booking/MIB',
    releasing: '13 Aug 2020'
  },
  {
    id: 3,
    title: 'Deepwater Horizon',
    language: 'English, Hindi',
    genre: 'adventure',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'DH.png',
    slug: 'DH',
    linkUrl: 'booking/DH',
    releasing: '13 Aug 2020'
  },
  {
    id: 4,
    title: 'Toy Story 4',
    language: 'English, Hindi',
    genre: 'animation',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'TS4.png',
    slug: 'TS4',
    linkUrl: 'booking/TS4',
    releasing: '13 Aug 2020'
  },
  {
    id: 5,
    title: 'American Honey',
    language: 'English',
    genre: 'thriller',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'AH.png',
    slug: 'AH',
    linkUrl: 'booking/AH',
    releasing: '13 Aug 2020'
  },
  {
    id: 6,
    title: 'Abominable',
    language: 'English Hindi',
    genre: 'animation',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'Abo.png',
    slug: 'Abominable',
    linkUrl: 'booking/Abominable',
    releasing: '13 Aug 2020'
  },
  {
    id: 7,
    title: 'Glass',
    language: 'English',
    genre: 'action',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'glass.png',
    slug: 'glass',
    linkUrl: 'booking/glass',
    releasing: '13 Aug 2020'
  },
  {
    id: 8,
    title: 'IO',
    language: 'English, Hindi',
    genre: 'adventure',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'io.png',
    slug: 'IO',
    linkUrl: 'booking/IO',
    releasing: '13 Aug 2020'
  },
  {
    id: 9,
    title: 'Lion King',
    language: 'English, Hindi',
    genre: 'animation',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'LK.png',
    slug: 'LK',
    linkUrl: 'booking/LK',
    releasing: '13 Aug 2020'
  },
  {
    id: 10,
    title: 'Secret Obsession',
    language: 'English',
    genre: 'thriller',
    director: 'Anthony and Joe Russo',
    writers: 'Christopher Markus and Stephen McFeely',
    starring: 'Robert Downey Jr., Chris Evans, Mark Ruffalo, Chris Hemsworth, Scarlett Johansson, Jeremy Renner, Don Cheadle, Paul Rudd, Brie Larson, Karen Gillan, Danai Gurira, Benedict Wong, Jon Favreau, Bradley Cooper, Gwyneth Paltrow, and Josh Brolin',
    details: '3h 1min  |  Action, Adventure, Sci-Fi  |  3D  |  English, Hindi',
    imageUrl: 'SO.png',
    slug: 'SO',
    linkUrl: 'booking/SO',
    releasing: '13 Aug 2020'
  }
];

export default MOVIE_DATA;
