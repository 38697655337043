import React from "react";
import SuggestionPreview from "../suggestion-preview/SuggestionPreview.component";
import UPCOMINGMOVIE_DATA from "../upcoming-movies/upcomingmovies.data";
import "./Trailer.style.css";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Text from "react-text";
import { FaPlayCircle } from "react-icons/fa";

class Trailer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: UPCOMINGMOVIE_DATA,
      trailerUrl: "",
      trailers: [],
    };
  }
  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("userid", 0);

    const nowShowingRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/comingsoon", formDataNS, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            console.log(resp);

            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let nowShowingArray = [];
              let trailersArray = resp.data.output;

              // Object.keys(resp.data.output.mv).forEach(function (key) {
              //   trailersArray.push(resp.data.output.mv);
              // });
              this.setState({
                trailers: trailersArray,
                trailerUrl: resp.data.output[0]["t"],
              });
              console.log("trailer");
              console.log(trailersArray);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
        this.setState({
          loading: 1,
        });
      }
    };
    nowShowingRequest();
  }

  handleClickEvent = (trailer) => {
    this.setState({
      trailerUrl: trailer,
    });
  };
  render() {
    const collections = this.state.trailers;
    const playTrailer = (t) => {
      this.setState({ trailerUrl: t });
    };
    return (
      <div className="trailer">
        <div className="trailer-box container">
          <h3>
            {localStorage.getItem("language") != "ar" ? (
              "trailers & videos"
            ) : (
              <Text>المقطورات ومقاطع الفيديو</Text>
            )}
          </h3>
          <div className="display-trailer">
            <div className="video-player">
              <iframe
                src={`https://www.youtube.com/embed/${this.state.trailerUrl}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="suggestions">
              {collections.map(function (item, i) {
                if (i > 0 && i <= 4) {
                  return (
                    <div
                      className="suggestion-preview"
                      onClick={() => playTrailer(item.t)}
                    >
                      <div className="suggestion-box">
                        <div className="suggestion-img">
                          <img
                            src={`https://img.youtube.com/vi/${item.t}/3.jpg`}
                            alt="img"
                          />
                          <FaPlayCircle />
                        </div>
                      </div>
                      <div className="suggestion-detail">
                        <h4>{item.n}</h4>
                        <p></p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trailer;
