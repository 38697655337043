import { React, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../assets/Logo.png";
import search from "../../assets/search.png";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./navbar.styles.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Login from "../login/login.component";

// import { Modal } from "react-bootstrap";
import Modal from "react-modal";

import Dropdown from "react-bootstrap/Dropdown";
import Text from "react-text";
import { NavDropdown } from "react-bootstrap";
import Homepage from "../../pages/homepage/homepage.pages";
// import { NavItem } from "react-bootstrap";
class NavbarMenu extends Component {
  constructor(props) {
    if (localStorage.getItem("userid") == null)
      localStorage.setItem("userid", 0);
    super(props);
    this.state = {
      cities: [],
      lat: 0.0,
      lng: 0.0,
      showLogin: false,
      addnavClass: "",
      showLocation: false,
    };
  }

  // handleChange(event) {
  //     this.setState({value: event.target.value});
  // }
  cityChange = (event) => {
    this.setState({ selectedcity: event.target.value });
    localStorage.setItem("selectedcity", event.target.value);
    //   alert(localStorage.getItem('selectedcity'));
    window.location = "/";
  };
  logout = () => {
    let lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("language", lang);
    window.location = "/";
  };
  setLanguage = (language) => {
    localStorage.setItem("language", language);

    window.location.reload("/");
  };
  listenScrollEvent = (e) => {
    if (document.querySelector("body").scrollTop > 200) {
      this.setState({ addnavClass: "dark-nav" });
      console.log(this.state.addnavClass);
    } else {
      this.setState({ addnavClass: "" });
    }
  };
  componentDidMount() {
    document
      .querySelector("body")
      .addEventListener("scroll", this.listenScrollEvent);
    let formData = new FormData(); //formdata object
    formData.append("country", Constants.country); //append the values with key, value pair
    formData.append("av", Constants.av);
    formData.append("pt", Constants.pt);
    formData.append("userid", localStorage.getItem("userid"));
    formData.append("lat", this.state.lat);
    formData.append("lng", this.state.lng);

    // if (document.location.pathname != "/") {
    //   this.setState({ addnavClass: "sticky" });
    // }

    const sendGetRequest = async () => {
      await this.getMyLocation();
      try {
        const resp = await axios
          .post(Constants.api + "content/cities", formData)
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              if (!localStorage.getItem("selectedcity")) {
                localStorage.setItem("selectedcity", resp.data.output.cc.name);
              }

              let citiesArray = [];
              Object.keys(resp.data.output.ot).forEach(function (key) {
                citiesArray.push(resp.data.output.ot[key]);
              });

              this.setState({
                cities: citiesArray,
              });
              console.log("cities");
              console.log(this.state.cities);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 1,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    // if (!sessionStorage.getItem('selectedcity'))
    // sendGetRequest();
  }
  componentWillUnmount() {
    document
      .querySelector("body")
      .removeEventListener("scroll", this.listenScrollEvent);
  }

  getMyLocation = (e) => {
    let location = null;
    let latitude = null;
    let longitude = null;
    if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;
    }
    if (location) {
      location.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      });
    }
    this.setState({ lat: latitude, lng: longitude });
  };

  render() {
    const citiesDropdownRender = this.state.cities.map(function (item, i) {
      // alert(item.mname);
      let selected =
        item.name == localStorage.getItem("selectedcity") ? "selected" : "";

      return (
        <>
          <option value={item.name} key={i} selected={selected}>
            {item.name}
          </option>
        </>
      );
    });

    const cl = "dark-nav";
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          bsPrefix="navbar"
          className={cl}
        >
          <Navbar.Brand href="/">
            <Link className="logo-container" to="/">
              <div className="logo">
                <img src={logo} alt="img" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto options">
              <a href="/" className="option">
                {localStorage.getItem("language") != "ar" ? (
                  "Home"
                ) : (
                  <Text>الصفحة الرئيسية</Text>
                )}
              </a>
              <a href="/now-showing" className="option">
                {localStorage.getItem("language") != "ar" ? (
                  "Now Showing"
                ) : (
                  <Text>`يعرض الآن`</Text>
                )}
              </a>
              <a href="/upcoming" className="option">
                {localStorage.getItem("language") != "ar" ? (
                  "Upcoming"
                ) : (
                  <Text>القادمة`</Text>
                )}
              </a>
              <a
                // href="https://maps.google.com?q=23.664624,57.903225"
                className="option pointer-cursor"
                href="/locations"
              // onClick={() => {
              //   this.setState(
              //     { showLocation: true },
              //     document
              //       .getElementById("responsive-navbar-nav")
              //       .classList.remove("show")
              //   );
              // }}
              >
                {localStorage.getItem("language") != "ar" ? (
                  "Locations"
                ) : (
                  <Text>موقعك</Text>
                )}
              </a>
              {/* <Link className="option" to="/#wow-factor">
                {localStorage.getItem("language") != "ar" ? (
                  "WOW Factor"
                ) : (
                  <Text> عامل واو </Text>
                )}
              </Link> */}
              <a
                className="option"
                href="/#wow-factor"
                onClick={() =>
                  document
                    .getElementById("responsive-navbar-nav")
                    .classList.remove("show")
                }
              >
                {localStorage.getItem("language") != "ar" ? (
                  "WOW Factor"
                ) : (
                  <Text> عامل واو </Text>
                )}
              </a>
              {/* <Link className="option" to="/about-us">
                {localStorage.getItem("language") != "ar" ? (
                  "Know More "
                ) : (
                  <Text>تعرف أكثر</Text>
                )}
              </Link> */}
              <a className="option" href="/offers">
                {localStorage.getItem("language") != "ar" ? (
                  "WOW Offers"
                ) : (
                  <Text> واو عروض </Text>
                )}
              </a>
              {/* <NavDropdown
                id="nav-dropdown-dark-example"
                title="WOW Offers"
                menuVariant="dark"
                className="option"
              >
                <NavDropdown.Item href="offers">Visa Offers</NavDropdown.Item>
                <NavDropdown.Item href="offers">Ahlibank Offers</NavDropdown.Item>
                <NavDropdown.Item href="offers">Oman Arab Bank Offers</NavDropdown.Item>
                <NavDropdown.Item href="offers">HSBC Offers</NavDropdown.Item>
                <NavDropdown.Item href="offers">NBO Offers</NavDropdown.Item>
                <NavDropdown.Item href="offers">BankDhofar Offers</NavDropdown.Item>
              </NavDropdown> */}

              <div className="select-location select-en">

                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {localStorage.getItem("language") &&
                      localStorage.getItem("language") == "ar" ? (
                      <a
                        href="#"
                        className="lang-box"
                      >
                        Language
                      </a>
                    ) : (
                      <a
                        href="#"
                        className="lang-box"
                      >
                        Language
                      </a>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.setLanguage("en")}>English</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setLanguage("ar")}>Arabic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <img src={search} className="img-search" alt="img" />
                <select
                  class="location-change"
                  value={this.state.value}
                  onChange={this.cityChange}
                >
                  {citiesDropdownRender}
                </select> */}

              </div>
              {/* <div className="select-location2">
                <img src={search} className="img-search" alt="img" />
                <select
                  class="location-change"
                  value={this.state.value}
                  onChange={this.cityChange}
                >
                  {citiesDropdownRender}
                </select>
              </div> */}
              {localStorage.getItem("userid") != 0 ? (
                <Link to="/loyalty">
                  <button className="club">
                    {localStorage.getItem("language") != "ar" ? (
                      "WOW CLUB"
                    ) : (
                      <Text> نادي واو </Text>
                    )}
                  </button>
                </Link>
              ) : (
                <Link onClick={() => this.setState({ showLogin: true })}>
                  <button className="club">
                    {localStorage.getItem("language") != "ar" ? (
                      "WOW CLUB"
                    ) : (
                      <Text> نادي واو </Text>
                    )}
                  </button>
                </Link>
              )}
              <div className="login-button">
                {localStorage.getItem("userid") != 0 ? (
                  <div className="profile-picture">
                    <Dropdown>
                      <Dropdown.Toggle className="profile" id="dropdown-basic">
                        <div className="profile-name">
                          <div className="profile-circle">
                            <i class="fa fa-user" aria-hidden="true"></i>
                          </div>
                          {localStorage.getItem("username")}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#/action-1"
                          className="profile-name-info"
                        >
                          <div className="profile-name">
                            <div className="profile-circle">
                              <i class="fa fa-user" aria-hidden="true"></i>
                            </div>
                            {localStorage.getItem("username")}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="/loyalty">
                          {localStorage.getItem("language") != "ar" ? (
                            "Profile"
                          ) : (
                            <Text>الملف الشخصي</Text>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.logout}>
                          {localStorage.getItem("language") != "ar" ? (
                            "Logout"
                          ) : (
                            <Text>تسجيل خروج</Text>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <>
                    {/* <a
                      href="#"
                      onClick=
                      {}
                    >
                      <img
                        src={require("../../assets/open-menu.png").default}
                        className="hide-mobile"
                      />
                    </a> */}
                  </>
                )}
                <Dropdown className="hide-mobile">
                  <Dropdown.Toggle id="dropdown-basic" className="drop-web">
                    <img
                      src={require("../../assets/open-menu.png").default}
                      className="hide-mobile"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="web-menu-drop">
                    <Dropdown.Item href="/about-us">
                      {" "}
                      {localStorage.getItem("language") != "ar" ? (
                        "About Us "
                      ) : (
                        <Text>معلومات عنا</Text>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item href="/contactus">
                      {localStorage.getItem("language") != "ar" ? (
                        "Contact Us"
                      ) : (
                        <Text>اتصل بنا</Text>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item href="/about-us">
                      {localStorage.getItem("language") != "ar" ? (
                        "Know More "
                      ) : (
                        <Text>تعرف أكثر</Text>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item href="/careers">
                      {" "}
                      {localStorage.getItem("language") != "ar" ? (
                        "Careers"
                      ) : (
                        <Text>وظائف</Text>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item href="/private-screening">
                      {localStorage.getItem("language") != "ar" ? (
                        "Private Screening"
                      ) : (
                        <Text>فحص خاص</Text>
                      )}
                    </Dropdown.Item>
                    {localStorage.getItem("userid") != 0 ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown.Item
                          href="#/action-5"
                          onClick={() => this.setState({ showLogin: true })}
                        >
                          {localStorage.getItem("language") != "ar" ? (
                            "Login/Signup "
                          ) : (
                            <Text>تسجيل الدخول </Text>
                          )}
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {localStorage.getItem("userid") != 0 ? (
                  <></>
                ) : (
                  <>
                    <button
                      className="btn btn-signup hide-desktop"
                      onClick={() => this.setState({ showLogin: true })}
                    >
                      Login / Sign Up
                    </button>
                  </>
                )}
              </div>
            </Nav>
            {/* <Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                    Dank memes
                </Nav.Link>
                </Nav> */}
          </Navbar.Collapse>
        </Navbar>
        {this.state.showLogin ? (
          <>
            <Modal isOpen={this.state.showLogin} portalClassName="login-modal">
              <div class="cross">
                <img
                  src={require("../../assets/cross@2x.png").default}
                  alt="img"
                  onClick={() => this.setState({ showLogin: false })}
                />
              </div>
              <Login />{" "}
            </Modal>
          </>
        ) : (
          ""
        )}

        <>
          <Modal
            isOpen={this.state.showLocation}
            contentLabel="Example Modal"
            // className="loc-modal"
            portalClassName="loc-modal"
          >
            {" "}
            <button
              className="trailer-cross"
              onClick={() => this.setState({ showLocation: false })}
            >
              <i class="fas fa-times"></i>
            </button>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.3168620724505!2d57.90103631497964!3d23.664623984631806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa0470bd20faf79cd!2zMjPCsDM5JzUyLjciTiA1N8KwNTQnMTEuNiJF!5e0!3m2!1sen!2sin!4v1644224171933!5m2!1sen!2sin"
              height="450"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </Modal>
        </>
      </>
    );
  }
}

export default NavbarMenu;
