import React from "react";
import MOVIE_DATA from "./movie.data.js";
//import MoviePreview from "../movie-preview/MoviePreview.component";
// import UpcomingMovies from "../../components/upcoming-movies/UpcomingMovies.component";
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Text from "react-text";
import "./NowShowing.style.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import "../movie-genre/MovieGenre.style.css";
import play from "../../assets/play.png";
import TrailerModal from "../trailer/TrailerModal.component";
import Modal from "react-modal";
import Dropdown from "react-bootstrap/Dropdown";

class NowShowing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: MOVIE_DATA,
      refUrl: window.location.href,
      output: {},
      nowShowing: [],
      upcoming: [],
      loading: 1,
      language: "",
      amcode: "",
      date: "",
      lang: "ALL",
      format: "ALL",
      price: "ALL",
      time: "ALL",
      cinetype: "ALL",
      special: "ALL",
      mname: "",
      lat: 0.0,
      lng: 0.0,
      date: "",
      lang: "ALL",
      format: "ALL",
      price: "ALL",
      time: "ALL",
      cinetype: "ALL",
      special: "ALL",
      hc: "ALL",
      lngs: [],
      cinemas: [],
      days: [],
      selectedDate: "",
      selectedDay: "",
      showTrailer: false,
      days: [],
      bookNow: false,
      bookUrl: "",
      trailer: "",
      genres: [],
      viewAll: true,
      viewupAll: true,
      count: 10,
      upcount: 10,
      refreshupcoming: false,
      tabIndex: this.props.tab == "up" ? 1 : 0,
      ccid: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("userid", 0);

    const nowShowingRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/nowshowing", formDataNS, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            console.log(resp);

            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let nowShowingArray = [];
              let genresArray = [];

              Object.keys(resp.data.output.mv).forEach(function (key) {
                nowShowingArray.push(resp.data.output.mv[key]);
                if (!genresArray.includes(resp.data.output.mv[key]["genres"]))
                  genresArray.push(resp.data.output.mv[key]["genres"]);
              });
              this.setState({
                nowShowing: nowShowingArray,
                genres: genresArray,
              });
              console.log("nowShowing");
              console.log(nowShowingArray);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
        this.setState({
          loading: 1,
        });
      }
    };
    nowShowingRequest();
    let formDataUP = new FormData(); //formdata object
    formDataUP.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataUP.append("av", Constants.av);
    formDataUP.append("pt", Constants.pt);
    formDataUP.append("did", Constants.did);
    formDataUP.append("userid", 0);
    formDataUP.append("lang", "ALL");

    const upcomingRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/comingsoon", formDataUP, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let upcomingArray = [];
              Object.keys(resp.data.output).forEach(function (key) {
                upcomingArray.push(resp.data.output[key]);
              });

              this.setState({
                upcoming: upcomingArray,
              });
              console.log("comingsoon");
              console.log(this.state.upcoming);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    upcomingRequest();
  }
  handleChange = (event) => {
    this.setState({
      genre: event.target.value,
    });
  };
  handleVal = (val) => {
    this.setState({
      genre: val,
    });
  };
  cinemaChange = (event) => {
    this.setState({ ccid: event.target.value })
  }
  movieChange = (event) => {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("mid", event.target.value);

    formDataNS.append("date", "");
    // formDataNS.append('date', '2020-12-09');
    formDataNS.append("userid", 0);
    formDataNS.append("lang", this.state.lang);
    formDataNS.append("format", this.state.format);
    formDataNS.append("price", this.state.price);
    formDataNS.append("time", this.state.time);
    formDataNS.append("cinetype", this.state.cinetype);
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);
    this.setState({ amcode: event.target.value });
    //  this.setState({ date: "" });

    this.movieSessionRequest(formDataNS);
  };
  movieSessionRequest = (formDataNS) => {
    try {
      this.setState({
        loading: 1,
      });
      // const resp =
      axios
        .post(Constants.api + "content/msessionsfilters/", formDataNS)
        .then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            this.setState({
              output: resp.data.output,
            });
            let lngsArray = [];
            Object.keys(resp.data.output.lngs).forEach(function (key) {
              lngsArray.push(resp.data.output.lngs[key]);
            });

            let daysArray = [];
            Object.keys(resp.data.output.dys).forEach(function (key) {
              daysArray.push(resp.data.output.dys[key]);
            });

            let sDay = "";
            let sD = "";
            Object.keys(daysArray).forEach(function (item, index) {
              if (index == 0) {
                console.log(daysArray[index].wd);
                sDay = daysArray[index].wd;
                sD = daysArray[index].d;
                return;
              }
            });

            let cinemasArray = [];
            Object.keys(resp.data.output.cinemas).forEach(function (key) {
              cinemasArray.push(resp.data.output.cinemas[key]);
            });

            this.setState({
              days: daysArray,
            });
            this.setState({ bookNow: false, bookUrl: "" });
            document.getElementById("movietime").value = "";
            document.getElementById("moviedate").value = "";
          } else {
            console.log(resp.data.msg);
            //  alert(resp.data.msg);
            this.setState({
              loading: 0,
            });
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  selectDate = (event) => {
    this.setState({ date: event.target.value });
    let formDataDS = new FormData(); //formdata object
    formDataDS.append("mid", this.state.amcode);
    formDataDS.append("date", event.target.value);
    // formDataDS.append('date', '2020-12-09');
    formDataDS.append("userid", 0);
    formDataDS.append("lang", this.state.lang);
    formDataDS.append("format", this.state.format);
    formDataDS.append("price", this.state.price);
    formDataDS.append("time", this.state.time);
    formDataDS.append("cinetype", this.state.cinetype);
    formDataDS.append("av", Constants.av);
    formDataDS.append("pt", Constants.pt);
    formDataDS.append("did", Constants.did);
    try {
      this.setState({
        loading: 1,
      });
      // const resp =
      axios
        .post(Constants.api + "content/msessionsfilters/", formDataDS)
        .then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            let cinemasArray = [];
            Object.keys(resp.data.output.cinemas).forEach(function (key) {
              cinemasArray.push(resp.data.output.cinemas[key]);
            });

            this.setState({
              cinemas: cinemasArray,
            });

            console.log(this.state.cinemas);
            // var element = document.getElementById('otherDateFilter');
            // element.classList.remove("active");
          } else {
            console.log(resp.data.msg);
            // alert(resp.data.msg);
            this.setState({
              loading: 0,
            });
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  selectTime = (event) => {
    let urls = event.target.value.split("|");
    let bookUrl =
      "/seatlayout/" +
      urls[0] +
      "/movie?mcode=" +
      urls[1] +
      "&sessionid=" +
      urls[2] +
      "&cinemacode=" +
      urls[3] +
      "&screenId=" +
      urls[4] +
      "&bundleAllowed=false";
    this.setState({ bookNow: true, bookUrl: bookUrl });
  };

  setTabIndex = (index) => {
    this.setState({ tabIndex: index });
  };
  selectLanguage = (language) => {
    if (language == '')
      this.setState({

        viewAll: true, count: 10, language: language
      });
    else
      this.setState({
        viewAll: true, count: 100, language: language
      })
    document.getElementById("now-show").scrollIntoView();
  };
  moveToMovies = () => {
    const blue = document.getElementById("now-show");
    let position = blue.getBoundingClientRect();
    // scrolls to 20px above element
    window.scrollTo(position.left, position.top + window.scrollY - 60);
  };
  render() {
    let language = this.state.language;
    let count = 0;
    let c = this.state.count;
    let upcount = 0;
    let uc = this.state.upcount;
    let MovieGenre2 = ({ genre, trailer, release }) => (
      <>
        {" "}
        {/* <div className="movie-genre">
          <p>{genre}</p> */}
        <div className="up-genre-contain">
          {trailer != "" ? (
            <a
              onClick={() => {
                this.setState({ showTrailer: true, trailerUrl: trailer });
              }}
            >
              {/* <img className="icon" src={play} alt="" /> */}
              <p className="up-genre">{genre}&nbsp;</p>
              <button className="club">
                {localStorage.getItem("language") != "ar" ? (
                  "Show trailer"
                ) : (
                  <Text>عرض مقطورة</Text>
                )}
              </button>
            </a>
          ) : (
            ""
          )}
        </div>
        {/* </div> */}
      </>
    );
    let upcoming = this.state.upcoming
      .filter((item, idx) => idx < uc)
      .map(function (item, i) {
        let otherCollectionProps = {
          id: i,
          title: item.n,
          language: item.lng,
          genre: item.genre,
          director: item.mdirector,
          writers: item.writer,
          starring: item.martists,
          details: item.mlength,
          imageUrl: item.miv,
          //  imageUrl: "NTTD.jpg",
          slug: "MIB",
          linkUrl: "",
          releasing: item.releaseDate,
          trailer: item.t,
        };
        upcount = upcount + 1;
        let upcomingUrl = `/upcomingdetails/${item.id}`;
        let lng = item.lng;
        if (
          language != "" &&
          lng != undefined &&
          language.toLowerCase().includes(lng.toLowerCase()) == true
        ) {
          if (upcount <= uc) {
            return (
              <>
                <div className="release">
                  <div className="img-showing">
                    <a href={upcomingUrl}>
                      <img src={item.miv} />
                    </a>

                    <MovieGenre2
                      genre={
                        item.genres && item.genres.length > 0
                          ? item.genres
                          : item.genre
                      }
                      trailer={item.t}
                      release={item.releaseDate}
                    />
                  </div>
                  <h4>{item.n}</h4>
                  {item.releaseDate !== undefined ? (
                    <>
                      <p className="releasing">
                        <span className="release-on">
                          {localStorage.getItem("language") != "ar" ? (
                            "Releasing On"
                          ) : (
                            <Text>الافراج في</Text>
                          )}
                        </span>{" "}
                        <br />
                        <span className="release-date">
                          {moment(item.releaseDate).format("DD MMMM YYYY")}
                        </span>
                      </p>
                      {/* <a href={upcomingUrl} className="upmore-info">
                  {localStorage.getItem("language") != "ar" ? (
                    "More Info"
                  ) : (
                    <Text>مزيد من المعلومات</Text>
                  )}
                  &nbsp;
                  <FaLongArrowAltRight />
                </a> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          }
        }
        if (language == "") {
          return (
            <>
              <div className="release">
                <div className="img-showing">
                  <a href={upcomingUrl}>
                    <img src={item.miv} />
                  </a>

                  <MovieGenre2
                    genre={
                      item.genres && item.genres.length > 0
                        ? item.genres
                        : item.genre
                    }
                    trailer={item.t}
                    release={item.releaseDate}
                  />
                </div>
                <h4>{item.n}</h4>
                {item.releaseDate !== undefined ? (
                  <>
                    <p className="releasing">
                      <span className="release-on">
                        {localStorage.getItem("language") != "ar" ? (
                          "Releasing On"
                        ) : (
                          <Text>الافراج في</Text>
                        )}
                      </span>{" "}
                      <br />
                      <span className="release-date">
                        {moment(item.releaseDate).format("DD MMMM YYYY")}
                      </span>
                    </p>
                    {/* <a href={upcomingUrl} className="upmore-info">
                    {localStorage.getItem("language") != "ar" ? (
                      "More Info"
                    ) : (
                      <Text>مزيد من المعلومات</Text>
                    )}
                    &nbsp;
                    <FaLongArrowAltRight />
                  </a> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
      });
    console.log(this.state.refUrl);

    let MovieGenre = ({
      genre,
      history,
      linkUrl,
      match,
      trailer,
      certificate,
    }) => (
      <>
        {" "}
        <div className="movie-genre">
          <p>
            {genre}&nbsp;| {certificate}
          </p>

          <div className="book">
            <a href={linkUrl} className="club book-now">
              {localStorage.getItem("language") != "ar" ? (
                "Book now"
              ) : (
                <Text>احجز الآن</Text>
              )}
            </a>
            {trailer != "" ? (
              <a
                onClick={() => {
                  this.setState({ showTrailer: true, trailerUrl: trailer });
                }}
              >
                <img className="icon" src={play} alt="img" />
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="trailer-btn">
            {trailer != "" ? (
              <button className="club book-now">
                <a>Watch Trailer</a>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );

    let MoviePreview = ({
      title,
      language,
      release,
      imageUrl,
      genre,
      linkUrl,
      certificate,
      trailer,
    }) => (
      <div className={`${release ? "release" : ""} movie-preview`}>
        <div className="img-showing">
          <a href={linkUrl}>
            <img src={imageUrl} />
          </a>
          <MovieGenre
            genre={genre}
            linkUrl={linkUrl}
            trailer={trailer}
            certificate={certificate}
          />
        </div>
        <h4>{title}</h4>
        {release ? (
          <p className="releasing">
            Releasing On <br />
            <span className="release-date">{release}</span>
          </p>
        ) : (
          <p>{language}</p>
        )}
      </div>
    );
    let nowshowing = this.state.nowShowing
      .filter((item, idx) => idx < c)
      .map(function (item, i) {
        let nameForUrl = "";
        if (item.n != undefined)
          nameForUrl = item.n.toString().toLowerCase().replaceAll(" ", "-");
        else nameForUrl = "";

        let otherCollectionProps = {
          id: i,
          title: item.n,
          language: item.lng,
          genre: item.genre,
          director: item.mdirector,
          writers: "",
          starring: item.martists,
          details: item.mlength,
          certificate: item.ce,
          imageUrl: item.miv,
          slug: "MIB",
          linkUrl:
            "/moviesessions/" +
            nameForUrl +
            "/" +
            item.id +
            "?language=" +
            item.lng,
          releasing: item.mopeningdate,
          trailer: item.t,
        };
        let lng = item.lng;
        if (
          language != "" &&
          lng != undefined &&
          language.toLowerCase().includes(lng.toLowerCase()) == true
        ) {
          //  count = count + 1;
          if (count <= c) {
            return (
              <>
                <MoviePreview key={i} {...otherCollectionProps} />
              </>
            );
          }
        }
        if (language == "") {
          //  count = count + 1;
          if (count <= c) {
            return (
              <>
                <MoviePreview key={i} {...otherCollectionProps} />
              </>
            );
          }
        }
      });
    const { collections } = this.state;
    console.log(this.state.trailer);
    let ccid = this.state.ccid;
    return (
      <>
        <div id="now-show"></div>
        <div id="upcoming"></div>
        <div className="now-showing container">
          <div className="now-showing-top">
            {this.state.tabIndex === 0 ? (
              <>
                <div
                  className="now-show-tab active"
                  onClick={() => this.setState({ tabIndex: 0, language: "" })}
                >
                  <a onClick={() => this.moveToMovies()}>
                    <h2>
                      {localStorage.language != "ar" ? (
                        "Now Showing"
                      ) : (
                        <Text>{`يعرض الآن`}</Text>
                      )}
                    </h2>
                  </a>
                </div>
                <div
                  className="now-show-tab"
                  onClick={() => this.setState({ tabIndex: 1, language: "" })}
                >
                  <a onClick={() => this.moveToMovies()}>
                    <h2>
                      {localStorage.getItem("language") != "ar" ? (
                        "Upcoming"
                      ) : (
                        <Text>القادمة</Text>
                      )}
                    </h2>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div
                  className="now-show-tab"
                  onClick={() => this.setState({ tabIndex: 0, language: "" })}
                >
                  <a onClick={() => this.moveToMovies()}>
                    <h2>
                      {localStorage.language != "ar" ? (
                        "Now Showing"
                      ) : (
                        <Text>{`يعرض الآن`}</Text>
                      )}
                    </h2>
                  </a>
                </div>
                <div
                  className="now-show-tab active"
                  onClick={() => this.setState({ tabIndex: 1, language: "" })}
                >
                  <a onClick={() => this.moveToMovies()}>
                    <h2>
                      {localStorage.getItem("language") != "ar" ? (
                        "Upcoming"
                      ) : (
                        <Text>القادمة</Text>
                      )}
                    </h2>
                  </a>
                </div>
              </>
            )}
            <div className="now-show-tab">
              <Dropdown>
                {" "}
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img
                    src={require("../../assets/filter-filled.png").default}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "All Languages"
                    ) : (
                      <Text>كل اللغات</Text>
                    )}{" "}
                    {this.state.language == "" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Hindi")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Hindi"
                    ) : (
                      <Text>هندي</Text>
                    )}{" "}
                    {this.state.language == "Hindi" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Arabic")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Arabic"
                    ) : (
                      <Text>عربي</Text>
                    )}{" "}
                    {this.state.language == "Arabic" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("English")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "English"
                    ) : (
                      <Text>إنجليزي</Text>
                    )}{" "}
                    {this.state.language == "English" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Tamil")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Tamil"
                    ) : (
                      <Text>التاميل</Text>
                    )}{" "}
                    {this.state.language == "Tamil" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Telugu")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Telugu"
                    ) : (
                      <Text>التيلجو</Text>
                    )}{" "}
                    {this.state.language == "Telugu" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Malayalam")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Malayalam"
                    ) : (
                      <Text>المالايالامية</Text>
                    )}{" "}
                    {this.state.language == "Malayalam" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Japanese")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Japanese"
                    ) : (
                      <Text>اليابانية</Text>
                    )}{" "}
                    {this.state.language == "Japanese" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => this.selectLanguage("Thai")}
                  >
                    {localStorage.getItem("language") != "ar" ? (
                      "Thai"
                    ) : (
                      <Text>التايلاندية</Text>
                    )}{" "}
                    {this.state.language == "Thai" ? (
                      <i class="fa fa-check"></i>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {this.state.tabIndex === 0 ? (
            <>
              <div className="movie-preview-collection">
                {this.state.nowShowing.length > 0
                  ? nowshowing
                  : "No Movies Available"}
              </div>
              {this.state.nowShowing.length > 0 && this.state.language == "" ? (
                this.state.nowShowing.length > 10 && this.state.viewAll ? (
                  <div
                    className="row view-button"
                    onClick={() =>
                      this.setState({
                        count: 100,
                        viewAll: false,
                        language: "",
                      })
                    }
                  >
                    <button className="btn btn-ghost view-btn">
                      <a className="pointer-cursor" href="#now-show">
                        {localStorage.getItem("language") != "ar" ? (
                          "VIEW ALL"
                        ) : (
                          <Text>عرض الكل</Text>
                        )}

                        <span className="icons-arr">
                          <FaLongArrowAltRight />
                        </span>
                      </a>
                    </button>
                  </div>
                ) : (
                  <div
                    className="row view-button"
                    onClick={() => this.setState({ count: 10, viewAll: true })}
                  >
                    <button className="btn btn-ghost view-btn">
                      <a className="pointer-cusror">
                        {localStorage.getItem("language") != "ar" ? (
                          "VIEW LESS"
                        ) : (
                          <Text>عرض أقل</Text>
                        )}
                        <span className="icons-arr">
                          <FaLongArrowAltRight />
                        </span>
                      </a>
                    </button>
                  </div>
                )
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="up-com">
                <div className="upcoming-movies upcom-nowhowing">
                  <div className="show-upcoming">{upcoming}</div>
                  {this.state.upcoming.length > 0 &&
                    this.state.language == "" ? (
                    this.state.upcoming.length > 10 && this.state.viewupAll ? (
                      <div
                        className="row view-button"
                        onClick={() =>
                          this.setState({
                            upcount: 100,
                            viewupAll: false,
                            language: "",
                          })
                        }
                      >
                        <button className="btn btn-ghost view-btn">
                          <a className="pointer-cursor" href="#now-show">
                            {localStorage.getItem("language") != "ar" ? (
                              "VIEW ALL"
                            ) : (
                              <Text>عرض الكل</Text>
                            )}

                            <span className="icons-arr">
                              <FaLongArrowAltRight />
                            </span>
                          </a>
                        </button>
                      </div>
                    ) : (
                      <div
                        className="row view-button"
                        onClick={() =>
                          this.setState({ upcount: 10, viewupAll: true })
                        }
                      >
                        <button className="btn btn-ghost view-btn">
                          <a className="pointer-cusror">
                            {localStorage.getItem("language") != "ar" ? (
                              "VIEW LESS"
                            ) : (
                              <Text>عرض أقل</Text>
                            )}
                            <span className="icons-arr">
                              <FaLongArrowAltRight />
                            </span>
                          </a>
                        </button>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  {/* </Carousel> */}
                  {/* </div> */}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="quick-book-btn-home">
          {!this.state.showQuickBook ? (
            <button
              className="btnc btnc-quick "
              onClick={() => this.setState({ showQuickBook: true })}
            >
              {localStorage.language != "ar" ? (
                "QUICK BOOK"
              ) : (
                <Text>كتاب سريع</Text>
              )}
            </button>
          ) : (
            <button
              className="btnc btnc-cross"
              onClick={() => this.setState({ showQuickBook: false })}
            >
              <i class="fas fa-times"></i>
            </button>
          )}
        </div>
        {this.state.showQuickBook ? (
          <div className="quick-book-container">
            <div className="quick-book-head">
              <h3>
                {localStorage.language != "ar" ? (
                  "QUICK BOOK"
                ) : (
                  <Text>كتاب سريع</Text>
                )}
              </h3>
            </div>
            <div className="select-genre select-quick">
              <select class="movie-change" onChange={this.movieChange}>
                <option>
                  {localStorage.language != "ar"
                    ? "Select Movie"
                    : "حدد الفيلم"}
                </option>
                {this.state.nowShowing.map(function (item, i) {
                  return <option value={item.id}>{item.n}</option>;
                })}
              </select>
            </div>
            <div className="select-genre select-quick">
              <select
                class="movie-change"
                id="moviedate"
                onChange={this.selectDate}
              >
                <option value="">
                  {localStorage.language != "ar" ? "Select Date" : "حدد تاريخ"}
                </option>
                {this.state.days.map(function (item, i) {
                  return (
                    <option value={item.dt}>
                      {moment(item.dt).format("DD MMMM YYYY")}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="select-genre select-quick">
              <select class="movie-change" onChange={this.cinemaChange}>
                <option>
                  {localStorage.language != "ar"
                    ? "Select Cinema"
                    : "اختر السينما"}
                </option>
                {this.state.cinemas.map(function (item, i) {
                  return <option value={item.cid}>{item.cn}</option>;
                })}
              </select>
            </div>
            <div className="select-genre select-quick">
              <select
                class="movie-change"
                id="movietime"
                onChange={this.selectTime}
              >
                <option value="">
                  {localStorage.language != "ar" ? "Select Time" : "حدد الوقت"}
                </option>
                {this.state.cinemas.map(function (mcinema, i) {
                  if (ccid != '' && ccid == mcinema.cid) {
                    return (
                      <>
                        {mcinema.childs.map((cinema, j) => {
                          return (
                            <>
                              {cinema.sws.map((sws, k) => {
                                return (
                                  <>
                                    {sws.s.map((show, l) => {
                                      if (show.ss == 1) {
                                        return (
                                          <>
                                            <option
                                              value={`${mcinema.cn
                                                .toString()
                                                .toLowerCase()
                                                .replaceAll(" ", "-")}|${show.mc
                                                }|${show.sid}|${cinema.ccid}|${show.sn
                                                }`}
                                            >
                                              {show.st}
                                              {/* </a> */}
                                            </option>
                                          </>
                                        );
                                      }
                                    })}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  }
                })}

              </select>
            </div>
            <div className="book-now-btn">
              {this.state.bookNow ? (
                <a href={this.state.bookUrl}>
                  <button className="btnc btnc-primary">
                    {localStorage.getItem("language") != "ar" ? (
                      "Book now"
                    ) : (
                      <Text>احجز الآن</Text>
                    )}
                  </button>
                </a>
              ) : (
                <button className="btnc btnc-primary" disabled>
                  {localStorage.getItem("language") != "ar" ? (
                    "Book now"
                  ) : (
                    <Text>احجز الآن</Text>
                  )}
                </button>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.trailerUrl} />
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default NowShowing;
