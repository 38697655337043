import React from "react";
import FOOTER_DATA from "./footer.data";
import FooterPreview from "../footer-preview/FooterPreview.component";
import {
  FaTwitter,
  FaFacebook,
  FaYoutube,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import logo from "../../assets/Logo.png";
import "./footer.css";
import Text from "react-text";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: FOOTER_DATA,
    };
  }
  render() {
    const { collections } = this.state;
    return (
      <div className="footer">
        <div className="footer-content-collection">
          <div className="footer-preview">
            <h4 className="title">
              {localStorage.getItem("language") != "ar" ? (
                "WOW Cinema"
              ) : (
                <Text>واو سينما</Text>
              )}
            </h4>
            <div className="logo">
              <img src={logo} />
            </div>
          </div>
          <div className="copy-right">
            <div className="icons">
              <ul>
                <li className="icons-info">
                  <a
                    href="https://www.facebook.com/wowcinemas.om/?hc_ref=ARSy74ErMVGf4dYj4Q9t_-9tyAd3b0NbAnh_LETuUHIjsVNJaywIgZoYnVxrkbhNgNs&fref=nf&__xts__[0]=68.ARC6kUglY4Ra3-50AlUwvMXzRrR5mvw-vw5pbjjF0jfh6kgkJU5rslrMqSYz28Bb5cCXiwNqd2m9sZ3DWmU3AMOCvygvZYV5FxfM6vlBNBRxJutBPrZW-OivROeqe5SKsCJiH59n_FoHlabQIf0G37z48k4TaU9fJn4u-2ExmYEPIrX83NirKaF-N2IN0HEF6dmb_uphmBsskrbaMYAxXlSen-WytdLlzbKc458WuxvOjELJZFrLqBJ626-WwXPvQoiH9LseWC4oBDJAaOJHfr8l5nK2o6AHXzKZSWNKjD1SKIO3N9c"
                    target="_blank"
                  >
                    <FaFacebook />
                  </a>
                </li>

                <li className="icons-info">
                  <a
                    href="https://instagram.com/wowcinemas.om?utm_medium=copy_link"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  {/* <a href="#">
                    <FaTwitter />
                  </a> */}
                </li>
                <li>
                  {/* <a href="#">
                    <FaYoutube />
                  </a> */}
                </li>
                <li>
                  {/* <a href="#">
                    <FaLinkedin />
                  </a> */}
                </li>
              </ul>
            </div>
            <div className="copyright-right">
              <p>
                {localStorage.getItem("language") != "ar" ? (
                  <Text>Copyright &#169; {(new Date().getFullYear())}  Grand Entertainment LLC. All Rights Reserved.</Text>
                ) : (
                  <> &#169;	 <Text> حقوق النشر لشركة Grand Entertainment LLC {(new Date().getFullYear())}. كل الحقوق محفوظة.</Text></>
                )}
              </p>
              <p>
                <a href="/about-us">
                  {localStorage.getItem("language") != "ar" ? (
                    "About Us "
                  ) : (
                    <Text>معلومات عنا</Text>
                  )}
                </a>{" "}
                |{" "}
                <a href="/faq">
                  {" "}
                  {localStorage.getItem("language") != "ar" ? (
                    "FAQs"
                  ) : (
                    <Text>أسئلة وأجوبة</Text>
                  )}
                </a>{" "}
                |
                <a href="/terms-and-conditions">
                  {localStorage.getItem("language") != "ar" ? (
                    "Terms & Conditions "
                  ) : (
                    <Text>البنود و الظروف</Text>
                  )}
                </a>{" "}
                |
                <a href="/privacy-policy">
                  {localStorage.getItem("language") != "ar" ? (
                    "Privacy Policy "
                  ) : (
                    <Text>سياسة خاصة</Text>
                  )}
                </a>{" "}
                |
                <a href="/careers">
                  {localStorage.getItem("language") != "ar" ? (
                    "Careers"
                  ) : (
                    <Text>وظائف</Text>
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="seperator"></div>
        <div className="copyright-section">
          <div className="copy-left">
            <p>
              {localStorage.getItem("language") != "ar" ? (
                "Design and Developed By"
              ) : (
                <Text>تصميم و تطوير</Text>
              )}{" "}
              <a href="https://www.wemonde.com" target="_blank">
                <span className="wem-main">Wemonde</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
