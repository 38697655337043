import React from "react";
import "./about.style.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import Text from "react-text";

class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-banner"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
          <div className="about_wow">
            <div className="know_more">
              <h3>WOW Cinemas</h3>
              <p>
                <Text>
                  {" "}
                  WOW cinemas is part of Grand Entertainment LLC is registered
                  in the Sultanate of Oman as a wholly Omani owned limited
                  liability company, which is part of Al Tamman Investments LLC.
                </Text>
              </p>
              <img
                src={require("../../assets/about_one.jpg").default}
                alt="img"
              />
            </div>
            <div className="about_text">
              <p>
                <Text>
                  {" "}
                  Grand Entertainment LLC conceptualized home grown cinema brand
                  Wow cinemas , which is a luxury cinema with affordable price
                  and the first 6 screen multiplex is at Barka. Grand
                  Entertainment LLC also has the Active Entertainment concept of
                  Funtopia and Holoverse ,holographic gaming concepts under its
                  portfolio.Grand Entertainment managed by professional team
                  with good domain experience. ALtamman Group is managed by the
                  professional team with diverse experiences in multiple sectors
                  spanning into portfolio management, real estate development,
                  project management, and private equity investments domains,
                </Text>
              </p>
            </div>
            <div className="about_img">
              <img
                src={require("../../assets/about_img.png").default}
                alt="img"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default About;
