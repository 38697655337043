import React from "react";
import Text from "react-text";
import "./MovieInfoDetails.style.css";

const MovieInfoDetails = ({ od, p, ca, d, len, lng }) => (
  <div className="movie-info-detail">
    <div className="movie-info-left">
      {localStorage.getItem("language") != "ar" ? "Release" : <Text>يطلق</Text>}
      :
    </div>
    <div className="movie-info-right">{od}</div>
    {/* <div className="movie-info-left">Duration:</div>
    <div className="movie-info-right">{len}</div> */}
    {/* <div className="movie-info-left">Language: </div>
    <div className="movie-info-right">{lng}</div> */}
    <div className="movie-info-left">
      {localStorage.getItem("language") != "ar" ? (
        "Director"
      ) : (
        <Text>مخرج</Text>
      )}
      :
    </div>
    <div className="movie-info-right">{d}</div>
    <div className="movie-info-left">
      {localStorage.getItem("language") != "ar" ? (
        "Starring"
      ) : (
        <Text>بطولة</Text>
      )}
      :
    </div>
    <div className="movie-info-right">{ca}</div>
    {/* <div className="movie-info-left">
      {localStorage.getItem("language") != "ar" ? (
        "Synopsis"
      ) : (
        <Text>ملخص</Text>
      )}
      :
    </div>
    <div className="movie-info-right">{p}</div> */}
    {/* <p class="movie-about">{p}</p> */}
  </div>
);

export default MovieInfoDetails;
