import React, { Component } from "react";
import "./loyalty.style.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import * as Constants from "../../Constants";
import axios from "axios";
import swal from "sweetalert";
import SeatLayout from "../seat-layout/SeatLayout.component";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Text from "react-text";
import Dropdown from "react-bootstrap/Dropdown";
import parse from 'html-react-parser';
import DatePicker from 'react-date-picker';
import moments from "moment";
import 'react-date-picker/dist/DatePicker.css';
import SimpleReactValidator from "simple-react-validator";

class Loyalty extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

  }
  state = {
    history: [],
    editProfile: false,
    name: localStorage.getItem("username"),
    email: localStorage.getItem("useremail"),
    profileOutput: {},
    loyaltyHistory: [],
    loyaltyVoucher: [],
    current: [],
    expiryDate: "",
    loyaltyVoucherCount: 0,
    points: {},
    dob: "",
    doa: "",
    gender: "",
    maritalStatus: ""

  };
  logout = () => {
    localStorage.clear();
    window.location = "/";
  };

  componentDidMount() {
    const getLoyalty = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/profile", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "string",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                profileOutput: resp.data.output,
                dob: new Date(resp.data.output.dob),
                doa: new Date(resp.data.output.doa),
                gender: resp.data.output.gender,
                maritalStatus: resp.data.output.maritalStatus,


              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getLoyalty();
    const getLoyaltyHistory = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/history", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "string",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                loyaltyHistory: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getLoyaltyHistory();
    const getLoyaltyVouchers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/vouchers", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                loyaltyVoucher: resp.data.output,
                expiryDate: resp.data.output[0].expiryDate,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getLoyaltyVouchers();
    const getMemberPoints = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/points", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                points: resp.data.output,
              });
              console.log(resp.data.output)
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getMemberPoints();
    const getfreeLoyaltyVouchers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/vouchers", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "V",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                loyaltyVoucherCount: resp.data.output.length,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getfreeLoyaltyVouchers();
    let formDataBH = new FormData(); //formdata object
    formDataBH.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
    formDataBH.append("av", Constants.av);
    formDataBH.append("pt", Constants.pt);

    const getBookingHistory = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "history/myticket", formDataBH)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output.c);

              this.setState({
                current: resp.data.output.c,
                history: resp.data.output.p,
              });
              console.log(resp.data.output.c);
            } else {
              //console.log(resp.data.msg);
              //alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getBookingHistory();

    let formDataPref = new FormData(); //formdata object
    formDataPref.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
    formDataPref.append("city", localStorage.getItem("selectedcity"));
    formDataPref.append("av", Constants.av);
    formDataPref.append("pt", Constants.pt);
    formDataPref.append("did", Constants.did);

    const getPreferences = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "/api/user/getprefrenences", formDataPref)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                profileOutput: resp.data.output,
              });
            } else {
              //console.log(resp.data.msg);
              alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    //  getPreferences();
  }
  updateProfile = () => {
    if (this.validator.allValid()) {
      let registerdata = new FormData(); //registerdata object
      registerdata.append("userid", localStorage.getItem("userid"));
      registerdata.append("mobile", localStorage.getItem("userphone"));
      registerdata.append("email", this.state.email);
      registerdata.append("name", this.state.name);
      registerdata.append("dob", moment(this.state.dob).format("DD-MM-YYYY"));
      if (this.state.doa != '')
        registerdata.append("doa", moment(this.state.doa).format("DD-MM-YYYY"));
      else
        registerdata.append("doa", '');
      registerdata.append("gender", this.state.gender);
      registerdata.append("mstatus", this.state.maritalStatus);
      registerdata.append("av", Constants.av);
      registerdata.append("pt", Constants.pt);
      if (this.state.email != "") {
        if (this.state.mobile != "") {
          axios
            .post(Constants.api + "user/editprofile", registerdata, {
              headers: { "Accept-Language": localStorage.getItem("language") },
            })
            .then((res) => {
              console.log(res);
              if (res.data.result == "success") {
                localStorage.setItem("useremail", res.data.output.em);
                localStorage.setItem("userid", res.data.output.id);
                localStorage.setItem("userphone", res.data.output.ph);
                localStorage.setItem("username", res.data.output.un);
                this.setState({ editProfile: false });
                // this.getLoyalty();
                //  swal(res.data.msg);

                window.location.reload();
              } else {
                // this.setState({alert:res.data.msg});
                swal(res.data.msg);
              }

              // if (res.data.msg == 'Please enter otp')
              // window.location = '/verifyotp';
            });
        } else swal("", "Please enter Valid Mobile", "error");
      } else swal("", "Please enter Valid Email", "error");
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  namehandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  ongenderChange = (event) => {
    this.setState({ gender: event.target.value });
  };
  onmaritalStatusChange = (event) => {

    this.setState({ maritalStatus: event.target.value, doa: '' });

  };
  ondobChange = (date) => {
    this.setState({ dob: date });
  };
  ondoaChange = (date) => {
    this.setState({ doa: date });
  };
  render() {
    let title =
      localStorage.getItem("language") != "ar" ? (
        <Text>Wow Club</Text>
      ) : (
        <Text>نادي واو</Text>
      );
    let historytitle =
      localStorage.getItem("language") != "ar" ? (
        <Text>Club History</Text>
      ) : (
        <Text>نادي واو تاريخ المعاملات</Text>
      );
    let bookingtitle =
      localStorage.getItem("language") != "ar" ? (
        <Text>Booking History</Text>
      ) : (
        <Text>سجل الحجز</Text>
      );
    let vouchertitle =
      localStorage.getItem("language") != "ar" ? (
        <Text>Your Vouchers</Text>
      ) : (
        <Text>قسائمك</Text>
      );
    return (
      <>
        <div className="loyalty-section container">
          <div className="loyalty-container">
            <div className="profile-edit-container">
              <div className="loyalty-edit-upper">
                <div className="profile-edit-box">
                  <div className="profile-name">
                    <div className="profile-circle">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                    {localStorage.getItem("username")}
                  </div>
                  {!localStorage.getItem("guest") ?

                    <a
                      href="#"
                      onClick={() => this.setState({ editProfile: true })}
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </a> : ''}
                </div>
                <a className="sign-out" onClick={this.logout}>
                  {localStorage.getItem("language") != "ar" ? (
                    "SIGN OUT"
                  ) : (
                    <Text>خروج</Text>
                  )}
                </a>
              </div>
              <div className="loyalty-edit-lower">


                <div className="profile-no">
                  <img
                    src={require("../../assets/phone (2)@2x.png").default}
                  />
                  <p>
                    {localStorage.getItem("userphone")}
                  </p>
                </div>

                <div className="profile-email">
                  <img
                    src={require("../../assets/email (1).png").default}
                  />

                  <p>
                    {localStorage.getItem("useremail")}
                  </p>
                </div>

              </div>
              {!localStorage.getItem("guest") ?
                <>
                  <div className="loyalty-edit-lower">


                    <div className="profile-no">
                      <img
                        src={require("../../assets/Icon material-cake.png").default}
                      />
                      <p>
                        {this.state.profileOutput.dob}
                      </p>
                    </div>
                    {this.state.profileOutput.maritalStatus == 'Married' ?
                      <div className="profile-email">
                        <img
                          src={require("../../assets/ring-svgrepo-com.png").default}
                        />

                        <p>
                          {this.state.profileOutput.doa}
                        </p>
                      </div> : ''}

                  </div>
                  <div className="loyalty-edit-lower">


                    <div className="profile-no">
                      <img
                        src={require("../../assets/user@2x.png").default}
                      />
                      <p>
                        {this.state.profileOutput.gender}
                      </p>
                    </div>

                    <div className="profile-email">
                      <img
                        src={require("../../assets/ring-svgrepo-com.png").default}
                      />

                      <p>
                        {this.state.profileOutput.maritalStatus}
                      </p>
                    </div>

                  </div>
                </>
                : ''}
            </div>
          </div>
          {this.state.profileOutput.dob && this.state.profileOutput.dob.length == 0 && this.state.profileOutput.doa && this.state.profileOutput.doa.length == 0 ?
            <div className="update-paln">
              <h5>Update your profile to earn more points <span onClick={() => this.setState({ editProfile: true })}>Update Now</span></h5>
            </div> : ''}


          <div className="loyalty-tabs">
            <Tabs
              defaultActiveKey="privilege"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="privilege" title={title}>
                {localStorage.getItem("guest") ? <div className="voucher-info-container">
                  <div className="voucher-info-container-upper">
                    <div class="wow_club wow-loyalty">
                      <div class="container">
                        <div class="wow_hello">
                          <div class="row align-items-center">
                            <div class="col-md-6">
                              <div class="wow_text_info">
                                <h1>
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Welcome"
                                  ) : (
                                    <Text>أهلا</Text>
                                  )}{" "}
                                  {localStorage.getItem("username")
                                    ? localStorage.getItem("username")
                                    : ""}

                                </h1>
                                {localStorage.getItem("language") != "ar" ? (
                                  <Text>
                                    {" "}
                                    <p>  Signup to become an exlcusive member of WOW club to avail amazing benefits on every purchase.   </p>


                                    <br />
                                    <ol>
                                      <li>
                                        You will get 500 welcome Points on Registering to the Website and App.

                                      </li>
                                      <li>
                                        Earn upto 5% Loyalty points on all transactions on Website and App.

                                      </li>
                                      <li>
                                        Earn  points on special occasions like:-<br />
                                        -Birthday 2700 Points<br />
                                        - Aniversary 1000 Points
                                        <br />
                                        (Note:- You have to update your profile to get points on special occasions.)
                                      </li>
                                      <li>
                                        Redeem your points through website & app.
                                      </li>
                                      <li>and much more...</li>
                                    </ol>

                                  </Text>
                                ) : (
                                  <Text>
                                    كن عضوًا حصريًا في WOW club للاستفادة من هذه المزايا المذهلة في كل عملية شراء.

                                    <br />       <ol>
                                      <li>
                                        ستحصل على نقاط ترحيب عند التسجيل في WOW Club
                                      </li>
                                      <li>
                                        ستحصل على نقاط ولاء على جميع المعاملات على الموقع (تصل إلى 5٪ من المبلغ).                      </li>
                                      <li>
                                        سوف تكسب أيضًا نقاطًا عند تحديث ملفك الشخصي.                      </li>
                                      <li>
                                        ستتمكن من استبدال نقاطهم من خلال موقع الويب والتطبيق وكذلك من نقاط البيع في السينما.
                                      </li>
                                    </ol>
                                  </Text>
                                )}
                              </div>
                            </div>


                          </div>

                          <div class="border-line"></div>
                        </div>




                      </div>
                    </div>
                  </div>

                  <div className="price-ticket">
                    <p>
                      {this.state.points && this.state.points.terms ? parse(this.state.points.terms) : ''}

                    </p>
                  </div>




                  <div className="voucher-info-container-lower d-none">
                    <Tabs
                      defaultActiveKey="your-vouchers"
                      id="uncontrolled-tab-example"

                    >
                      <Tab
                        eventKey="your-vouchers"
                        title={vouchertitle}
                        tabClassName="voucher-tag"
                      >
                        <div className="voucher-select-container">
                          {this.state.loyaltyVoucher.map(function (item, i) {
                            let cl = "voucher-tags";
                            if (item.status == "U") cl = " voucher-tags blur";
                            return (
                              <div className={cl}>
                                <a href="#">
                                  <div className="voucher-tag-html">
                                    <div className="voucher-tag-img">
                                      <img
                                        src={
                                          require("../../assets/voucher-card.png")
                                            .default
                                        }
                                      />
                                    </div>
                                    {/* <div className="voucher-content"> */}
                                    {item.expiryDate != null ? (
                                      <>
                                        <p className="voucher-till">
                                          Valid till
                                        </p>
                                        <p className="voucher-valid">
                                          {moment(item.expiryDate).format(
                                            "DD MMMM YYYY"
                                          )}
                                        </p>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <p className="voucher-type">{item.vname}</p>
                                    <p className="voucher-code">
                                      CODE:{item.vcode}
                                    </p>
                                    <div className="voucher-off">
                                      <p>{item.vAmount}%</p>
                                      <p>Flat Off</p>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </Tab>
                      {!localStorage.getItem("guest") ?
                        <Tab
                          eventKey="trans-history"
                          title={historytitle}
                          tabClassName="voucher-tag"
                        >
                          <div className="trans-history-container">
                            <div class="card1">
                              <ul id="progressbar1" class="text-center">

                                {this.state.loyaltyHistory.map(function (
                                  item,
                                  i
                                ) {
                                  let classes = "active step0";
                                  return (
                                    <>
                                      <li class={classes} key={i}>
                                        <div className="list-number">
                                          {item.pointsEarned != 0 ? (
                                            item.pointsEarned
                                          ) : (
                                            <i class="fa fa-check"></i>
                                          )}
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                              {this.state.loyaltyHistory.map(function (item, i) {
                                return (
                                  <div class="track-margin active">
                                    <p class="theme-dark-info">
                                      {item.transText}
                                    </p>
                                    <p class="theme-info">
                                      {moment(item.transDate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Tab> : ''}

                    </Tabs>
                  </div>
                </div>
                  : <div className="voucher-info-container">
                    <div className="voucher-info-container-upper">
                      <div class="wow_club wow-loyalty">
                        <div class="container">
                          <div class="wow_hello">
                            <div class="row align-items-center">
                              <div class="col-md-6">
                                <div class="wow_text_info">
                                  <h1>
                                    {localStorage.getItem("language") != "ar" ? (
                                      "Hello"
                                    ) : (
                                      <Text>أهلا</Text>
                                    )}{" "}
                                    {localStorage.getItem("username")
                                      ? localStorage.getItem("username")
                                      : ""}

                                  </h1>
                                  <p>
                                    {localStorage.getItem("language") != "ar" ? (
                                      "Your Wow Club Earnings"
                                    ) : (
                                      <Text>أرباحك من نادي واو</Text>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="wow_points">
                                  <div className="gold">
                                    <div class="point_img">
                                      <img
                                        src={require("../../assets/coins.svg").default}
                                      />
                                      <p>

                                        {/* {localStorage.getItem("language") != "ar" ? (
                                "POINTS"
                              ) : (
                                <Text>نقاط</Text>
                              )} */}
                                      </p>
                                    </div>&nbsp;
                                    &nbsp;
                                    <div class="point_text">
                                      <h1>
                                        {" "}
                                        {this.state.points
                                          ? this.state.points.pointsTxt
                                          : ""}

                                      </h1>
                                    </div>


                                  </div>

                                  <div className="silver">

                                    <div class="point_img point-silver">
                                      <img
                                        src={require("../../assets/coins-img - Copy.png").default}
                                      />
                                      <p>
                                        {/* {localStorage.getItem("language") != "ar" ? (
                                "TIERS"
                              ) : (
                                <Text>نقاط</Text>
                              )} */}
                                      </p>
                                    </div>&nbsp;
                                    <div class="point_text">
                                      <h1>
                                        {" "}

                                        {/* {this.state.points
                                ? this.state.points.tier
                                : ""}<br />Tier */}
                                        {this.state.points
                                          ? this.state.points.pntValTxt
                                          : ""}
                                      </h1>
                                    </div>

                                  </div>



                                </div>
                              </div>

                              {/* <div class="col-md-3">
                      <div class="wow_points">
                        <div class="point_text">
                          <h1>{this.state.loyaltyVoucher.length}</h1>
                        </div>

                        <div class="point_img">
                          <img
                            src={require("../../assets/voucher.svg").default}
                          />
                          <p>
                            {localStorage.getItem("language") != "ar" ? (
                              "voucher"
                            ) : (
                              <Text>فاتورة</Text>
                            )}
                          </p>
                        </div>
                      </div>
                    </div> */}
                            </div>

                            <div class="border-line"></div>
                          </div>



                          {this.state.s == "true" ?
                            <>
                              <div class="user_points_win">
                                <div class="user_img">
                                  <img src={require("../../assets/user.svg").default} />
                                </div>
                                <div class="point_win">
                                  <h2>
                                    {/* {this.state.points.point_left
                                    ?
                                    this.state.points.point_left : ''} */}

                                  </h2>
                                </div>

                                <div class="points_text">
                                  <p>
                                    {localStorage.getItem("language") != "ar" ? (
                                      "Earn"
                                    ) : (
                                      <Text>يكسب</Text>
                                    )}
                                    {this.state.points.point_left
                                      ?
                                      this.state.points.point_left : ''}

                                    {localStorage.getItem("language") != "ar" ? (
                                      "points more to redeem now"
                                    ) : (
                                      <Text>نقاط أكثر لاستردادها الآن</Text>
                                    )}
                                  </p>
                                </div>
                              </div>

                              <div class="flex-parent">
                                {this.state.usedloyaltyVoucher
                                  ? this.state.usedloyaltyVoucher.map(function (item, i) {
                                    return (
                                      <div className="voucher-progress ">
                                        <div class="user_img">
                                          <img
                                            src={require("../../assets/user.svg").default}
                                          />
                                        </div>
                                        <div className="voucher-img">
                                          <img
                                            src={
                                              require("../../assets/voucher.png").default
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <ProgressBar
                                          now={100}
                                          className="voucher-prog-bar"
                                        />
                                      </div>
                                    );
                                  })
                                  : ""}
                                {this.state.loyaltyVoucher
                                  ? this.state.loyaltyVoucher.map(function (item, i) {
                                    return (
                                      <div className="voucher-progress active">
                                        <div class="user_img">
                                          <img
                                            src={require("../../assets/user.svg").default}
                                          />
                                        </div>
                                        <div className="voucher-img">
                                          <img
                                            src={
                                              require("../../assets/voucher.png").default
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <ProgressBar
                                          now={100}
                                          className="voucher-prog-bar"
                                        />
                                      </div>
                                    );
                                  })
                                  : ""}
                                <div className="voucher-progress ">
                                  <div className="voucher-img">
                                    <img
                                      src={require("../../assets/voucher.png").default}
                                      alt="logo"
                                    />
                                  </div>
                                  <ProgressBar now={0} className="voucher-prog-bar" />
                                </div>
                              </div></>
                            : ''}
                        </div>
                      </div>
                    </div>
                    {this.state.points.s == "true" ?

                      <div class="wow_club wow-silver">
                        <div class="container">
                          <div class="wow_hello wow-silver">
                            <div class="row align-items-center">
                              <div class="col-md-2">
                                <div className="silver">
                                  <div class="point_text">
                                    <h1>
                                      {" "}

                                      {this.state.points
                                        ? this.state.points.tier
                                        : ""} <br /> Tier
                                    </h1>
                                  </div>

                                  <div class="point_img point-silver">
                                    <img
                                      src={require("../../assets/coins-img - Copy.png").default}
                                    />
                                    <p>
                                      {localStorage.getItem("language") != "ar" ? (
                                        "TIERS"
                                      ) : (
                                        <Text>نقاط</Text>
                                      )}
                                    </p>
                                  </div>
                                </div>

                              </div>
                              <div class="col-md-5">
                                <div class="wow_points">
                                  <div className="gold">
                                    <div class="point_text amout-text">
                                      {this.state.points.s == "true" ?
                                        <>

                                          <p>
                                            {localStorage.getItem("language") != "ar" ? (
                                              "Earn "
                                            ) : (
                                              <Text>يكسب </Text>
                                            )}
                                            {this.state.points.point_left
                                              ?
                                              '*' + this.state.points.point_left : ''}

                                            {localStorage.getItem("language") != "ar" ? (
                                              " points more to redeem now"
                                            ) : (
                                              <Text> نقاط أكثر لاستردادها الآن</Text>
                                            )}
                                          </p>


                                        </> : ''}
                                    </div>


                                  </div>




                                </div>
                              </div>



                            </div>




                          </div>




                          {this.state.s == "true" ?
                            <>
                              <div class="user_points_win">
                                <div class="user_img">
                                  <img src={require("../../assets/user.svg").default} />
                                </div>
                                <div class="point_win">
                                  <h2>
                                    {this.state.points.point_left
                                      ?
                                      parseFloat(this.state.points.point_left).toFixed(2) : ''}

                                  </h2>
                                </div>

                                <div class="points_text">
                                  <p>
                                    {localStorage.getItem("language") != "ar" ? (
                                      "points more to redeem now"
                                    ) : (
                                      <Text>نقطة للفوز بقسيمة أخرى</Text>
                                    )}
                                  </p>
                                </div>
                              </div>

                              <div class="flex-parent">
                                {this.state.usedloyaltyVoucher
                                  ? this.state.usedloyaltyVoucher.map(function (item, i) {
                                    return (
                                      <div className="voucher-progress ">
                                        <div class="user_img">
                                          <img
                                            src={require("../../assets/user.svg").default}
                                          />
                                        </div>
                                        <div className="voucher-img">
                                          <img
                                            src={
                                              require("../../assets/voucher.png").default
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <ProgressBar
                                          now={100}
                                          className="voucher-prog-bar"
                                        />
                                      </div>
                                    );
                                  })
                                  : ""}
                                {this.state.loyaltyVoucher
                                  ? this.state.loyaltyVoucher.map(function (item, i) {
                                    return (
                                      <div className="voucher-progress active">
                                        <div class="user_img">
                                          <img
                                            src={require("../../assets/user.svg").default}
                                          />
                                        </div>
                                        <div className="voucher-img">
                                          <img
                                            src={
                                              require("../../assets/voucher.png").default
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <ProgressBar
                                          now={100}
                                          className="voucher-prog-bar"
                                        />
                                      </div>
                                    );
                                  })
                                  : ""}
                                <div className="voucher-progress ">
                                  <div className="voucher-img">
                                    <img
                                      src={require("../../assets/voucher.png").default}
                                      alt="logo"
                                    />
                                  </div>
                                  <ProgressBar now={0} className="voucher-prog-bar" />
                                </div>
                              </div></>
                            : ''}
                        </div>


                      </div>
                      : ''}
                    <div className="price-ticket price-width">
                      <p>
                        {this.state.points && this.state.points.terms ? parse(this.state.points.terms) : ''}

                      </p>
                    </div>




                    <div className="voucher-info-container-lower d-none">
                      <Tabs
                        defaultActiveKey="your-vouchers"
                        id="uncontrolled-tab-example"

                      >
                        <Tab
                          eventKey="your-vouchers"
                          title={vouchertitle}
                          tabClassName="voucher-tag"
                        >
                          <div className="voucher-select-container">
                            {this.state.loyaltyVoucher.map(function (item, i) {
                              let cl = "voucher-tags";
                              if (item.status == "U") cl = " voucher-tags blur";
                              return (
                                <div className={cl}>
                                  <a href="#">
                                    <div className="voucher-tag-html">
                                      <div className="voucher-tag-img">
                                        <img
                                          src={
                                            require("../../assets/voucher-card.png")
                                              .default
                                          }
                                        />
                                      </div>
                                      {/* <div className="voucher-content"> */}
                                      {item.expiryDate != null ? (
                                        <>
                                          <p className="voucher-till">
                                            Valid till
                                          </p>
                                          <p className="voucher-valid">
                                            {moment(item.expiryDate).format(
                                              "DD MMMM YYYY"
                                            )}
                                          </p>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <p className="voucher-type">{item.vname}</p>
                                      <p className="voucher-code">
                                        CODE:{item.vcode}
                                      </p>
                                      <div className="voucher-off">
                                        <p>{item.vAmount}%</p>
                                        <p>Flat Off</p>
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </Tab>
                        {!localStorage.getItem("guest") ?
                          <Tab
                            eventKey="trans-history"
                            title={historytitle}
                            tabClassName="voucher-tag"
                          >
                            <div className="trans-history-container">
                              <div class="card1">
                                <ul id="progressbar1" class="text-center">

                                  {this.state.loyaltyHistory.map(function (
                                    item,
                                    i
                                  ) {
                                    let classes = "active step0";
                                    return (
                                      <>
                                        <li class={classes} key={i}>
                                          <div className="list-number">
                                            {item.pointsEarned != 0 ? (
                                              item.pointsEarned
                                            ) : (
                                              <i class="fa fa-check"></i>
                                            )}
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                                {this.state.loyaltyHistory.map(function (item, i) {
                                  return (
                                    <div class="track-margin active">
                                      <p class="theme-dark-info">
                                        {item.transText}
                                      </p>
                                      <p class="theme-info">
                                        {moment(item.transDate).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Tab> : ''}

                      </Tabs>
                    </div>
                  </div>}

              </Tab>
              {!localStorage.getItem("guest") ?

                <Tab
                  eventKey="trans-history"
                  title={historytitle}
                  tabClassName="voucher-tag"
                >
                  <div className="trans-history-container">
                    <div class="card1">
                      <ul id="progressbar1" class="text-center" className="progressbar">
                        {this.state.loyaltyHistory.map(function (
                          item,
                          i
                        ) {
                          let classes = "active step0";
                          return (
                            <>
                              <div className="points-ticket">
                                <li>
                                  <img
                                    src={require("../../assets/add.png").default}

                                  />
                                  <h4>   {item.transText}</h4>
                                  <p> {moment(item.transDate).format(
                                    "DD MMMM YYYY"
                                  )}</p>
                                </li>
                              </div>
                            </>
                          )
                        })}
                      </ul>




                    </div>
                  </div>
                </Tab> : ''}
              <Tab eventKey="bookinghistory" title={bookingtitle}>
                <div className="booking-history-container">
                  {this.state.current.map(function (item, i) {
                    let qrimage = "data:image/png;base64," + item.qrdata;

                    return (
                      <>
                        <div className="booking-history">
                          <div className="booking-history-date">
                            <p>
                              {localStorage.getItem("language") != "ar" ? (
                                "On"
                              ) : (
                                <Text>تشغيل</Text>
                              )}
                              &nbsp;{item.showtime.split("-")[0]}
                            </p>
                            <p>{item.showtime.split("-")[1]}</p>
                          </div>
                          <div className="booking-history-poster">
                            <div className="booking-history-poster-img">
                              <img src={item.poster} />
                            </div>
                          </div>
                          <div className="booking-history-info">
                            <div className="booking-history-info-left">
                              <h4>
                                {item.moviename}{" "}
                                {/* <p>
                                  {localStorage.getItem("language") != "ar" ? (
                                    `You have earned ${item.loyaltyPoints} points`
                                  ) : (
                                    <Text>
                                      لقد ربحت {item.loyaltyPoints} نقطة
                                    </Text>
                                  )}
                                </p> */}
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "at"
                                  ) : (
                                    <Text>في</Text>
                                  )}
                                  &nbsp;
                                  {item.cinemaname}
                                </span>
                              </h4>
                              <p>
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Order ID"
                                  ) : (
                                    <Text>رقم التعريف الخاص بالطلب</Text>
                                  )}
                                  :
                                </span>{" "}
                                {item.bookingid}
                              </p>
                              <p>
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Seats Booked"
                                  ) : (
                                    <Text>المقاعد المحجوزة</Text>
                                  )}
                                  :
                                </span>{" "}
                                {item.seatsArr.join(",")}
                              </p>
                              <p>
                                {localStorage.getItem("language") != "ar" ? (
                                  "Pay at Cinema(OMR)"
                                ) : (
                                  <Text>دفع في السينما (OMR)</Text>
                                )}
                                :{" "}
                                <span className="price-wow">
                                  {item.totalprice}
                                </span>
                              </p>
                            </div>
                            <div className="booking-history-info-right">
                              {/* <button className="btn btn-food">
                                Order Food
                              </button> */}
                            </div>
                          </div>
                          <div className="booking-cancel">
                            <img src={qrimage} alt="img" />
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {this.state.history.map(function (item, i) {
                    let qrimage = "data:image/png;base64," + item.qrdata;

                    return (
                      <>
                        <div className="booking-history">
                          <div className="booking-history-date">
                            <p>
                              {localStorage.getItem("language") != "ar" ? (
                                "On"
                              ) : (
                                <Text>تشغيل</Text>
                              )}
                              &nbsp;{item.showtime.split("-")[0]}
                            </p>
                            <p>{item.showtime.split("-")[1]}</p>
                          </div>
                          <div className="booking-history-poster">
                            <div className="booking-history-poster-img">
                              <img src={item.poster} />
                            </div>
                          </div>
                          <div className="booking-history-info">
                            <div className="booking-history-info-left">
                              <h4>
                                {item.moviename}{" "}
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "at"
                                  ) : (
                                    <Text>في</Text>
                                  )}
                                  &nbsp;{item.cinemaname}
                                </span>
                              </h4>
                              <p>
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Order ID"
                                  ) : (
                                    <Text>رقم التعريف الخاص بالطلب</Text>
                                  )}
                                  :
                                </span>{" "}
                                {item.bookingid}
                              </p>
                              <p>
                                <span className="wow-gray-info">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Seats Booked"
                                  ) : (
                                    <Text>المقاعد المحجوزة</Text>
                                  )}
                                  :
                                </span>{" "}
                                {item.seatsArr.join(",")}
                              </p>
                              <p>
                                {localStorage.getItem("language") != "ar" ? (
                                  "Pay at Cinema(OMR)"
                                ) : (
                                  <Text>دفع في السينما (OMR)</Text>
                                )}
                                :{" "}
                                <span className="price-wow">
                                  {item.totalprice}
                                </span>
                              </p>
                            </div>
                            <div className="booking-history-info-right">
                              {/* <button className="btn btn-food">
                                Order Food
                              </button> */}
                            </div>
                          </div>
                          <div className="booking-cancel">
                            <img src={qrimage} alt="img" />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {this.state.editProfile ? (
                  <Modal show={true} className="login-modal edit-modal">
                    <div class="cross">
                      <img
                        src={require("../../assets/cross@2x.png").default}
                        alt="img"
                        onClick={() => this.setState({ editProfile: false })}
                      />
                    </div>

                    <div class="login-form" >
                      <div class="input-box">
                        <i class="fa fa-user"></i>
                        <input
                          type="text"
                          name="name"
                          id="phone"
                          onChange={this.namehandleChange}
                          value={this.state.name}
                          placeholder="Full Name"
                        />
                        {this.validator.message(
                          "Full Name",
                          this.state.name,
                          "required"
                        )}
                      </div>
                      <div class="input-box">
                        <i class="fa fa-envelope"></i>
                        <input
                          type="text"
                          name="email"
                          id="phone"
                          onChange={this.emailhandleChange}
                          value={this.state.email}
                          placeholder="Email"
                        />
                        {this.validator.message(
                          "Email",
                          this.state.email,
                          "required"
                        )}
                      </div>
                      <div class="input-box">
                        <i class="fa fa-phone"></i>
                        <input
                          type="text"
                          name="name"
                          id="phone"
                          readOnly
                          value={localStorage.getItem("userphone")}
                        />
                      </div>
                      <div class="input-box">
                        <img
                          src={require("../../assets/Icon material-cake.png").default}
                        />
                        <DatePicker
                          maxDate={new Date("02-29-2009")}

                          wrapperClassName="datePicker" name="dob" onChange={this.ondobChange} value={this.state.dob} />
                        {this.validator.message(
                          "DOB",
                          this.state.dob,
                          "required"
                        )}
                      </div>


                      <div class="input-box">
                        <i class="fa fa-male"></i>
                        <select name="gender" onChange={this.ongenderChange} value={this.state.gender} >

                          <option value=""> Gender</option>

                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                        </select>
                        {this.validator.message(
                          "Gender",
                          this.state.gender,
                          "required"
                        )}
                      </div>
                      <div class="input-box">
                        <img
                          src={require("../../assets/ring-svgrepo-com.png").default}
                        />
                        <select name="maritalStatus" onChange={this.onmaritalStatusChange} value={this.state.maritalStatus} >

                          <option value="">  Marital Status</option>

                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                        </select>
                        {this.validator.message(
                          "Marital Status",
                          this.state.maritalStatus,
                          "required"
                        )}
                      </div>
                      {this.state.maritalStatus == 'Married' ?
                        <div class="input-box">
                          <img
                            src={require("../../assets/ring-svgrepo-com.png").default}
                          />
                          <DatePicker
                            maxDate={new Date()} wrapperClassName="datePicker" name="doa" onChange={this.ondoaChange} value={this.state.doa} />
                          {this.validator.message(
                            "Anniversary",
                            this.state.doa,
                            "required"
                          )}
                        </div>

                        : ''}

                      <div class="input-btn" >
                        <input
                          type="submit"
                          onClick={this.updateProfile}
                          value={
                            localStorage.getItem("language") != "ar"
                              ? "Save"
                              : "حفظ"
                          }
                          class="btn btn-default"
                        />
                      </div>
                    </div>
                  </Modal>
                ) : (
                  ""
                )}
              </Tab>

            </Tabs>

          </div>
        </div>
      </>
    );
  }
}

export default Loyalty;
