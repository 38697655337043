import { React, Component } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./login.styles.css";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Otp from "react-otp-input";
import Text from "react-text";

class GuestLogin extends Component {
  constructor(props) {
    if (localStorage.getItem("userid") == null)
      localStorage.setItem("userid", 0);
    super(props);
    this.state = {
      cities: [],
      lat: 0.0,
      lng: 0.0,
      otplogin: false,
      showLogin: true,
      show: this.props.show,
      name: "",
      mobile: "",
      email: "",
      otp: "",
      password: "",
      confirmpassword: "",
      showRegister: false,
    };
    this.otpvalidator = new SimpleReactValidator();
    this.forgotvalidator = new SimpleReactValidator();
    this.resetvalidator = new SimpleReactValidator();
    this.resetpassvalidator = new SimpleReactValidator();
    this.otploginvalidator = new SimpleReactValidator();
    this.registervalidator = new SimpleReactValidator();
    this.validator = new SimpleReactValidator();
  }

  handleForgotpassSubmit = (event) => {
    event.preventDefault();
    if (this.forgotvalidator.allValid()) {
      let logindata = new FormData(); //logindata object
      logindata.append("pt", Constants.pt);
      logindata.append("av", Constants.av);
      logindata.append("did", Constants.did);
      logindata.append("mobile", this.state.mobile);
      logindata.append("email", "");

      axios
        .post(Constants.api + "user/forgotpassword", logindata)
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            if (res.data.msg == "ok") {
              swal("Please Enter OTP Received on your Mobile Number.");
              this.setState({ showforgotpass: false, resetpassshow: true });
            }
          } else {
            // this.setState({alert:res.data.msg});
            swal("", res.data.msg, "info");
          }
        });
    } else this.forgotvalidator.showMessages();
    this.forceUpdate();
  };
  handleOtpLoginSubmit = (event) => {
    event.preventDefault();
    if (this.otploginvalidator.allValid() && this.state.mobile != "") {
      let logindata = new FormData(); //logindata object
      logindata.append("pt", Constants.pt);
      logindata.append("mobile", this.state.mobile);
      axios
        .post(Constants.api + "user/new/resendotp", logindata)
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            swal("", "Please enter OTP received on your Mobile Number", "info");
            if (res.data.output.otp_require == "true") {
              this.setState({ showotplogin: 0, otpshow: 1, showLogin: false });
            }
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    } else this.otploginvalidator.showMessages();
    this.forceUpdate();
  };
  handleLoginSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let logindata = new FormData(); //logindata object
      logindata.append("av", Constants.av);
      logindata.append("pt", Constants.pt);
      logindata.append("name", this.state.name);
      logindata.append("mobile", this.state.mobile);
      logindata.append("email", this.state.email);
      logindata.append("guest", true);

      axios
        .post(Constants.api + "user/new/registerpay", logindata,)
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            if (res.data.output.otp_require == "true") {
              this.setState({ showLogin: false, otpshow: true });
            } else {
              swal("", "You Have Succesfully Logged In.", "success");
              localStorage.setItem("useremail", res.data.output.customer.em);
              localStorage.setItem("userid", res.data.output.customer.id);
              localStorage.setItem("userphone", res.data.output.customer.ph);
              localStorage.setItem("username", res.data.output.customer.un);
              localStorage.setItem("guest", true);
              if (window.location.href.indexOf("food") > -1)
                window.location =
                  "/payment/" +
                  localStorage.getItem("ccode") +
                  "/" +
                  localStorage.getItem("mname");
              else window.location.reload(false);
            }
          } else {
            // this.setState({alert:res.data.msg});

            swal(res.data.msg);
          }
        });
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleRegisterSubmit = (event) => {
    event.preventDefault();

    if (this.registervalidator.allValid()) {
      if (this.state.password != this.state.confirmpassword) {
        swal("", "Passwords do not match.", "info");
      } else {
        let registerdata = new FormData(); //registerdata object
        registerdata.append("av", Constants.av);
        registerdata.append("pt", Constants.pt);
        registerdata.append("did", Constants.did);
        registerdata.append("mobile", this.state.mobile);
        registerdata.append("password", this.state.password);
        registerdata.append("email", this.state.email);
        registerdata.append("name", this.state.name);
        registerdata.append("guest", true);

        axios
          .post(Constants.api + "user/new/register", registerdata)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              if (res.data.output.otp_require == "true") {
                swal("Please Enter OTP Received on your Mobile Number.");
                this.setState({ showRegister: false, otpshow: true });
              }
              //     localStorage.setItem('useremail', res.data.output.em);
              //     localStorage.setItem('userid', res.data.output.id);
              //     localStorage.setItem('userphone', res.data.output.ph);
              //     localStorage.setItem('username', res.data.output.un);
              //     //   sessionStorage.setItem('loginid',res.data.output.id);
              //     //   sessionStorage.setItem('logincompany',res.data.output.company);
              //     //   sessionStorage.setItem('loginname',res.data.output.name);
              //     //   sessionStorage.setItem('login',res.data.output.name);
              //     //  // alert("Success");
              //     //  this.setState({alert:"You have logged in. Redirecting you to the pnanel."});
              //     //  this.setState({logout:false});

              //     window.location.reload(false);
            } else {
              // this.setState({alert:res.data.msg});
              swal("", res.data.msg, "info");

              window.location.reload(false);

            }

            // if (res.data.msg == 'Please enter otp')
            // window.location = '/verifyotp';
          });
      }
    } else this.registervalidator.showMessages();
    this.forceUpdate();
  };

  handleotpSubmit = (event) => {
    event.preventDefault();
    if (this.otpvalidator.allValid()) {
      if (this.state.otp.length == 6) {
        let otpdata = new FormData(); //otpdata object
        otpdata.append("av", Constants.av);
        otpdata.append("pt", Constants.pt);
        otpdata.append("mobile", this.state.mobile);
        otpdata.append("otp", this.state.otp);
        otpdata.append("name", this.state.name);

        axios.post(Constants.api + "user/mVerify", otpdata).then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("useremail", res.data.output.em);
            localStorage.setItem("userid", res.data.output.id);
            localStorage.setItem("userphone", res.data.output.ph);
            localStorage.setItem("username", res.data.output.un);
            swal("", "You have logged in!", "success");
            if (window.location.href.indexOf("food") > -1)
              window.location =
                "/payment/" +
                localStorage.getItem("ccode") +
                "/" +
                localStorage.getItem("mname");
            else window.location.reload(false);
          } else {
            // this.setState({alert:res.data.msg});
            swal("", res.data.msg, "info");
          }

          // if (res.data.msg == 'Please enter otp')
          // window.location = '/verifyotp';
        });
      } else swal("", "Please enter 6 digit otp", "error");
    } else this.otpvalidator.showMessages();
    this.forceUpdate();
  };
  handleresetpassSubmit = (event) => {
    event.preventDefault();
    if (this.resetpassvalidator.allValid()) {
      if (this.state.password != this.state.confirmpassword) {
        swal("", "Passwords do not match.", "info");
      } else {
        let resetdata = new FormData(); // resetdata object
        resetdata.append("av", Constants.av);
        resetdata.append("pt", Constants.pt);
        resetdata.append("did", Constants.did);
        resetdata.append("mobile", this.state.mobile);
        resetdata.append("password", this.state.password);
        resetdata.append("authcode", this.state.otp);
        resetdata.append("email", "");

        axios
          .post(Constants.api + "user/resetpassword", resetdata)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              localStorage.setItem("useremail", res.data.output.em);
              localStorage.setItem("userid", res.data.output.id);
              localStorage.setItem("userphone", res.data.output.ph);
              localStorage.setItem("username", res.data.output.un);
              swal("", "You have logged in!", "success");
              if (window.location.href.indexOf("food") > -1)
                window.location =
                  "/payment/" +
                  localStorage.getItem("ccode") +
                  "/" +
                  localStorage.getItem("mname");
              else window.location.reload(false);
            } else {
              // this.setState({alert:res.data.msg});
              swal("", res.data.msg, "info");
            }

            // if (res.data.msg == 'Please enter otp')
            // window.location = '/verifyotp';
          });
      }
    } else this.resetpassvalidator.showMessages();
    this.forceUpdate();
  };
  resendOtp = () => {
    if (this.state.mobile != "") {
      try {
        let resendData = new FormData(); //formdata object

        resendData.append("pt", Constants.pt);
        resendData.append("mobile", this.state.mobile);

        // const resp =
        axios
          .post(Constants.api + "user/new/resendotp", resendData)
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              swal("", "OTP has been resent on your Mobile Number.", "success");
              this.setState({ otp: "" });
            } else {
              swal(resp.data.msg);
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    } else this.validator.showMessages();
  };
  otphandleChange = (otp) => this.setState({ otp });

  namehandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  mobilehandleChange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  passwordhandleChange = (event) => {
    this.setState({ password: event.target.value });
  };
  confirmpasswordhandleChange = (event) => {
    this.setState({ confirmpassword: event.target.value });
  };
  render() {
    return (
      <>
        {this.state.showLogin ? (
          <div class="login-container">
            <h4>
              {localStorage.getItem("language") != "ar" ? (
                "Continue as Guest"
              ) : (
                <Text>تواصل كضيف</Text>
              )}
            </h4>
            <form class="login-form" onSubmit={this.handleLoginSubmit}>
              <div class="input-box">
                <i class="fa fa-user"></i>

                <input
                  type="text"
                  name="name"
                  id="phone"
                  onChange={this.namehandleChange}
                  value={this.state.name}
                  placeholder={
                    localStorage.getItem("language") != "ar" ? "Name" : "اسم"
                  }
                />
              </div>
              {this.validator.message("name", this.state.name, "required")}
              <div class="input-box">
                <img
                  src={require("../../assets/phone (2)@2x.png").default}
                  alt="img"
                />
                <input
                  type="text"
                  name="mobile"
                  id="phone"
                  onChange={this.mobilehandleChange}
                  value={this.state.mobile}
                  placeholder={
                    localStorage.getItem("language") != "ar"
                      ? "Mobile Number"
                      : "رقم الهاتف المحمول"
                  }
                />
              </div>
              {this.validator.message(
                "mobile",
                this.state.mobile,
                "required|numeric"
              )}
              <div class="input-box">
                <i class="fa fa-envelope"></i>

                <input
                  type="email"
                  name="mobile"
                  id="email"
                  onChange={this.emailhandleChange}
                  value={this.state.email}
                  placeholder={
                    localStorage.getItem("language") != "ar"
                      ? "Email"
                      : "بريد الالكتروني"
                  }
                />
              </div>
              {this.validator.message("email", this.state.email, "required")}

              <div class="input-btn">
                <input
                  type="submit"
                  value={
                    localStorage.getItem("language") != "ar"
                      ? "Continue"
                      : "يكمل"
                  }
                  class="btn btn-default"
                />
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
        {this.state.otpshow ? (
          <form onSubmit={this.handleotpSubmit}>
            <div class="login-container">
              <h4>Enter OTP</h4>
              <Otp
                value={this.state.otp}
                onChange={this.otphandleChange}
                numInputs={6}
                isInputSecure={true}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                className="otp-box"
              />
              <div class="input-btn">
                <input type="submit" value="Sign In" class="btn btn-default" />
              </div>
              <a
                style={{ cursor: "pointer" }}
                class="sign-up"
                onClick={this.resendOtp}
              >
                Resend OTP
              </a>
            </div>
          </form>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default GuestLogin;
