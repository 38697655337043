import React, { Component } from "react";
// import Header from './Header';
// import Footer from './Footer';
import * as Constants from "../../Constants";
// import '../CSS/payment.css';
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";

// import * as QueryString from "query-string";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "./payment.style.css";
import moment from "moment";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import TabPane from "react-bootstrap/TabPane";
import TabContent from "react-bootstrap/TabContent";
import { ThemeProvider } from "react-bootstrap";
import Text from "react-text";
import { PaymentInputsContainer } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import SimpleReactValidator from "simple-react-validator";
import { decode as base64_decode, encode as base64_encode } from 'base-64';

class Payment extends Component {
  constructor(props) {

    super(props);
    this.validator = new SimpleReactValidator();

  }


  state = {
    cinemaname: this.props.match.params.cinemaname,
    moviename: this.props.match.params.moviename,
    tckDetailsOutput: {},
    tckDetails: [],
    selectSeat: [],
    totalPrice: 0,
    loyaltyVoucher: [],
    discount: 0,
    promocodeApplied: 0,
    payModes: [],
    selected_paymode: "",
    tnc_checked: false,
    promocode: 0,
    encRequest: "",
    access_code: "",
    bankForm: false,
    code: "",
    submitForm: false,
    cardNum: "",
    expiryDate: "",
    cvv: "",
    cardName: "",
    bankoffers: [],
    selectedbankoffer: "",
    bankoffercard: "",
    showbankoffercard: false,
    bankOfferApplied: false,
    coupon: "",
    pointsRedeemed: false,
    redeem: false,
    totalpricebhaisa: 0,
    paid: false,
    promoEnable: false


  };


  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.cancelTransaction();
    });
    window.addEventListener('popstate', (event) => { });
    onpopstate = (event) => {
      // this.promocodeRollback();
      this.cancelTransaction();
    };
    if (window.performance) {
      if (window.performance.navigation.type == 1) {
        //   this.promocodeRollback();
        this.cancelTransaction();
      }
    }
    let formDataTckDetails = new FormData(); //formdata object
    formDataTckDetails.append("cinemacode", localStorage.getItem("ccode")); //append the values with key, value pair
    formDataTckDetails.append("bookingid", localStorage.getItem("bookingid"));
    formDataTckDetails.append("transid", localStorage.getItem("transid"));
    formDataTckDetails.append("doreq", false);
    formDataTckDetails.append("userid", localStorage.getItem("userid"));
    formDataTckDetails.append("av", Constants.av);
    formDataTckDetails.append("pt", Constants.pt);
    formDataTckDetails.append("did", Constants.did);

    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(Constants.api + "trans/tckdetails/", formDataTckDetails, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              localStorage.setItem("bookingid", resp.data.output.bookingid);
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                tckDetailsOutput: resp.data.output,
                selectSeat: resp.data.output.seatsArr,
                promoEnable: resp.data.output.promoEnable

              });
              // console.log("Ddfdfdfdffdf");

              let tckDetailsArray = [];
              Object.keys(resp.data.output.items).forEach(function (key) {
                tckDetailsArray.push(resp.data.output.items[key]);
              });

              this.setState({
                tckDetails: tckDetailsArray,
                totalPrice: resp.data.output.totalprice,
                payModes: resp.data.output.paymodes.gateway,
                totalpricebhaisa: resp.data.output.totalpricebhaisa,
              });
              console.log(this.state.tckDetails);
            } else {
              console.log(resp.data.msg);
              swal("something went wrong");
              //  window.location = "/";
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    //payModes();
    tckDetailsRequest();
    const getLoyaltyVouchers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/member/vouchers", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "V",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                loyaltyVoucher: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    getLoyaltyVouchers();
    const getBankOffers = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        let formDataUP = new FormData(); //formdata object
        formDataUP.append("av", Constants.av);
        formDataUP.append("pt", Constants.pt);
        formDataUP.append("did", Constants.did);
        await axios
          .post(Constants.api + "deals/bankoffers", formDataUP, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                bankoffers: resp.data.output,
              });
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getBankOffers();
    const getMemberPoints = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "v1/points", {
            av: Constants.av,
            id: 0,
            mobile: localStorage.getItem("userphone"),
            point: 0,
            pt: Constants.pt,
            status: "",
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });

              this.setState({
                points: resp.data.output,
              });
              console.log(resp.data.output)
            } else {
              console.log(resp.data.output);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getMemberPoints();

  }
  componentWillUnmount() { }

  onBackButtonEvent = () => {
    //  this.promocodeRollback();
    this.cancelTransaction();
  };
  cardNameChange = (event) => {
    this.setState({
      cardName: event.target.value
    })
  }
  cardNumberChange = (event) => {
    this.setState({
      cardNum: event.target.value
    })
  }
  handleCouponCodeChange = (event) => {
    this.setState({
      coupon: event.target.value
    })
  }
  bankOfferCardNumberChange = (event) => {
    this.setState({
      bankoffercard: event.target.value
    })
  }
  cvNumberChange = (event) => {
    this.setState({ cvv: event.target.value })
  }
  expiryDateChange = (event) => {
    this.setState({ expiryDate: event.target.value })
  }

  promocodeRollback = () => {
    this.setState({ loading: 1 });
    try {
      let promoData = new FormData(); //formdata object
      promoData.append("bookingid", localStorage.getItem("bookingid"));

      // const resp =
      axios
        .post(Constants.api + "v1/loyaltypromo/rollback", promoData)
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
              discount: 0,
              promocodeApplied: 0,
            });
            // swal("", "Promocode has been removed successfully.", "info");
          } else {
            this.setState({
              loading: 0,
            });
            // swal(resp.data.msg);
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  changeTnc = (event) => {
    console.log(event.target.checked);
    if (event.target.checked == true) {
      this.setState({ tnc_checked: true });
    } else {
      this.setState({ tnc_checked: false });
    }
  };

  // makePayment = (obj) => {
  //   var paytm_anj_config = {
  //     root: "",
  //     flow: "DEFAULT",
  //     data: {
  //       orderId: obj.bookid /* update order id */,
  //       token: obj.hmackey /* update token value */,
  //       tokenType: "TXN_TOKEN",
  //       amount: obj.amount /* update amount */,
  //     },
  //     handler: {
  //       notifyMerchant: function (eventName, data) {
  //         console.log("notifyMerchant handler function called");
  //         console.log("eventName => ", eventName);
  //         console.log("data => ", data);
  //         if (eventName == "APP_CLOSED") {
  //           window.location = "/cancel/trans";
  //         }
  //       },
  //     },
  //   };
  //   console.log(paytm_anj_config);
  //   if (window.Paytm && window.Paytm.CheckoutJS) {
  //     // initialze configuration using init method
  //     console.log("initialze configuration using init method");
  //     window.Paytm.CheckoutJS.init(paytm_anj_config)
  //       .then(function onSuccess() {
  //         console.log("Before JS Checkout invoke");
  //         // after successfully update configuration invoke checkoutjs
  //         window.Paytm.CheckoutJS.invoke();
  //       })
  //       .catch(function onError(error) {
  //         console.log("Error => ", error);
  //       });
  //   }
  // };

  // checkTransactionStatus = () => {
  //   const interval = setInterval(() => {
  //     console.log("This will run every second!");
  //   }, 5000);
  //   clearInterval(interval);
  // };

  promocodeChange = (event) => {
    this.setState({ promocode: event.target.value });
  };
  paylater = () => {
    this.setState({
      loading: 1,
    });
    try {
      let paymentData = new FormData(); //formdata object
      paymentData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
      paymentData.append("bookingid", localStorage.getItem("bookingid"));
      paymentData.append("transid", localStorage.getItem("transid"));
      paymentData.append("pt", "WEBSITE");

      paymentData.append("av", "1.0");

      axios
        .post(Constants.api + "trans/paylater", paymentData, {
          headers: { "Accept-Language": "en" },
        })
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            console.log(resp.data.output);
            if (resp.data.output.unpaid)
              window.location = "/booking?result=success";
            else window.location = "/booking?result=fail";
          } else {
            swal(resp.data.msg);
            this.setState({
              loading: 0,
            });
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  onSubmitHandler = (e) => {
    e.preventDefault();
    this.setState({
      submitForm: true,
    });
    if (this.state.submitForm) {
      // submitting the form conditionally with javascript
      document.getElementById("bankform").submit();
    }
  };
  cancelTransaction = () => {

    this.setState({ loading: 1 });
    try {
      let canceldata = new FormData(); //formdata object
      canceldata.append("bookingid", localStorage.getItem("bookingid"));
      canceldata.append("transid", parseInt(localStorage.getItem("transid")));
      canceldata.append("cinemacode", localStorage.getItem("ccode"));
      canceldata.append("pt", "WEBSITE");

      canceldata.append("av", "1.0");
      axios.post(Constants.api + "canceltrans", canceldata, {
        headers: { "Accept-Language": localStorage.getItem("language") },
      }).then((resp) => {
        console.log(resp);
        this.props.history.go(-3);

      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  render() {
    // if (this.state.loading == 1) {
    //   return (
    //     <>
    //       <Loading />
    //     </>
    //   );
    // }
    let selected_paymode = this.state.selected_paymode;
    let selectedbankoffer = this.state.selectedbankoffer;

    const selectPaymode = (pm) => {
      if (!this.state.bankOfferApplied)
        this.setState({ cardNum: "" });
      if (pm == 'Bank Offer' && (this.state.bankOfferApplied || this.state.couponcodeApplied))
        swal("The Discount has been already applied for this transaction")
      this.setState({ selected_paymode: pm, cvv: "", expiryDate: "", cardName: "" });
      this.validator.hideMessages();
      // if (pm == "UPI") pm = "UPI";
      // if (pm == "Net Banking") pm = "NB";
      // if (pm == "Credit Card") pm = "CC";
      // if (pm == "Debit Card") pm = "DC";
      // if (pm == "Paytm") pm = "WB";
      if (pm == "Pay at Cinema") pm = "paylater";

      if (pm == 'paylater')
        this.paylater();
      // else
      //   initiatePayment(pm);
    }
    const initiatePayment = (pm) => {

      // if (this.validator.allValid()) {
      var paytype = this.state.selected_paymode;
      console.log(pm);
      if (pm == "Credit Card") pm = "CC";
      if (pm == "Debit Card") pm = "DC";
      this.setState({ loading: 1 });
      try {
        if (pm == "UPI") pm = "UPI";
        if (pm == "Net Banking") pm = "NB";
        if (pm == "Credit Card") pm = "CC";
        if (pm == "Debit Card") pm = "DC";
        if (pm == "Paytm") pm = "WB";
        let paymentData = new FormData(); //formdata object
        paymentData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
        paymentData.append("bookingid", localStorage.getItem("bookingid"));
        paymentData.append("transid", localStorage.getItem("transid"));
        paymentData.append("booktype", "BOOKING");
        paymentData.append("pt", "WEBSITE");

        paymentData.append("av", "1.0");
        paymentData.append("ptype", pm);
        paymentData.append("cardNum", this.state.cardNum.replaceAll(" ", ""));
        paymentData.append("expMon", this.state.expiryDate.replaceAll(' ', '').split('/')[0]);
        paymentData.append("expYear", '20' + this.state.expiryDate.replaceAll(' ', '').split('/')[1]);
        paymentData.append("cvv", this.state.cvv);

        let carddetail = this.state.expiryDate.replaceAll(' ', '').split('/')[0] + '20' + this.state.expiryDate.replaceAll(' ', '').split('/')[1] + this.state.cvv + this.state.cardNum.replaceAll(" ", "");
        //alert(carddetail);

        let variable = this.state.cvv.length == 3 ? "X" : "Y";


        let cardArray = carddetail.split('');
        let ca = [];
        for (var i = 0; i < cardArray.length; i++) {
          ca.push(Math.floor(Math.random() * 10));
          // ca.push("z")
          ca.push(cardArray[i]);
        }
        // alert(ca.join(''));
        // alert(variable);
        // alert(variable + ca.join(''));
        let encrypted = base64_encode(variable + ca.join(''));

        // alert(encrypted);
        paymentData.append("token", '');
        let paymentData_old = new FormData(); //formdata object
        paymentData_old.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
        paymentData_old.append("bookingid", localStorage.getItem("bookingid"));
        paymentData_old.append("transid", localStorage.getItem("transid"));
        paymentData_old.append("booktype", "BOOKING");
        paymentData_old.append("pt", "WEBSITE");
        paymentData_old.append("av", "1.0");
        paymentData_old.append("ptype", pm);
        paymentData_old.append("cardNum", "");
        paymentData_old.append("expMon", "");
        paymentData_old.append("expYear", "");
        paymentData_old.append("cvv", "");
        paymentData_old.append("token", "");

        axios
          .post(Constants.api + "payment/smartpay/hmac", paymentData_old, {
            headers: { "Accept-Language": "en" },
          })
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              window.location = resp.data.output;
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              // window.location = "/booking-success";
              let payurl =
                "https://paymentwow.wemonde.in?paymode=" +
                pm +
                "&transid=" +
                localStorage.getItem("transid") +
                "&bookingid=" +
                localStorage.getItem("bookingid") +
                "&userid=" +
                localStorage.getItem("userid") +
                "&encRequest=" +
                resp.data.output.encr +
                "&access_code=" +
                resp.data.output.ac;

              this.setState({
                encRequest: resp.data.output.encr,
                access_code: resp.data.output.ac,
                bankForm: true,
              });
              // alert("hmac success");

            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      // }
      // else this.validator.showMessages();
      // this.forceUpdate();
    };
    const selectBankOffer = (code, bank) => {
      if (this.state.bankOfferApplied)
        swal("The Discount has been already applied for this transaction");
      else
        this.setState({ selectedbankoffer: code, showbankoffercard: true, bankname: bank })
    }
    const applyBankOffer = () => {

      if (this.state.bankoffercard.length > 4) {
        this.setState({ loading: 1 });
        try {
          // const resp =
          let promo = new FormData(); //formdata object
          promo.append("bin", this.state.bankoffercard.replaceAll(' ', '').substring(0, 6));
          promo.append("bookingid", localStorage.getItem("bookingid"));
          promo.append("transid", localStorage.getItem("transid"));

          promo.append("userid", localStorage.getItem("userid"));
          promo.append("booktype", "Booking");

          promo.append("bid", this.state.selectedbankoffer); //append the values with key, value pair

          promo.append("pt", "WEBSITE");
          promo.append("av", "1.0");
          axios.post(Constants.api + "v1/bankoffer", promo, {
            headers: { "Accept-Language": "en" },
          }).then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
                bankOfferApplied: true,
                cardNum: this.state.bankoffercard.replaceAll(' ', '')
              });
              swal(
                "Congratulations",
                "You got a discount of  " + resp.data.output.di + " OMR !!",
                "success"
              );
              tckDetailsRequest();

            } else {
              this.setState({
                loading: 0,
              });
              swal(resp.data.msg);
            }
            //movie array
          });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else swal("Please enter a valid card")
    };
    const tckDetailsRequest = async () => {
      let formDataTckDetails = new FormData(); //formdata object
      formDataTckDetails.append("cinemacode", localStorage.getItem("ccode")); //append the values with key, value pair
      formDataTckDetails.append("bookingid", localStorage.getItem("bookingid"));
      formDataTckDetails.append("transid", localStorage.getItem("transid"));
      formDataTckDetails.append("doreq", false);
      formDataTckDetails.append("userid", localStorage.getItem("userid"));
      formDataTckDetails.append("av", Constants.av);
      formDataTckDetails.append("pt", Constants.pt);
      formDataTckDetails.append("did", Constants.did);
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(Constants.api + "trans/tckdetails/", formDataTckDetails, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {

              localStorage.setItem("bookingid", resp.data.output.bookingid);
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                tckDetailsOutput: resp.data.output,
                selectSeat: resp.data.output.seatsArr,
                promoEnable: resp.data.output.promoEnable
              });
              // console.log("Ddfdfdfdffdf");

              let tckDetailsArray = [];
              Object.keys(resp.data.output.items).forEach(function (key) {
                tckDetailsArray.push(resp.data.output.items[key]);
              });

              this.setState({
                tckDetails: tckDetailsArray,
                totalPrice: resp.data.output.totalprice,
                payModes: resp.data.output.paymodes.gateway,
              });
              console.log(this.state.tckDetails);
            } else {
              console.log(resp.data.msg);
              swal("something went wrong");
              window.location = "/";
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const applyCouponCode = () => {
      let code = this.state.coupon
      if (code.length > 4) {
        this.setState({ loading: 1 });
        try {
          // const resp =
          let promo = new FormData(); //formdata object
          promo.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
          promo.append("bookingid", localStorage.getItem("bookingid"));
          promo.append("transid", localStorage.getItem("transid"));
          promo.append("booktype", "BOOKING");
          promo.append("promocode", code);
          promo.append("amount", 0);
          promo.append("pt", "WEBSITE");
          promo.append("av", "1.0");
          axios.post(Constants.api + "v1/promocode", promo).then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
                couponcodeApplied: 1,
                couponcode: code,
                discount: resp.data.output.di,
              });
              tckDetailsRequest();
              swal(
                "Congratulations",
                "You got a discount of  " + resp.data.output.di + " OMR !!",
                "success"
              );
              tckDetailsRequest();
            } else {
              this.setState({
                loading: 0,
                couponcode: ''
              });
              swal(resp.data.msg);
            }
            //movie array
          });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      } else swal("", "Please enter a valid promocode", "error");
    };
    const checkRedeem = (redeem) => {
      this.setState({ redeem: redeem })
    }
    const redeemPoints = () => {
      // alert("dsjkdhk")
      if (this.state.redeem) {

        this.setState({ loading: 1 });
        try {
          // const resp =
          let timestamp = Date.now();
          let cs = localStorage.getItem("userid") + "|" + localStorage.getItem("mobenc") + "|" + timestamp;
          var sha512 = require('js-sha512');

          // alert(sha512(cs));
          axios.post(Constants.api + "v1/redeem", {
            "amount": this.state.totalpricebhaisa,
            "av": Constants.av,
            "bookingId": localStorage.getItem("bookingid"),
            "encMob": localStorage.getItem("mobenc"),
            "id": localStorage.getItem("userid"),
            "merchantId": "",
            "mobile": localStorage.getItem("userphone"),
            "points": 0,
            "pt": Constants.pt,
            "status": "INITIALIZED",
            "timestamp": timestamp,
            "transId": localStorage.getItem("transid"),
            "vcode": ""
          }, {
            headers: { "checksum": sha512(cs) },
          }).then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {

              tckDetailsRequest();
              swal(resp.data.msg
              );
              this.setState({ pointsRedeemed: true, paid: resp.data.output.paid })
              if (resp.data.output.paid == "true") { window.location = "/booking?result=success&booking_id=" + localStorage.getItem("bookingid") }
              // tckDetailsRequest();
            } else {
              this.setState({
                loading: 0,
                couponcode: ''
              });
              swal(resp.data.msg);
            }
            //movie array
          });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else
        swal("Please select the checkbox")
    };
    const applyPromocode = (code) => {
      if (code.length > 4) {
        this.setState({ loading: 1 });
        try {
          // const resp =
          let promo = new FormData(); //formdata object
          promo.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
          promo.append("bookingid", localStorage.getItem("bookingid"));
          promo.append("transid", localStorage.getItem("transid"));
          promo.append("booktype", "BOOKING");
          promo.append("promocode", code);
          promo.append("vtype", "");
          promo.append("amount", 0);
          promo.append("pt", "WEBSITE");
          promo.append("av", "1.0");
          axios.post(Constants.api + "v1/loyaltypromo", promo).then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
                promocodeApplied: 1,
                couponcode: code,
                discount: resp.data.output.di,
              });
              swal(
                "Congratulations",
                "You got a discount of  " + resp.data.output.di + " OMR !!",
                "success"
              );
            } else {
              this.setState({
                loading: 0,
              });
              swal(resp.data.msg);
            }
            //movie array
          });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      } else swal("", "Please enter a valid promocode", "error");
    };
    const tckDetailsRender = this.state.tckDetails.map(function (tck, i) {
      if (tck.it.length > 0) {
        return (
          <>
            <React.Fragment key={i}>
              {tck.it.map((det, j) => {
                return (
                  <li>
                    <div class="_food_item_name">{det.n}</div>
                    <div class="_food_item_name">
                      {/* {det.n.startsWith("GST Number") ? "" : "OMR"}  */}
                      {det.v}
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          </>
        );
      } else
        return (
          <li>
            <div class="_food_item_name">{tck.n}</div>
            <div class="_food_item_name">
              {/* {det.n.startsWith("GST Number") ? "" : "OMR"}  */}
              {tck.v}
            </div>
          </li>
        );
    });
    const selectSeatRender = this.state.selectSeat.map(function (item, i) {
      return (
        <>
          <React.Fragment key={i}>
            <li class="square-seat">{item}</li>
          </React.Fragment>
        </>
      );
    });

    let couponcode = this.state.couponcode;
    return (
      <>

        <div className="booking-container container">
          <div class="movie-info payment-movie-info">
            <div class="booking_summary _35px">
              <h3 class="_summary_txt">
                {localStorage.getItem("language") != "ar" ? (
                  "Booking Summary"
                ) : (
                  <Text>ملخص الكتاب</Text>
                )}
              </h3>
              {/* <img class="img-responsive" src={this.state.tckDetailsOutput.imh} alt="img" /> */}
              <div class="_summary_details _border__">
                <div class="_item_list">
                  <p class="_name__">
                    {localStorage.getItem("language") != "ar" ? (
                      "Movie"
                    ) : (
                      <Text>فيلم</Text>
                    )}
                  </p>
                  <h4 class="movie_name__">
                    {this.state.tckDetailsOutput.moviename}{" "}
                    {this.state.tckDetailsOutput.mlanguage}{" "}
                    {/* {this.state.tckDetailsOutput.mlanguage} */}
                  </h4>
                </div>
                <div class="_item_list">
                  <p class="_name__">
                    {localStorage.getItem("language") != "ar" ? (
                      "Location"
                    ) : (
                      <Text>موقع</Text>
                    )}
                  </p>
                  <h4 class="movie_name__">
                    {this.state.tckDetailsOutput.cinemaname}
                  </h4>
                </div>

                <div class="_item_list">
                  <p class="_name__">
                    {localStorage.getItem("language") != "ar" ? (
                      "Show Time"
                    ) : (
                      <Text>موعد العرض</Text>
                    )}
                  </p>
                  <h4 class="movie_name__">
                    {this.state.tckDetailsOutput.showtime}
                  </h4>
                </div>
              </div>
            </div>
            <div class="ticket_prints _35px">
              <span class="_name__">
                {localStorage.getItem("language") != "ar" ? (
                  "SEATS"
                ) : (
                  <Text>مقاعد</Text>
                )}
              </span>
              <span class="_info__ _float_right">
                <ul className="seats-flex">{selectSeatRender}</ul>
                {/* <img src={require('../assets/information.png').default} /> */}
              </span>
              <div class="_print_tickets">
                <span class="_info__ _float_right">
                  <ul>
                    {tckDetailsRender}
                    {this.state.promocodeApplied == 1 ? (
                      <li>
                        <div class="_food_item_name">Discount</div>
                        <div class="_food_item_name expire">
                          {" "}
                          - {this.state.discount} OMR
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* {foodDetailsRender} */}
                  </ul>
                </span>
              </div>
            </div>
            <div class="foods_details_with_coupon"></div>
            {/* <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div> */}
            <div class="total-amount _item_list">
              <span class="_name__">
                {localStorage.getItem("language") != "ar" ? (
                  "Total amount"
                ) : (
                  <Text>المبلغ الإجمالي</Text>
                )}
              </span>
              <span class="amount-value">{this.state.totalPrice}</span>
            </div>
            <div class="booking-button _item_list">
              {/* <button class="_btn btn-back" onClick={backButtonClicked}>
                Back
              </button>
              <button class="_btn btn-next" onClick={this.initiatePayment}>
                Next
              </button> */}
            </div>
          </div>
          <div className="booking-success-container payment-section-container">
            <div class="payment-section">
              <div class="gray-box hidden">
                {/* {this.state.promocodeApplied == 0 ? ( */}
                {this.state.loyaltyVoucher.length > 0 ? (
                  <div class="offer">
                    <div class="row offer-heading border-bot">
                      <h3>
                        {localStorage.getItem("language") != "ar" ? (
                          "Avail Offers"
                        ) : (
                          <Text>عروض الاستفادة</Text>
                        )}
                      </h3>
                    </div>
                    <div class="row offer-preview-display">
                      {this.state.loyaltyVoucher.map(function (item, i) {
                        let cl = "";
                        if (couponcode == item.vcode) cl = "voucher-tags blur";
                        else cl = "voucher-tags";
                        return (
                          <div
                            className={cl}
                            onClick={() => applyPromocode(item.vcode)}
                          >
                            <a href="#">
                              <div className="voucher-tag-html">
                                <div className="voucher-tag-img">
                                  <img
                                    src={
                                      require("../../assets/voucher-card.png")
                                        .default
                                    }
                                  />
                                </div>
                                {/* <div className="voucher-content"> */}
                                {item.expiryDate != null ? (
                                  <>
                                    <p className="voucher-till">
                                      {localStorage.getItem("language") !=
                                        "ar" ? (
                                        "Valid till"
                                      ) : (
                                        <Text>صالح حتى</Text>
                                      )}
                                    </p>
                                    <p className="voucher-valid">
                                      {moment(item.expiryDate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </p>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                                <p className="voucher-type">{item.vname}</p>
                                <p className="voucher-code">
                                  {localStorage.getItem("language") != "ar" ? (
                                    "CODE"
                                  ) : (
                                    <Text>الشفرة</Text>
                                  )}
                                  :{item.vcode}
                                </p>
                                <div className="voucher-off">
                                  <p>{item.vAmount}%</p>
                                  <p>
                                    {localStorage.getItem("language") !=
                                      "ar" ? (
                                      "Flat Off"
                                    ) : (
                                      <Text>شقة معطلة</Text>
                                    )}
                                  </p>
                                </div>
                                {/* </div> */}
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="gray-box  hidden">
                {/* {this.state.promocodeApplied == 0 ? ( */}
                {this.state.bankoffers.length > 0 && !this.state.couponcodeApplied && !this.state.bankOfferApplied && !this.state.pointsRedeemed ? (
                  <div class="offer">
                    <div class="row offer-heading border-bot">
                      <h3>
                        {localStorage.getItem("language") != "ar" ? (
                          "Avail Bank Offers"
                        ) : (
                          <Text>عروض الاستفادة</Text>
                        )}
                      </h3>
                    </div>
                    <div class="row offer-preview-display">
                      {this.state.bankoffers.map(function (item, i) {
                        let cl = "";
                        if (selectedbankoffer == item.id) cl = "voucher-tags ";
                        else cl = "voucher-tags blur";
                        return (
                          <div
                            className={cl}
                            style={{ border: "1px solid gray" }}
                            onClick={() => selectBankOffer(item.id, item.name)}
                          >
                            <a href="#">
                              <div className="voucher-tag-html">
                                <div className="voucher-tag-img gray-box">
                                  {item.name}
                                </div>
                                {/* <div className="voucher-content"> */}
                                {item.validto != null ? (
                                  <>
                                    <p className="voucher-till">
                                      {localStorage.getItem("language") !=
                                        "ar" ? (
                                        "Valid till"
                                      ) : (
                                        <Text>صالح حتى</Text>
                                      )}
                                    </p>
                                    <p className="voucher-valid">
                                      {item.validto}

                                    </p>{" "}
                                  </>
                                ) : (
                                  ""
                                )}

                                <p className="voucher-type">{item.onlineText}</p>

                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                ) : (
                  ""
                )}

              </div>





              {!this.state.bankOfferApplied && !this.state.couponcodeApplied && !this.state.pointsRedeemed && this.state.promoEnable ? <div class="gray-box">
                <div class="payment">
                  <div class="row payment-heading border-bot">
                    <h3>
                      {localStorage.getItem("language") != "ar" ? (
                        "Apply Coupon Code"
                      ) : (
                        <Text>  أدخل رقم البطاقة</Text>
                      )}
                    </h3>
                  </div>
                  <div className="apply-coupon" style={{ padding: "28px" }} >

                    <input onChange={this.handleCouponCodeChange} value={this.state.coupon} placeholder="Enter Promo Code" />




                    <a onClick={applyCouponCode}><button class="btn btn-normal proceed-btn btn-apply" >  Apply </button></a>


                  </div>

                </div>

              </div> :
                this.state.promoEnable ?
                  <div className="offer-info">
                    <>

                      <img src={require("../../assets/check-marks.png").default} />
                      {this.state.bankOfferApplied ? "Bank Offer Applied!" : this.state.coupon + " Applied!"}</>
                  </div>
                  : ''
              }

              {this.state.points ?
                !this.state.pointsRedeemed && !localStorage.getItem("guest") && this.state.points && this.state.points.s && this.state.points.s == "false" ?

                  <div class="gray-box">
                    <div class="payment">
                      <div class="row payment-heading border-bot">
                        <h3>
                          <input type="checkbox" defaultChecked={this.state.redeem}
                            name="redeem"
                            onChange={() => checkRedeem(this.state.redeem ? false : true)} />&nbsp;
                          {this.state.points
                            ? this.state.points.points
                            : ""} available to redeem
                        </h3>
                        <button class="btn btn-normal proceed-btn btn-apply btn-redeem" onClick={redeemPoints}>  Redeem </button>

                      </div>

                    </div>
                  </div> : ''
                : ''}
              <div class="gray-box">
                <div class="payment">
                  <div class="row payment-heading border-bot">
                    <h3>
                      {localStorage.getItem("language") != "ar" ? (
                        "payment options"
                      ) : (
                        <Text>خيارات الدفع</Text>
                      )}
                    </h3>
                  </div>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row className="payment-container" style={{ display: "none" }}>

                      <Col sm={4}>
                        <div className="card-section">
                          {this.state.loading == 0 ?
                            // <Nav variant="pills"
                            //   className="flex-column payment-options">
                            //   <Nav.Link
                            //     onClick={() => this.paylater()}
                            //   >
                            //     Pay at Cinema
                            //   </Nav.Link>
                            // </Nav>
                            this.state.payModes.map(function (pm, j) {
                              let border = (pm.name == selected_paymode) ? " border-card" : '';
                              return (
                                <Nav variant="pills"
                                  onClick={() => selectPaymode(pm.name)}

                                  className={'flex-column payment-options' + border
                                  }
                                >
                                  <Nav.Link
                                  >
                                    {pm.name}
                                  </Nav.Link>
                                </Nav>
                              );
                            })
                            : (
                              <BallTriangle
                                color="#00BFFF"
                                height={80}
                                width={80}
                              />
                            )}
                          {this.state.selected_paymode == 'Bank Offer' ?
                            (this.state.bankoffers.length > 0 && !this.state.couponcodeApplied && !this.state.bankOfferApplied && !this.state.pointsRedeemed ?
                              <div className="card-details">
                                {this.state.bankoffers.map(function (item, i) {
                                  return (<><div onClick={() => selectBankOffer(item.id, item.name)}
                                    className="hdfc-card">{item.name}</div></>)
                                })}
                              </div>
                              : '')
                            : ''}




                        </div>

                      </Col>
                      <Col sm={8} className="card-details">
                        {this.state.selected_paymode != '' && this.state.selected_paymode != 'Pay at Cinema' && this.state.selected_paymode != 'Bank Offer' ?
                          <>
                            <div className="card-details-text">
                              {this.state.selected_paymode} Details

                            </div>


                            <Tab.Content>

                              {/* <input onChange={this.cardNameChange} value={this.state.cardName} /> */}

                              <PaymentInputsContainer>

                                {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps }) => (
                                  <>
                                    {this.state.cardNum.length > 2 ? <svg {...getCardImageProps({ images })} /> : ''}
                                    {this.state.bankOfferApplied ?
                                      <input readOnly value={this.state.cardNum} />

                                      :
                                      <>
                                        <input {...getCardNumberProps({ onChange: this.cardNumberChange })} value={this.state.cardNum} />
                                        {meta.isTouched && meta.error && <span style={{ color: "red" }}>{meta.error}</span>}
                                      </>
                                    }

                                    <div className="input-date">
                                      <div className="input-exp">
                                        <input {...getExpiryDateProps({ onChange: this.expiryDateChange })} value={this.state.expiryDate} />
                                        <span style={{ color: "red" }}>
                                          {this.validator.message(
                                            "Expiry Date",
                                            this.state.expiryDate,
                                            "required"
                                          )}
                                        </span>
                                      </div>

                                      <div className="input-cv">
                                        <input
                                          {...getCVCProps({ onChange: this.cvNumberChange })} value={this.state.cvv} />

                                        <span style={{ color: "red" }}>
                                          {this.validator.message(
                                            "cvv",
                                            this.state.cvv,
                                            "required"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </>

                                )}
                              </PaymentInputsContainer>
                              <div className="card-name">
                                <input onChange={this.cardNameChange} placeholder=" NAME ON THE CARD" value={this.state.cardName} />

                              </div>

                              {/* <button class="btn btn-normal proceed-btn"><a href="#">   Proceed</a></button> */}



                            </Tab.Content>
                            <button type="btn" class="btn-pay proceed-btn btn" onClick={() => initiatePayment(this.state.selected_paymode)} >Proceed</button>
                          </> : ''}
                        {this.state.selected_paymode == 'Bank Offer' ? <>
                          {this.state.bankoffers.length > 0 && !this.state.couponcodeApplied && !this.state.bankOfferApplied ? (
                            <div class="offer">
                              <div class="row offer-heading border-bot">
                                <h3>
                                  {localStorage.getItem("language") != "ar" ? (
                                    "Avail Bank Offers"
                                  ) : (
                                    <Text>عروض الاستفادة</Text>
                                  )}
                                </h3>
                              </div>
                              <div class="row offer-preview-display">

                                {this.state.showbankoffercard && !this.state.bankOfferApplied && !this.state.couponcodeApplied ? <div class="gray-box">
                                  <div class="payment">
                                    <div class="row payment-heading border-bot">
                                      <h3>
                                        {localStorage.getItem("language") != "ar" ? (
                                          "Enter " + this.state.bankname + " Card Number"
                                        ) : (
                                          <Text>  {this.state.bankname} أدخل رقم البطاقة</Text>
                                        )}
                                      </h3>
                                    </div>
                                    <div className="card-info-section"  >
                                      <PaymentInputsContainer>

                                        {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps }) => (
                                          <>

                                            {this.state.bankOfferApplied ?
                                              <input readOnly value={this.state.bankoffercard} />

                                              :
                                              <>
                                                <input  {...getCardNumberProps({ onChange: this.bankOfferCardNumberChange })} value={this.state.bankoffercard} />
                                              </>
                                            }
                                            {meta.isTouched && meta.error && <span style={{ color: "red" }}>{meta.error}</span>}
                                            {meta.isTouched && meta.error ? <button class="btn btn-normal proceed-btn " disabled="true">  Apply Offer</button> :
                                              <a onClick={applyBankOffer}><button class="btn btn-normal proceed-btn " >  Apply Offer</button></a>}


                                          </>
                                        )}
                                      </PaymentInputsContainer>



                                    </div>
                                  </div>
                                </div> : ''}

                              </div>
                            </div>

                          ) : (
                            ""
                          )}
                        </> : <></>}

                      </Col>
                    </Row>
                    <Row className="payment-container" style={{ pointer: "cursor" }}>
                      <Col sm={6} onClick={() => initiatePayment("CC")} style={{ pointer: "cursor" }}>
                        <img class="img-responsive"

                          style={{ width: "80%", margin: "10px" }}
                          src={require("../../assets/creditcard.png").default}
                        />
                      </Col>
                      <Col sm={6} onClick={() => initiatePayment("DC")} style={{ pointer: "cursor" }}>
                        <img class="img-responsive"

                          style={{ width: "80%", margin: "10px" }}
                          src={require("../../assets/debitcard.png").default}
                        />
                      </Col>

                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Payment;
