import React from "react";
import Carousel from "react-multi-carousel";
import "./contact.styles.css";
import * as Constants from "../../Constants";
// import '../CSS/payment.css';
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Text from "react-text";

class Careers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: "", mobile: "", email: "", message: "", type: "" };
    this.validator = new SimpleReactValidator();
  }
  namehandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  mobilehandleChange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  messagehandleChange = (event) => {
    this.setState({ message: event.target.value });
  };
  typehandleChange = (event) => {
    this.setState({ type: event.target.value });
  };
  formSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      try {
        let data = new FormData(); //formdata object
        data.append("name", this.state.name);
        data.append("type", this.state.type);
        data.append("mobile", this.state.mobile);

        data.append("comment", this.state.message);

        data.append("email", this.state.email);
        data.append("av", Constants.av);
        data.append("pt", Constants.pt);

        // const resp =
        axios.post(Constants.api + "contactus", data).then((resp) => {
          //  casetype array
          swal(
            "Your query has been submitted. We will get in touch with you shortly."
          );
          //movie array
        });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    } else this.validator.showMessages();
  };

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header-about">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-about"
            removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
        </div>
        <div className="container contact-us-container">
          <div className="contact-form">
            {/* <h2>Contact Us</h2> */}
            <div className="form-group">
              {localStorage.getItem("language") != "ar" ? (
                "Mail your Resume to"
              ) : (
                <Text>أرسل سيرتك الذاتية بالبريد إلى</Text>
              )}{" "}
              <a href="mailto:contactus@wowcinemas.om">
                contactus@wowcinemas.om
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Careers;
