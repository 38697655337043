import React from "react";
import NewsPreview from "../news-preview/NewsPreview.component";
import "./News.style.css";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Text from "react-text";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
    };
  }
  componentDidMount() {
    const newsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios.post(Constants.api + "getnews/", "").then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            let rowsArray = [];
            Object.keys(resp.data.output).forEach(function (key) {
              rowsArray.push(resp.data.output[key]);
            });
            this.setState({
              collections: rowsArray,
            });
          } else {
            console.log(resp.data.msg);
            // swal(resp.data.msg);
            this.setState({
              loading: 0,
            });
          }
          //movie array
        });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    newsRequest();
  }
  render() {
    const { collections } = this.state;
    return (
      <div className="news hidden">
        {collections.length > 0 ? (
          <div className="news-box container">
            <h3>
              {localStorage.getItem("language") != "ar" ? (
                <Text> latest news</Text>
              ) : (
                <Text>أحدث الأخبار</Text>
              )}
            </h3>
            <div className="news-preview-collection">
              {collections.map(({ id, ...otherAdditionalProps }) => (
                <NewsPreview key={id} {...otherAdditionalProps} />
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default News;
