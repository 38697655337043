import React from "react";
import "./bookinsuccess.style.css";
import * as Constants from "../../Constants";
// import '../CSS/payment.css';
import axios from "axios";
// import * as QueryString from "query-string";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Text from "react-text";
class BookingSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qrdata: "",
      ticketCount: 0,
      amount: "",
      bookingid: "",
      error: 0,
      unpaid: "true",
      result: new URLSearchParams(window.location.search).get("result"),
      bookingid: new URLSearchParams(window.location.search).get("booking_id"),
      firsttime: 0
    };
  }
  componentDidMount() {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        window.location = "/"
      }
    }
    if (this.state.result == "success") {
      let ticketData = new FormData(); //formdata object
      ticketData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
      ticketData.append("bookingid", localStorage.getItem("bookingid"));
      ticketData.append("transid", localStorage.getItem("transid"));
      ticketData.append("booktype", "BOOKING");
      ticketData.append("pt", "WEBSITE");

      ticketData.append("av", "1.0");
      axios
        .post(Constants.api + "trans/ticketBooked", ticketData)
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            this.setState({
              qrdata: resp.data.output.qrdata,
              amount: resp.data.output.totalprice,
              ticketCount: resp.data.output.seatsArr.length,
              bookingid: resp.data.output.bookingid,
              unpaid: resp.data.output.unpaid,
            });
            localStorage.removeItem("ccode");
            localStorage.removeItem("bookingid");
            localStorage.removeItem("transid");

            //  this.makePayment(blink_obj);
          } else {
            swal(resp.data.msg);
            this.setState({
              error: 1,
            });
            localStorage.removeItem("ccode");
            localStorage.removeItem("bookingid");
            localStorage.removeItem("transid");
          }
          //movie array
        });
    }
  }

  render() {
    let qrimage = "data:image/png;base64," + this.state.qrdata;
    return (
      <div className="booking-container">



        {this.state.result == "fail" ? (
          <div className="booking-success-container container">
            <div className="wow_successfully">
              <div className="successfully_info">
                <div className="successfully_text">

                  <h2>
                    {localStorage.getItem("language") != "ar" ? (
                      "Transaction Failed!"
                    ) : (
                      <Text>فشل الاجراء</Text>
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        ) :
          ""}



        {this.state.error == 0 && this.state.loading == 0 ? (
          <>
            <div class="movie-info hidden">
              <div class="booking_summary _35px ">
                <h3 class="_summary_txt">Booking Summary</h3>
                {/* <img class="img-responsive" src={this.state.tckDetailsOutput.imh} alt="img" /> */}
                <div class="_summary_details _border__">
                  <div class="_item_list">
                    <p class="_name__">Movie</p>
                    <h4 class="movie_name__">
                      {/* {this.state.tckDetailsOutput.m}{" "}
                  {this.state.tckDetailsOutput.cen}{" "}
                  {this.state.tckDetailsOutput.lg} */}
                    </h4>
                  </div>
                  <div class="_item_list">
                    <p class="_name__">Location</p>
                    <h4 class="movie_name__">
                      {/* {this.state.tckDetailsOutput.c} */}
                    </h4>
                  </div>

                  <div class="_item_list">
                    <p class="_name__">Date</p>
                    <h4 class="movie_name__">
                      {/* {this.state.tckDetailsOutput.md} */}
                    </h4>
                  </div>

                  <div class="_item_list">
                    <p class="_name__">Time</p>
                    <h4 class="movie_name__">
                      {/* {this.state.tckDetailsOutput.t} */}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="ticket_prints _35px">
                <span class="_name__">Seat Info</span>
                <span class="_info__ _float_right">
                  {/* <img src={require('../assets/information.png').default} /> */}
                </span>
                <div class="_print_tickets">
                  <ul>{/* {selectSeatRender} */}</ul>
                </div>
              </div>
              <div class="foods_details_with_coupon">
                <ul class="food_list_selected_">
                  {/* {tckDetailsRender} */}
                  {/* {foodDetailsRender} */}
                </ul>
              </div>
              {/* <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div> */}
              <div class="total-amount _item_list">
                <span class="_name__">Total Amount</span>
                <span class="amount-value">
                  {/* {this.state.totalPrice}  */}
                  OMR
                </span>
              </div>
              <div class="booking-button _item_list">
                <button class="_btn btn-back">Back</button>
                <button class="_btn btn-next">Next</button>
              </div>
            </div>
            <div className="booking-success-container container">
              <div className="wow_successfully">
                <div className="successfully_info">
                  <div className="successfully_img">
                    {this.state.result == "success" ? (
                      <img src={qrimage} alt="img" />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="successfully_text">
                    {this.state.result == "fail" ? (
                      <h2>
                        {localStorage.getItem("language") != "ar" ? (
                          "Transaction Failed!"
                        ) : (
                          <Text>فشل الاجراء</Text>
                        )}
                      </h2>
                    ) :
                      ""}
                    {this.state.result == "success" &&
                      this.state.bookingid != "" ? (
                      <h2>
                        {localStorage.getItem("language") != "ar" ? (
                          "Ticket Booked Successfully!"
                        ) : (
                          <Text>تم حجز التذكرة بنجاح</Text>
                        )}
                      </h2>
                    ) : (
                      <h2>
                        {localStorage.getItem("language") != "ar" ? (
                          "Transaction Failed!"
                        ) : (
                          <Text>فشل الاجراء</Text>
                        )}
                      </h2>
                    )}
                    {this.state.result == "success" &&
                      this.state.bookingid != "" ? (
                      this.state.unpaid == "true" ?
                        <p>
                          {localStorage.getItem("language") != "ar" ? (
                            `Enjoy your movie! You have succesfully booked 
                      ${this.state.ticketCount} tickets and amount to be paid at
                      cinema is ${this.state.amount}. Your booking id is 
                      ${this.state.bookingid}. Reach 30 minutes prior to the show
                      time to collect your ticket at the counter.`
                          ) : (
                            <Text>
                              تمتع بفلمك! لقد حجزت {this.state.ticketCount} تذكرة
                              بنجاح والمبلغ الذي يجب دفعه في السينما هو
                              {this.state.amount} ريال عُماني. معرف الحجز الخاص بك
                              هو
                              {this.state.bookingid}. قم بالوصول قبل 30 دقيقة من
                              موعد العرض لاستلام تذكرتك من الشباك.
                            </Text>
                          )}
                        </p> : <p>
                          {localStorage.getItem("language") != "ar" ? (
                            `Enjoy your movie! You have succesfully booked 
                      ${this.state.ticketCount} tickets and amount paid at
                      cinema is ${this.state.amount}. Your booking id is 
                      ${this.state.bookingid}. Reach 30 minutes prior to the show
                      time to collect your ticket at the counter.`
                          ) : (
                            <Text>
                              تمتع بفلمك! لقد حجزت {this.state.ticketCount} تذكرة
                              بنجاح والمبلغ الذي يجب دفعه في السينما هو
                              {this.state.amount} ريال عُماني. معرف الحجز الخاص بك
                              هو
                              {this.state.bookingid}. قم بالوصول قبل 30 دقيقة من
                              موعد العرض لاستلام تذكرتك من الشباك.
                            </Text>
                          )}
                        </p>
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.result == "success"
                    ? ""
                    : // <div className="wow_successfully_btn">
                    //   <button type="button" className="wow_btn wow_download">
                    //     <img
                    //       src={require("../../assets/get_app.png").default}
                    //       alt="icon"
                    //     />
                    //     Download
                    //   </button>
                    //   <button type="button" className="wow_btn wow_share">
                    //     {" "}
                    //     <img
                    //       src={require("../../assets/share.png").default}
                    //       alt="icon"
                    //     />
                    //     Share
                    //   </button>
                    // </div>
                    ""}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default BookingSuccess;
