import React from "react";
import Carousel from "react-multi-carousel";
import "./contact.styles.css";
import * as Constants from "../../Constants";
// import '../CSS/payment.css';
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Text from "react-text";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      mobile: "",
      email: "",
      message: "",
      type: this.props && this.props.type ? this.props.type : "",
    };
    this.validator = new SimpleReactValidator();
  }
  namehandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  mobilehandleChange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  messagehandleChange = (event) => {
    this.setState({ message: event.target.value });
  };
  typehandleChange = (event) => {
    this.setState({ type: event.target.value });
  };
  componentDidMount() {
    if (this.state.type == "ps") {
      let element = document.getElementById("type");
      element.value = "Private Screening";
    }
  }
  formSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      try {
        let data = new FormData(); //formdata object
        data.append("name", this.state.name);
        data.append("type", this.state.type);
        data.append("mobile", this.state.mobile);

        data.append("comment", this.state.message);

        data.append("email", this.state.email);
        data.append("av", Constants.av);
        data.append("pt", Constants.pt);

        // const resp =
        axios.post(Constants.api + "contactus", data).then((resp) => {
          //  casetype array
          swal(
            "",
            "Your query has been submitted. We will get in touch with you shortly.",
            "success"
          );
          this.setState({
            name: "",
            mobile: "",
            email: "",
            message: "",
          });
          document.getElementById("contact-form").reset();
          this.validator.hideMessages();

          //movie array
        });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header-about">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-about"
            removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
        </div>
        <div className="container contact-us-container">
          <div className="contact-details">
            <div className="contact-box">
              <h4>For More Info:</h4>
              <div className="contact-inline">
                <div className="contact-left">
                  <i class="fas fa-envelope"></i> Mail us on:
                </div>
                <div className="contact-right">
                  <a href="mailTo: contactus@wowcinemas.om">
                    contactus@wowcinemas.om
                  </a>
                </div>
              </div>
              <div className="contact-inline">
                <div className="contact-left">
                  <i class="fas fa-phone-alt"></i> Customer Service:
                </div>
                <div className="contact-right">26985414</div>
              </div>
            </div>
          </div>
          <div className="contact-form">
            {/* <h2>Contact Us</h2> */}
            <form id="contact-form" onSubmit={this.formSubmit}>
              <div className="form-group">
                <label htmlFor="name">
                  {localStorage.getItem("language") != "ar" ? (
                    "Type"
                  ) : (
                    <Text>نوع </Text>
                  )}
                </label>
                <select
                  type="text"
                  className="form-control"
                  id="type"
                  name="type"
                  onChange={() => this.typehandleChange}
                >
                  <option value="contact">
                    {localStorage.getItem("language") != "ar"
                      ? "Enquiry"
                      : "سؤال"}
                  </option>
                  <option value="feedback">
                    {localStorage.getItem("language") != "ar"
                      ? "Feedback"
                      : "تعليق"}
                  </option>
                  <option value="Private Screening">
                    {localStorage.getItem("language") != "ar"
                      ? "Private Screening"
                      : "فحص خاص"}
                  </option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="name">
                  {localStorage.getItem("language") != "ar" ? (
                    "Name"
                  ) : (
                    <Text> اسم </Text>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={this.namehandleChange}
                />
              </div>
              {this.validator.message("name", this.state.name, "required")}

              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  {localStorage.getItem("language") != "ar" ? (
                    "Email address"
                  ) : (
                    <Text> عنوان البريد الالكترونى </Text>
                  )}
                </label>
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  name="email"
                  onChange={this.emailhandleChange}
                />
              </div>
              {this.validator.message("email", this.state.email, "required")}
              <div className="form-group">
                <label htmlFor="name">
                  {localStorage.getItem("language") != "ar" ? (
                    "Mobile"
                  ) : (
                    <Text> التليفون المحمول </Text>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  onChange={this.mobilehandleChange}
                />
              </div>
              {this.validator.message(
                "mobile",
                this.state.mobile,
                "required|numeric"
              )}
              <div className="form-group">
                <label htmlFor="message">
                  {localStorage.getItem("language") != "ar" ? (
                    "Message"
                  ) : (
                    <Text> رسالة </Text>
                  )}
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="message"
                  onChange={this.messagehandleChange}
                ></textarea>
              </div>
              {this.validator.message(
                "message",
                this.state.message,
                "required"
              )}

              <button type="submit" className="club book-now">
                {localStorage.getItem("language") != "ar" ? (
                  "Submit"
                ) : (
                  <Text> إرسال </Text>
                )}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
