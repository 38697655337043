import React, { Component } from "react";
import "../App.css";
import { Offline, Online } from "react-detect-offline";
import logo from "../assets/Logo.png";
class Loading extends Component {
  render() {
    return (
      <>
        {/* <div class="loading-screen">
          <img src={logo} alt="img" />

          <br />
          <Offline>
            <h4 id="internet" style={{ color: "black" }}>
              Uh oh! Please check your internet connection.
            </h4>{" "}
          </Offline>
        </div>
        <section class="footer">
          <div class="footer" style={{ color: "black", bottom: "10px" }}>
            Powered By <b>Wemonde</b>
          </div>
          <div class="loading-screen"></div>
        </section> */}
      </>
    );
  }
}
export default Loading;
