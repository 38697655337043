import React from "react";
import "./about.style.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import Text from "react-text";

class Appwowclub extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    localStorage.setItem('language', 'en')
  }
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <>
        <div className="header-about">

          <div className="about_wow container">
            <div className="know_more">

              <h3>

                <Text>
                  {" "}
                  <center> Welcome Guest</center>
                </Text>

              </h3>
              <br />
              {/* <img
                src={require("../../assets/about_one.jpg").default}
                alt="img"
              /> */}
            </div>
            <div className="about_text">
              <p>

                <Text>
                  {" "}
                  <p>   Become an exlcusive member of WOW club to avail amazing benefits on every purchase.   </p>

                  {/* <center><img
                    src={require("../../assets/6Tr6R9aac.gif").default}
                    className="d-block "
                    height="30px"
                    alt="img"
                  /></center> */}
                  <br />
                  <ol>
                    <p>
                      You will get welcome points on registering to the WOW Club.
                    </p>
                    <p>
                      You will get loyalty points(upto 5% of the amount) on all transactions on website and app.
                    </p>
                    <p>
                      You will also earn points on updating your profile.
                    </p>
                    <p>
                      You will be able to redeem your points through website, app as well as from POS in cinema.
                    </p>
                  </ol>
                  <p>and many more...</p>

                </Text>

              </p>

            </div>

          </div>
        </div>
      </>
    );
  }
}
export default Appwowclub;
