import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "./locations.css";


class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cinemas: [],
      showmap: false,
    };
  }

  componentDidMount() {
    let logindata = new FormData(); //logindata object
    logindata.append("pt", Constants.pt);
    logindata.append("av", Constants.av);
    logindata.append("did", Constants.did);
    axios
      .post(Constants.api + "content/cinemas", logindata, {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          av: "1.0",
          platform: "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);

        console.log("cinemas");
        this.setState({
          cinemas: res.data.output,
        });
      });
  }
  render() {
    const openmap = (lat, long) => {
      let map =
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyAgURva0yoMANv5oC9zAsn_bCMuZGLlSFc&q=" +
        lat +
        "," +
        long;
      this.setState({ mapurl: map, showmap: true });

    };
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header-about">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-about"
            removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
          <div className="about_wow wow-location container">
            <div className="Wow_booking_section">

              <div className="cinescap_location_section">
                <div class='home-content-container'>
                  <h2>
                    {localStorage.getItem("language") != "ar" ? (
                      "Wow locations"
                    ) : (
                      <Text>المواقع</Text>
                    )}
                  </h2>


                  <div className="wow_info">
                    {this.state.cinemas.map(function (item, i) {
                      return (
                        <>
                          <div className="wow_location">
                            <div className="flim_info">
                              <img
                                src={item.imageUrl}
                                alt="img"
                              />
                              <div className="house_text">
                                <h3>{item.cinemaStrName}</h3>
                                <div className="location_info">
                                  <h4>
                                    {" "}
                                    {localStorage.getItem("language") != "ar" ? (
                                      "address"
                                    ) : (
                                      <Text>موقع</Text>
                                    )}
                                  </h4>
                                  <p>{item.address} </p>
                                  <p
                                    className="map club book-now"
                                    onClick={() =>
                                      openmap(item.latitude, item.longitude)
                                    }
                                  >
                                    <div className="img-map">
                                      {/* <img
                                    src={require("../assets/location.png").default}
                                  /> */}
                                    </div>
                                    <div className="text-map" style={{ cursor: "pointer" }}>
                                      {" "}

                                      {localStorage.getItem("language") != "ar" ? (

                                        "Map"
                                      ) :
                                        (
                                          <Text>خرائط</Text>
                                        )}
                                    </div>
                                  </p>
                                </div>


                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div></div>
        {
          this.state.showmap ? (
            <>
              <Modal
                isOpen={true}
                contentLabel="Example Modal"
                portalClassName="trai-modal"
              >
                {" "}
                <button
                  className="trailer-cross"
                  onClick={() => this.setState({ showmap: false })}
                >
                  <i class="fas fa-times"></i>
                </button>
                <iframe
                  src={this.state.mapurl}
                  width="100%"
                  height="300px"
                ></iframe>
              </Modal>
            </>
          ) : (
            ""
          )
        }
      </>
    );
  }
}
export default Location;
