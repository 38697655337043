import React from "react";
import "./NewsPreview.style.css";

const NewsPreview = ({ title, desc, nd, i }) => (
  <div className="news-preview">
    <div className="news-img">
      <img src={i} alt="img" />
    </div>
    <div className="news-content">
      <p>{nd}</p>
      <h4>{title}</h4>
      <p>{desc.substring(0, 100)}..</p>
    </div>
  </div>
);

export default NewsPreview;
