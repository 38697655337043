import React, { Component } from "react";

import * as Constants from "../../Constants/index";
import "./SeatLayout.style.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as QueryString from "query-string";
import swal from "sweetalert";
import { FaClock, FaShareAlt } from "react-icons/fa";
// import MovieDetails from "../movie-details/MovieDetails.component";
import Login from "../login/login.component";
// import { Modal } from "react-bootstrap";
import Modal from "react-modal";
import Text from "react-text";
import TrailerModal from "../trailer/TrailerModal.component";
import GuestLogin from "../login/guestlogin.component";
class SeatLayout extends Component {
  constructor(props) {
    super(props);
    // this.otpvalidator = new SimpleReactValidator();
    // this.forgotvalidator = new SimpleReactValidator();
    // this.resetvalidator = new SimpleReactValidator();
    // this.resetpassvalidator = new SimpleReactValidator();
    // this.otploginvalidator = new SimpleReactValidator();
    // this.registervalidator = new SimpleReactValidator();
    // this.validator = new SimpleReactValidator();
  }

  state = {
    cinemaname: this.props.match.params.cinemaname,
    moviename: this.props.match.params.moviename,
    mcode: QueryString.parse(this.props.location.search).mcode,
    ccode: QueryString.parse(this.props.location.search).cinemacode,
    sessionid: QueryString.parse(this.props.location.search).sessionid,
    screenId: QueryString.parse(this.props.location.search).screenId,
    rows: [],
    output: {},
    moviedetail: {},
    showNextButton: 0,
    loading: 1,
    selectedSeats: [],
    ticketPrice: 0,
    prices: [],
    ticketCount: 0,
    transOutput: {},
    seatsToSet: [],
    reserveOutput: {},
    showTrailer: false,
    showLogin: false
  };

  reserveData = {
    transid: "",
    cinemacode: "",
    sessionid: "",
    reseveSeatVOs: [],
    av: Constants.av,
    pt: Constants.pt,
  };

  componentDidMount() {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        this.props.history.goBack();
      }
    }
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataNS.append("mid", this.state.mcode);
    formDataNS.append("lat", this.state.lat);
    formDataNS.append("lng", this.state.lng);
    formDataNS.append("date", this.state.date);
    formDataNS.append("userid", localStorage.getItem("userid"));
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);

    const movieSessionRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(
            Constants.api +
            "trans/getseatlayout/" +
            this.state.ccode +
            "/" +
            this.state.sessionid,
            formDataNS,
            {
              headers: { "Accept-Language": localStorage.getItem("language") },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              console.log(this.state.mname);
              console.log(this.state.mcode);
              this.setState({
                output: resp.data.output,
              });

              let rowsArray = [];
              Object.keys(resp.data.output.rows).forEach(function (key) {
                rowsArray.push(resp.data.output.rows[key]);
              });

              let priceArray = [];
              Object.keys(resp.data.output.priceList).forEach(function (key) {
                priceArray.push(resp.data.output.priceList[key]);
              });
              this.setState({ moviedetail: resp.data.output.movie });
              // const AT = resp.data.output.et.toInt() * 60 * 1000 - (resp.data.output.at.toInt() * 60 * 1000);
              // const ET = resp.data.output.at.toInt() * 60 * 1000;
              localStorage.setItem("ccode", resp.data.output.cinemaCode);
              localStorage.setItem("mcode", this.state.mcode);
              localStorage.setItem("mname", this.state.output.movie.n);
              localStorage.setItem("cname", resp.data.output.cn);
              localStorage.setItem("showDate", this.state.output.st);

              this.setState({
                rows: rowsArray,
                prices: priceArray,
              });
              console.log(this.state.prices);
            } else {
              console.log(resp.data.msg);
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    movieSessionRequest();
  }

  setSeats = () => {
    // if (localStorage.getItem("userid") == 0) {
    //   this.setState({ showLogin: true });
    // } else {
    this.reserveData.transid = "0";
    this.reserveData.cinemacode = this.state.ccode;
    this.reserveData.sessionid = this.state.sessionid;
    this.reserveData.reseveSeatVOs = this.state.seatsToSet;

    try {
      this.setState({
        loading: 1,
      });
      axios
        .post(Constants.api + "trans/reserveseats", this.reserveData)
        .then((resp) => {
          console.log(this.state.reserveOutput.bookingid);
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            this.setState({
              reserveOutput: resp.data.output,
            });
            localStorage.setItem(
              "bookingid",
              this.state.reserveOutput.bookingid
            );
            localStorage.setItem("transid", this.state.reserveOutput.transid);
            if (resp.data.output.fc == "true") {
              window.location = "/food/" + this.state.cinemaname;
            } else {
              window.location =
                "/payment/" +
                this.state.cinemaname +
                "/" +
                this.state.moviename;
            }
          } else {
            console.log(resp.data.msg);
            swal(resp.data.msg);
            this.setState({
              loading: 0,
              selectedSeats: [],
            });
            setTimeout(function () {
              window.location.reload();
            }, 1500);
          }
        });
    } catch (err) {
      console.error(err);
    }

    // }

  };

  render() {
    const MovieDetails = ({ nm, len, gnr, lng, lngs, mih, miv, t }) => (
      <div
        className="movie-details container"
      // style={{ backgroundImage: `url(${mih})` }}
      >
        <div className="booking-poster">
          <img src={miv} alt="img" />
        </div>
        <div className="booking-movie-details">
          <h3>{nm}</h3>

          <p>
            {len ? <FaClock /> : ""} {len} {"mins"} | {lng}
          </p>
          {t != "" ? (
            <div>
              <button
                className="btn btn-normal"
                onClick={() => this.setState({ showTrailer: true })}
              >
                <a href="#">
                  {localStorage.getItem("language") != "ar" ? (
                    "Watch Trailer"
                  ) : (
                    <Text>مشاهدة اعلان</Text>
                  )}
                </a>
              </button>{" "}
            </div>
          ) : (
            ""
          )}
          {/* <button className="btn btn-normal"><a href="#">Watch Trailer</a></button> <FaShareAlt className="share"/> */}
        </div>
      </div>
    );
    const seatLayoutRender = this.state.rows.map(function (item, i) {
      if (item.t == "area") {
        return (
          <>
            <React.Fragment key={i}>
              <div class="seats-row">
                <div class="seats-col _class_lable">
                  <span class="seats__area">{item.n}</span>
                </div>
              </div>
            </React.Fragment>
          </>
        );
      }
      if (item.t == "seats") {
        return (
          <>
            <React.Fragment key={i}>
              <div class="seats-row">
                <div class="seats-col">
                  <span class="seat-row-no">{item.n}</span>
                </div>
                {item.s.map((seat, j) => {
                  if (seat.s == 0) {
                    return (
                      <div class="seats-col">
                        <span class="seat _hide">{seat.displaynumber}</span>
                      </div>
                    );
                  } else if (seat.s == 1) {
                    return (
                      <div class="seats-col">
                        {/* <span id={seat.b} class="seat current">{seat.displaynumber}</span> */}
                        <span
                          id={seat.b}
                          className="seat current"
                          onClick={() => selectSeat(seat.b, seat.sn, seat.c)}
                        >
                          {/* <img src={require('../../assets/hollowseat.png').default} /> */}
                        </span>
                      </div>
                    );
                  } else if (seat.s == 2) {
                    return (
                      <div class="seats-col">
                        <span class="seat disable current">
                          {seat.displaynumber}
                        </span>
                      </div>
                    );
                  } else if (seat.s == 3) {
                    return (
                      <div class="seats-col covid-seat " titile="restricted">
                        <img src={require("../../assets/close.png").default} />
                      </div>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          </>
        );
      }
    });
    const selectSeat = (seatValue, seatNumber, areaNumber) => {
      if (this.state.ticketCount == 10) {
        let selectedSeatsArray = this.state.selectedSeats;

        var index = selectedSeatsArray.indexOf(seatNumber);

        if (index == -1) swal("You can only book 10 tickets at a time.");

        this.setState({ showNextButton: 1 });
        let tctPrice = this.state.ticketPrice;
        console.log(areaNumber);
        let areaPrice = this.state.output.priceList[areaNumber];
        console.log(areaPrice);
        let tckCount = this.state.ticketCount;

        var element = document.getElementById(seatValue);
        if (selectedSeatsArray.includes(seatNumber) === true) {
          var index = selectedSeatsArray.indexOf(seatNumber);
          if (index !== -1) {
            element.classList.remove("selected");
            tctPrice -= parseFloat(areaPrice.price);
            this.setState({ ticketPrice: tctPrice });
            tckCount--;
            this.setState({ ticketCount: tckCount });

            selectedSeatsArray.splice(index, 1);
            this.state.seatsToSet.splice(index, 1);
            if (selectedSeatsArray.length == 0) {
              this.setState({ showNextButton: 0 });
            }
          }
        }
        return;
      }
      this.setState({ showNextButton: 1 });
      let tctPrice = this.state.ticketPrice;
      console.log(areaNumber);
      let areaPrice = this.state.output.priceList[areaNumber];
      console.log(areaPrice);
      let tckCount = this.state.ticketCount;

      var element = document.getElementById(seatValue);
      let selectedSeatsArray = this.state.selectedSeats;
      if (selectedSeatsArray.includes(seatNumber) === true) {
        var index = selectedSeatsArray.indexOf(seatNumber);
        if (index !== -1) {
          element.classList.remove("selected");
          tctPrice -= parseFloat(areaPrice.price);
          this.setState({ ticketPrice: Number(tctPrice.toFixed(3)) });
          tckCount--;
          this.setState({ ticketCount: tckCount });

          selectedSeatsArray.splice(index, 1);
          this.state.seatsToSet.splice(index, 1);
          if (selectedSeatsArray.length == 0) {
            this.setState({ showNextButton: 0 });
          }
        }
      } else {
        tctPrice += parseFloat(areaPrice.price);
        this.setState({ ticketPrice: Number(tctPrice.toFixed(3)) });
        tckCount++;
        this.setState({ ticketCount: tckCount });
        element.classList.add("selected");
        selectedSeatsArray.push(seatNumber);
        let seatObj = {};
        seatObj.priceCode = areaPrice.priceCode;
        seatObj.seatBookingId = seatValue;

        this.state.seatsToSet.push(seatObj);
      }

      this.setState({
        selectedSeats: selectedSeatsArray,
      });

      console.log(this.state.selectedSeats);
    };

    const selectSeatRender = this.state.selectedSeats.map(function (item, i) {
      return (
        <>
          <React.Fragment key={i}>
            <li>{item}</li>
          </React.Fragment>
        </>
      );
    });

    const backButtonClicked = () => {
      this.props.history.goBack();
    };

    return (
      <>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.moviedetail.t} />
            </Modal>
          </>
        ) : (
          ""
        )}
        <div className="booking">

          <div
            className="booking-upper"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url(${this.state.moviedetail ? this.state.moviedetail.mih : ""
                })`,
            }}
          >
            {
              // <MovieDetails {...output}/>
              <MovieDetails
                nm={
                  this.state.moviedetail && this.state.moviedetail.n
                    ? this.state.moviedetail.n
                    : ""
                }
                miv={
                  this.state.moviedetail && this.state.moviedetail.miv
                    ? this.state.moviedetail.miv
                    : ""
                }
                len={
                  this.state.moviedetail && this.state.moviedetail.l
                    ? this.state.moviedetail.l
                    : ""
                }
                gnr={
                  this.state.moviedetail && this.state.moviedetail.genres
                    ? this.state.moviedetail.genres
                    : ""
                }
                lng={
                  this.state.moviedetail && this.state.moviedetail.lng
                    ? this.state.moviedetail.lng
                    : ""
                }
                t={
                  this.state.moviedetail && this.state.moviedetail.lng
                    ? this.state.moviedetail.t
                    : ""
                }
              />
            }
          </div>
          <div className="booking-lower">
            <div className="bookin-lower-contain container">
              <div className="summary-display">
                <div class="sticky-right summary-sticky">
                  <div class="booking_summary _35px">
                    <h3 class="_summary_txt">
                      {localStorage.getItem("language") != "ar" ? (
                        "Booking Summary"
                      ) : (
                        <Text>ملخص الكتاب</Text>
                      )}
                    </h3>
                    {/* <img class="img-responsive" src={this.state.output.imh} alt="img" /> */}
                    <div class="_summary_details _border__">
                      <div class="_item_list">
                        <p class="_name__">
                          {localStorage.getItem("language") != "ar" ? (
                            "Movie"
                          ) : (
                            <Text>فيلم</Text>
                          )}
                        </p>
                        <h4 class="movie_name__">{this.state.moviedetail.n}</h4>
                      </div>
                      <div class="_item_list">
                        <p class="_name__">
                          {localStorage.getItem("language") != "ar" ? (
                            "Location"
                          ) : (
                            <Text>موقع</Text>
                          )}
                        </p>
                        <h4 class="movie_name__">{this.state.output.cn}</h4>
                      </div>
                      <div class="_item_list">
                        <p class="_name__">
                          {localStorage.getItem("language") != "ar" ? (
                            "Date"
                          ) : (
                            <Text>تاريخ</Text>
                          )}
                        </p>
                        <h4 class="movie_name__">{this.state.output.st}</h4>
                      </div>
                      <div class="_item_list seat-list">
                        <div className="seat-selected">
                          {this.state.selectedSeats.map((items) => {
                            return <div className="square-seat">{items}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="total-amount _item_list">
                      <span class="_name__">
                        {localStorage.getItem("language") != "ar" ? (
                          "Total amount"
                        ) : (
                          <Text>المبلغ الإجمالي</Text>
                        )}
                      </span>
                      <span class="amount-value">
                        {this.state.ticketPrice} OMR
                      </span>
                    </div>
                    <div className="booking-button _item_list">
                      <button class="_btn btn-back" onClick={backButtonClicked}>
                        {localStorage.getItem("language") != "ar" ? (
                          "Back"
                        ) : (
                          <Text>عودة</Text>
                        )}
                      </button>
                      {this.state.showNextButton == 1 &&
                        this.state.loading == 0 ? (
                        <button class="_btn btn-next" onClick={this.setSeats}>
                          {localStorage.getItem("language") != "ar" ? (
                            "Next"
                          ) : (
                            <Text>التالي</Text>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="seats-info">
                <div className="seats-display">
                  <div className="seat-type cinema-labels cl-left">
                    <div class="box box-available">
                      <img
                        src={require("../../assets/hollowseat.png").default}
                      />
                      {localStorage.getItem("language") != "ar" ? (
                        "AVAILABLE"
                      ) : (
                        <Text>متوفرة</Text>
                      )}
                    </div>
                    {/* <div class="box box-available">
                      <img src={require("../../assets/delux.png").default} />

                      {localStorage.getItem("language") != "ar" ? (
                        "DELUX"
                      ) : (
                        <Text>ديلوكس</Text>
                      )}
                    </div> */}
                    <div class="box box-unavailable">
                      <img src={require("../../assets/close.png").default} />
                      {localStorage.getItem("language") != "ar" ? (
                        "Restricted"
                      ) : (
                        <Text>مقيد</Text>
                      )}
                    </div>
                    {/* <div class="box box-unavailable">
                      <img
                        src={require("../../assets/filledseat.png").default}
                      />
                      {localStorage.getItem("language") != "ar" ? (
                        "SOLD"
                      ) : (
                        <Text>تم البيع</Text>
                      )}
                    </div> */}
                  </div>
                  <div class="cinema-labels cl-right">
                    <div class="box box-available">
                      <img
                        src={require("../../assets/filledseat.png").default}
                      />
                      {localStorage.getItem("language") != "ar" ? (
                        "SOLD"
                      ) : (
                        <Text>تم البيع</Text>
                      )}
                    </div>
                    <div class="box box-selected">
                      <img
                        src={require("../../assets/selectseat.png").default}
                      />
                      {localStorage.getItem("language") != "ar" ? (
                        "SELECTED"
                      ) : (
                        <Text>المحدد</Text>
                      )}
                    </div>
                  </div>
                </div>
                <div class="scree_watch y">
                  <img src={require("../../assets/screen.png").default} />
                  <p>
                    {localStorage.getItem("language") != "ar" ? (
                      "screen this way"
                    ) : (
                      <Text>الشاشة بهذه الطريقة</Text>
                    )}
                  </p>
                </div>
                <div className="seats-scroll">
                  <div className="seats-preview">{seatLayoutRender}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLogin ? (
          <>
            {/* <Modal show={this.state.showLogin} className="login-modal"> */}
            <Modal isOpen={this.state.showLogin} portalClassName="login-modal">
              <div class="cross">
                <img
                  src={require("../../assets/cross@2x.png").default}
                  alt="img"
                  onClick={() => this.setState({ showLogin: false })}
                />
              </div>
              <GuestLogin />
            </Modal>
          </>
        ) : (
          ""
        )}
        {/* <div class="bottom_sticky">
                    <button class="btn btn_outline btn_text"><i class="fa fa-long-arrow-left" aria-hidden="true">&nbsp;&nbsp;</i>
            Back</button>
                    {(this.state.showNextButton == 1) ?
                        <button onClick={this.setSeats} class="btn btn_blue _float_right btn_text">Next &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </button>
                        : ''} */}
        {/* booking summary */}

        {/* booking summary end */}

        {/* <Footer /> */}
      </>
    );
  }
}

export default SeatLayout;
