import React from "react";
import "./offershome.style.css";

class Offershome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="offershome">
          <img src={require("../../assets/offer.png").default} alt="img" />
        </div>
      </>
    );
  }
}

export default Offershome;
