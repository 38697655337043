import React from "react";
import Text from "react-text";
import * as Constants from "../../Constants/index";
import axios from "axios";
import "./offerdetail.style.css";
import parse from "html-react-parser";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

class offerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offersId: this.props.match.params.offerid,
      offerstype: "",
      offers: [],
      wd: "",
      d: "",
    };
  }

  componentDidMount() {
    let formDataNP = new FormData(); //formdata object
    formDataNP.append("id", this.state.offersId);
    formDataNP.append("av", Constants.av);
    formDataNP.append("pt", Constants.pt);
    formDataNP.append("did", Constants.did);
    axios
      .post(Constants.api + "deals/mdetail", formDataNP, {
        headers: { "Accept-Language": localStorage.getItem("language") },
      })
      .then((resp) => {
        //  casetype array
        if (resp.data.result === "success") {
          this.setState({
            loading: 0,
            wd: resp.data.output.wd,
            d: resp.data.output.d,
          });
          console.log(resp);
        } else {
          console.log(resp.data.output);
          this.setState({
            loading: 0,
          });
        }
      });
  }

  render() {
    console.log(this.state.wd);
    return (
      <>
        <BrowserView>{parse(this.state.wd)};</BrowserView>
        <MobileView>
          <div className="margin-top"></div>
          {parse(this.state.d)};
        </MobileView>
      </>
    );
  }
}

export default offerDetail;
