import React from "react";
import FACTORS_DATA from "./factors.data";
import FactorsPreview from "../factors-preview/FactorsPreview.component";
import "./Factors.style.css";
import Text from "react-text";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import axios from "axios";
class GoldClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { collections } = this.state;
    return (
      <div className="offers-pic">
        <div className="offers-pic-container">
          <div className="offers-wow-img">
            <div className="wow-img">
              {/* <img src={require("../../assets/Gold Class preview .png").default} /> */}
            </div>
            <img src={require("../../assets/gold about final b.png").default} />
            <img src={require("../../assets/gold 2 c.png").default} />
            <img src={require("../../assets/gold 1 d (1).png").default} />
            <img src={require("../../assets/gold E.png").default} />
            <img src={require("../../assets/gold 4F.png").default} />
          </div>
        </div>
      </div>
    );
  }
}
export default GoldClass;
