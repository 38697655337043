import React from "react";
import "./header.style.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import * as Constants from "../../Constants/index";
import TrailerModal from "../trailer/TrailerModal.component";
import Modal from "react-modal";
import Text from "react-text";
import moment from "moment";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      showTrailer: false,
      trailerUrl: "",
    };
  }
  componentDidMount() {
    if (
      localStorage.getItem("language") == "" &&
      !localStorage.getItem("language")
    )
      localStorage.setItem("language", "en");
    let formDataUP = new FormData(); //formdata object
    formDataUP.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataUP.append("av", Constants.av);
    formDataUP.append("pt", Constants.pt);
    formDataUP.append("type", "WOW");

    const bannerRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/bannersnew", formDataUP)
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              let bannerArray = [];
              Object.keys(resp.data.output).forEach(function (key) {
                bannerArray.push(resp.data.output[key]);
              });

              this.setState({
                banners: bannerArray,
                loading: 0,
              });
              console.log("banners");
              console.log(this.state.banners);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    bannerRequest();
  }
  render() {
    console.log(this.state.banners);
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    const banner = this.state.banners.map((item, j) => {
      let linkUrl = "";
      if (item.type == "nowshowing")
        linkUrl =
          "/moviesessions/" +
          item.name.toString().toLowerCase().replaceAll(" ", "-") +
          "/" +
          item.id +
          "?language=All";
      if (item.type == "comingsoon") linkUrl = "/upcomingdetails/" + item.id;
      return (
        <>
          <a href={item.type != "WOW" ? linkUrl : item.url}>
            <img className="d-block w-100" src={item.i} />
          </a>
          {item.type == "WOW" ? (
            <div className="banner-div container">
              <div className="book-now-banner">
                <button className="btn btn-normal">
                  <a href={item.url}>
                    {localStorage.getItem("language") != "ar" ? (
                      " Know More"
                    ) : (
                      <Text>تعرف أكثر</Text>
                    )}
                  </a>
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {item.type == "comingsoon" ? (
            <div className="banner-div container">
              <div className="book-now-banner">
                <h1>{item.name}</h1>
                {item.rt !== undefined ? (
                  <>
                    <p>
                      {localStorage.getItem("language") != "ar" ? (
                        "Releasing On "
                      ) : (
                        <Text>الافراج في </Text>
                      )}
                      {item.rt}
                    </p>
                  </>
                ) : (
                  ""
                )}
                <button className="btn btn-normal">
                  <a href={linkUrl}>
                    {localStorage.getItem("language") != "ar" ? (
                      " View Details"
                    ) : (
                      <Text>تعرف أكثر</Text>
                    )}
                  </a>
                </button>
                {item.url != "" ? (
                  <button className="btn btn-normal btn-ghost">
                    <a
                      onClick={() => {
                        this.setState({
                          showTrailer: true,
                          trailerUrl: item.url,
                        });
                      }}
                    >
                      {localStorage.getItem("language") != "ar" ? (
                        "Watch Trailer"
                      ) : (
                        <Text>مشاهدة اعلان</Text>
                      )}
                    </a>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {item.name.length > 0 ? (
            <>
              {" "}
              {item.type != "WOW" && item.type != "comingsoon" ? (
                <div className="banner-div container">
                  <div className="book-now-banner">
                    <h1>{item.name}</h1>
                    <p>
                      {item.lng} | {item.gn} | {item.cn}{" "}
                    </p>
                    <button className="btn btn-normal">
                      <a href={linkUrl}>
                        {localStorage.getItem("language") != "ar" ? (
                          " Book now"
                        ) : (
                          <Text>احجز الآن</Text>
                        )}
                      </a>
                    </button>

                    {item.url != "" ? (
                      <button className="btn btn-normal btn-ghost">
                        <a
                          onClick={() => {
                            this.setState({
                              showTrailer: true,
                              trailerUrl: item.url,
                            });
                          }}
                        >
                          {localStorage.getItem("language") != "ar" ? (
                            "Watch Trailer"
                          ) : (
                            <Text>مشاهدة اعلان</Text>
                          )}
                        </a>
                      </button>
                    ) : (
                      ""
                      // <div className="banner-div container">
                      //   <div className="book-now-banner">
                      //     <button className="btn btn-normal">
                      //       <a href="/offers">
                      //         {localStorage.getItem("language") != "ar" ? (
                      //           " Know More"
                      //         ) : (
                      //           <Text>تعرف أكثر</Text>
                      //         )}
                      //       </a>
                      //     </button>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      );
    });
    return (
      <>
        <div className="header" id="head-banner">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3500}
            keyBoardControl={true}
            // customTransition="all 0.25"
            transitionDuration={1000}
            containerClass="carousel-container-banner"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
            // itemClass="banner-carousel-item-padding-40-px"
          >
            {banner}
          </Carousel>
        </div>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.trailerUrl} />
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default Header;
