import React from "react";
import "./FactorsPreview.style.css";

const FactorsPreview = ({ title, description, imageUrl, url }) => (
  <div className="factors-preview">
    <a href={url}>
      <div className="factors-img">
        <img src={imageUrl} alt="img" />
      </div>
    </a>
    <a href={url}>
      <h4>{title}</h4>
    </a>
    <p>{description}</p>
  </div>
);

export default FactorsPreview;
