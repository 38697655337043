import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import Loading from "../../Constants/Loading";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import "./food.css";
import foodimage from "../../assets/food_image.jpg";
import GuestLogin from "../login/guestlogin.component";
// import { Modal } from "react-bootstrap";
import Modal from "react-modal";

import Text from "react-text";
import Login from "../login/login.component";

class Food extends Component {
  constructor(props) {
    super(props);
    this.otpvalidator = new SimpleReactValidator();
    this.forgotvalidator = new SimpleReactValidator();
    this.resetvalidator = new SimpleReactValidator();
    this.resetpassvalidator = new SimpleReactValidator();
    this.otploginvalidator = new SimpleReactValidator();
    this.registervalidator = new SimpleReactValidator();
    this.validator = new SimpleReactValidator();
    this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    cinemaname: this.props.match.params.cinemaname,
    moviename: this.props.match.params.moviename,
    categories: [],
    foods: [],
    tckDetailsOutput: {},
    tckDetails: [],
    foodDetails: [],
    selectSeat: [],
    totalPrice: 0,
    foodString: "",
    showLogin: false,
    onlyVeg: false,
  };

  // handleClickActiveTab=(e)=> {
  //     alert(e);
  //     // const newActiveTab = e.target.tab;
  //     // this.setState({
  //     //     activeTab: newActiveTab,
  //     // })
  // }

  componentDidMount() {
    let formDataFood = new FormData(); //formdata object
    formDataFood.append("ccode", localStorage.getItem("ccode")); //append the values with key, value pair
    formDataFood.append("bookingid", localStorage.getItem("bookingid"));
    formDataFood.append("cbookid", "");
    formDataFood.append("transid", localStorage.getItem("transid"));
    formDataFood.append("type", "");
    formDataFood.append("audi", "");
    formDataFood.append("seat", "");
    formDataFood.append("av", Constants.av);
    formDataFood.append("pt", Constants.pt);
    formDataFood.append("did", Constants.did);
    this.getFoodRequest();

    let formDataTckDetails = new FormData(); //formdata object
    formDataTckDetails.append("cinemacode", localStorage.getItem("ccode")); //append the values with key, value pair
    formDataTckDetails.append("transid", localStorage.getItem("transid"));

    formDataTckDetails.append("userid", 0);
    formDataTckDetails.append("av", Constants.av);
    formDataTckDetails.append("pt", Constants.pt);
    formDataTckDetails.append("bookingid", "");
    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(Constants.api + "trans/tckdetails/", formDataTckDetails, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              let tckDetailsArray = [];
              Object.keys(resp.data.output.items).forEach(function (key) {
                tckDetailsArray.push(resp.data.output.items[key]);
              });

              // console.log(resp.data.output.seat);
              this.setState({
                tckDetailsOutput: resp.data.output,
                tckDetails: tckDetailsArray,

                selectSeat: resp.data.output.seatsArr,
              });

              this.setState({
                totalPrice: resp.data.output.totalticketprice / 1000,
              });
            } else {
              //   console.log(resp.data.msg);
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    tckDetailsRequest();
  }

  async getFoodRequest(onlyVeg = false) {
    console.log("testing -------->");
    let formDataFood = new FormData(); //formdata object
    formDataFood.append("ccode", localStorage.getItem("ccode")); //append the values with key, value pair
    formDataFood.append("bookingid", localStorage.getItem("bookingid"));
    formDataFood.append("cbookid", "");
    formDataFood.append("transid", localStorage.getItem("transid"));
    formDataFood.append("type", "");
    formDataFood.append("audi", "");
    formDataFood.append("seat", "");
    formDataFood.append("av", Constants.av);
    formDataFood.append("pt", Constants.pt);
    formDataFood.append("did", Constants.did);
    try {
      // console.log(this.state.moviename);
      //console.log(this.state.mcode);
      // const resp =
      //console.log(this.formDataFood);
      await axios
        .post(Constants.api + "trans/getfoods/", formDataFood)
        .then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output.r);
            this.setState({
              output: resp.data.output,
            });

            let categoryArray = [];
            // Object.keys(resp.data.output.cat).forEach(function (key) {
            //   categoryArray.push(resp.data.output.cat[key]);
            // });

            //  console.log(foodsArray);
            console.log("sss");
            this.setState({
              foods: resp.data.output.r,
              categories: categoryArray,
            });
          } else {
            //console.log(resp.data.msg);
            swal(resp.data.msg);
            this.setState({
              loading: 0,
            });
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
    // getFoodRequest();
  }
  resendOtp = () => {
    try {
      let resendData = new FormData(); //formdata object

      resendData.append("pt", Constants.pt);
      resendData.append("mobile", this.state.mobile);

      // const resp =
      axios
        .post(Constants.api + "/user/new/resendotp", resendData)
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            swal("", "OTP has been resent on your Mobile Number.", "success");
          } else {
            swal(resp.data.msg);
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  setFood = () => {
    try {
      console.log(this.state.moviename);
      console.log(this.state.mcode);
      // const resp =
      let formDataFoodDetails = new FormData();
      formDataFoodDetails.append("av", Constants.av);
      formDataFoodDetails.append("pt", Constants.pt);
      formDataFoodDetails.append("did", Constants.did);
      formDataFoodDetails.append("transid", localStorage.getItem("transid"));
      formDataFoodDetails.append("cinemacode", localStorage.getItem("ccode"));
      formDataFoodDetails.append("foods", localStorage.getItem("foodString"));
      axios
        .post(Constants.api + "trans/setfood", formDataFoodDetails)
        .then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            if (localStorage.getItem("userid") == 0) {
              this.setState({ showLogin: true });
            } else
              window.location =
                "/payment/" +
                localStorage.getItem("ccode") +
                "/" +
                localStorage.getItem("mname");
          } else if (resp.data.result === "dialog") {
            swal("Please choose food");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  skip = () => {
    try {
      if (localStorage.getItem("userid") == 0) {
        this.setState({ showLogin: true });
      } else
        window.location =
          "/payment/" +
          localStorage.getItem("ccode") +
          "/" +
          localStorage.getItem("mname");
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  handleForgotpassSubmit = (event) => {
    event.preventDefault();
    if (this.forgotvalidator.allValid()) {
      let logindata = new FormData(); //logindata object
      logindata.append("pt", Constants.pt);
      logindata.append("av", Constants.av);
      logindata.append("did", Constants.did);
      logindata.append("mobile", this.state.mobile);
      logindata.append("email", "");

      axios
        .post(Constants.api + "/user/forgotpassword", logindata)
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            if (res.data.msg == "ok") {
              swal("Please Enter OTP Received on your Mobile Number.");
              this.setState({ showforgotpass: 0, resetpassshow: 1 });
            }
          } else {
            // this.setState({alert:res.data.msg});
            swal("", res.data.msg, "info");
          }
        });
    } else this.forgotvalidator.showMessages();
    this.forceUpdate();
  };
  handleOtpLoginSubmit = (event) => {
    event.preventDefault();
    if (this.otploginvalidator.allValid()) {
      let logindata = new FormData(); //logindata object
      logindata.append("pt", Constants.pt);
      logindata.append("mobile", this.state.mobile);

      axios
        .post(Constants.api + "/user/new/resendotp", logindata)
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            if (res.data.output.otp_require == "true") {
              this.setState({ showotplogin: 0, otpshow: 1 });
            }
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    } else this.otploginvalidator.showMessages();
    this.forceUpdate();
  };
  handleLoginSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let logindata = new FormData(); //logindata object
      logindata.append("av", Constants.av);
      logindata.append("pt", Constants.pt);
      logindata.append("did", Constants.did);
      logindata.append("mobile", this.state.mobile);
      logindata.append("password", this.state.password);

      axios.post(Constants.api + "/user/new/login", logindata).then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          swal("", "You Have Succesfully Logged In.", "success");
          localStorage.setItem("useremail", res.data.output.em);
          localStorage.setItem("userid", res.data.output.id);
          localStorage.setItem("userphone", res.data.output.ph);
          localStorage.setItem("username", res.data.output.un);

          window.location =
            "/payment/" + localStorage.ccode + "/" + localStorage.mname;
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleRegisterSubmit = (event) => {
    event.preventDefault();

    if (this.registervalidator.allValid()) {
      if (this.state.password != this.state.confirmpassword) {
        swal("", "Passwords do not match.", "info");
      } else {
        let registerdata = new FormData(); //registerdata object
        registerdata.append("av", Constants.av);
        registerdata.append("pt", Constants.pt);
        registerdata.append("did", Constants.did);
        registerdata.append("mobile", this.state.mobile);
        registerdata.append("password", this.state.password);
        registerdata.append("email", this.state.email);
        registerdata.append("name", this.state.name);

        axios
          .post(Constants.api + "/user/new/register", registerdata)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              if (res.data.output.otp_require == "true") {
                swal("Please Enter OTP Received on your Mobile Number.");
                this.setState({ showregister: 0, otpshow: 1 });
              }
            } else {
              // this.setState({alert:res.data.msg});
              swal("", res.data.msg, "info");
            }

            // if (res.data.msg == 'Please enter otp')
            // window.location = '/verifyotp';
          });
      }
    } else this.registervalidator.showMessages();
    this.forceUpdate();
  };

  handleotpSubmit = (event) => {
    event.preventDefault();
    if (this.otpvalidator.allValid()) {
      let otpdata = new FormData(); //otpdata object
      otpdata.append("av", Constants.av);
      otpdata.append("pt", Constants.pt);
      otpdata.append("mobile", this.state.mobile);
      otpdata.append("otp", this.state.otp);
      otpdata.append("name", this.state.name);

      axios.post(Constants.api + "/user/verifyotp", otpdata).then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          localStorage.setItem("useremail", res.data.output.em);
          localStorage.setItem("userid", res.data.output.id);
          localStorage.setItem("userphone", res.data.output.ph);
          localStorage.setItem("username", res.data.output.un);
          swal("", "You have logged in!", "success");

          window.location =
            "/payment/" + localStorage.ccode + "/" + localStorage.mname;
        } else {
          // this.setState({alert:res.data.msg});
          swal("", res.data.msg, "info");
        }

        // if (res.data.msg == 'Please enter otp')
        // window.location = '/verifyotp';
      });
    } else this.otpvalidator.showMessages();
    this.forceUpdate();
  };
  handleresetpassSubmit = (event) => {
    event.preventDefault();
    if (this.resetpassvalidator.allValid()) {
      if (this.state.password != this.state.confirmpassword) {
        swal("", "Passwords do not match.", "info");
      } else {
        let resetdata = new FormData(); // resetdata object
        resetdata.append("av", Constants.av);
        resetdata.append("pt", Constants.pt);
        resetdata.append("did", Constants.did);
        resetdata.append("mobile", this.state.mobile);
        resetdata.append("password", this.state.password);
        resetdata.append("authcode", this.state.otp);
        resetdata.append("email", "");

        axios
          .post(Constants.api + "/user/resetpassword", resetdata)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              localStorage.setItem("useremail", res.data.output.em);
              localStorage.setItem("userid", res.data.output.id);
              localStorage.setItem("userphone", res.data.output.ph);
              localStorage.setItem("username", res.data.output.un);
              swal("", "You have logged in!", "success");

              window.location =
                "/payment/" + localStorage.ccode + "/" + localStorage.mname;
            } else {
              // this.setState({alert:res.data.msg});
              swal("", res.data.msg, "info");
            }

            // if (res.data.msg == 'Please enter otp')
            // window.location = '/verifyotp';
          });
      }
    } else this.resetpassvalidator.showMessages();
    this.forceUpdate();
  };
  searchhandleChange = (event) => {
    this.setState({ search: event.target.value });
  };

  otphandleChange = (event) => {
    this.setState({ otp: event.target.value });
  };
  namehandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  mobilehandleChange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  passwordhandleChange = (event) => {
    this.setState({ password: event.target.value });
  };
  confirmpasswordhandleChange = (event) => {
    this.setState({ confirmpassword: event.target.value });
  };
  changeFoodType = (event) => {
    console.log(event.target.checked);
    if (event.target.checked == true) {
      this.getFoodRequest(true);
      this.setState({ onlyVeg: true });
    } else {
      this.setState({ onlyVeg: false });
      this.getFoodRequest();
    }
  };
  cityChange = (event) => {
    this.setState({ selectedcity: event.target.value });
    localStorage.setItem("selectedcity", event.target.value);
    //   alert(localStorage.getItem('selectedcity'));
    window.location = "/";
  };
  registerClick = () => {
    this.setState({ showregister: 1, showlogin: 0 });
  };
  forgotpassClick = () => {
    this.setState({ showforgotpass: 1, showlogin: 0 });
  };
  loginClick = () => {
    this.setState({ showlogin: 1 });
  };
  loginotpClick = () => {
    this.setState({ showlogin: 0, showotplogin: 1 });
  };

  nameClick = () => {
    if (this.state.showMenu == 1) this.setState({ showMenu: 0 });
    else this.setState({ showMenu: 1 });
  };

  loginHide = () => {
    this.setState({ showlogin: 0 });
  };
  registerHide = () => {
    this.setState({ showregister: 0 });
  };
  forgotpassHide = () => {
    this.setState({ showforgotpass: 0 });
  };
  resetpassHide = () => {
    this.setState({ resetpassshow: 0 });
  };
  otploginHide = () => {
    this.setState({ showotplogin: 0 });
  };

  render() {
    const activeClass = this.state.activeTab;
    if (this.state.loading == 1) {
      return (
        <>
          <Loading />
        </>
      );
    }

    let foodsArray = this.state.foods;
    const categoryRender = this.state.categories
      .filter((category) => category != "")
      .map(function (category, i) {
        // let actv =activeClass == i ? 'active' : '';
        return (
          <>
            <React.Fragment key={i}>
              <Tab>
                <button class="btn btn-gray">{category}</button>
              </Tab>
            </React.Fragment>
          </>
        );
      });
    const selectSeatRender = this.state.selectSeat.map(function (item, i) {
      return (
        <>
          <React.Fragment key={i}>
            <li class="square-seat">{item}</li>
          </React.Fragment>
        </>
      );
    });
    const foodRenderAll = this.state.foods.map(function (food, i) {
      //console.log(food);
      return (
        <>
          <React.Fragment key={i}>
            {/* <h2>Any content </h2> */}

            <div class="foods_box">
              <div class="foods_img_box">
                <img
                  class="img-responsive"
                  src={require("../../assets/Logo.png")}
                  alt="img"
                />
              </div>
              <div class="foods_txt_box">
                <div class="_cont_box " hidden={food.veg != true}>
                  <img
                    class="img-responsive"
                    src={require("../../assets/veg_icon.svg")}
                    alt="img"
                  />
                </div>

                <div class="_cont_box">
                  <h4 class="_food_name">{food.h}</h4>
                </div>
                <div>
                  <div class="_cont_box">
                    {/*  */}

                    <button
                      className={`btn btn_blue ${"add" + food.id}`}
                      onClick={() => addFood(food, 1)}
                    >
                      Add{" "}
                    </button>
                    <div className={`hide ${"remove" + food.id}`}>
                      <i
                        class="fa fa-minus _circle_round"
                        aria-hidden="true"
                        onClick={() => addFood(food, 1, "dec")}
                      ></i>
                      <span className={"qty" + food.id}></span>
                      <i
                        class="fa fa-plus _circle_round "
                        aria-hidden="true"
                        onClick={() => addFood(food, 1, "inc")}
                      ></i>
                    </div>
                  </div>
                  <div class="_cont_box _float_right ">
                    <div class="p15px_">
                      <i class="fa fa-inr __price_food"></i>
                      <del class="__price_food"> {food.dp}</del>
                    </div>
                    <div class="p15px_">
                      <i class="fa fa-inr __price_food"></i>
                      <span class="__price_food"> {food.dp}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </>
      );
    });
    let containsObject = (obj, list) => {
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    };
    const addFood = (food, qty, type = "") => {
      // if (localStorage.getItem("userid") == 0) {
      //   this.setState({ showLogin: true });
      // }
      // else {
      let food_string = "";
      //   REGULAR POPCORN CHEESE (NEW)|100263|1|27000|HO00100263#FRENCH FRIES (NEW)|101328|1|18000|HO00101328

      let foodDetails = this.state.foodDetails;
      if (foodDetails.length == 0 && type != "inc") return false;
      if (
        foodDetails.filter((e) => e.h === food.h).length == 0 &&
        type != "inc"
      )
        return false;

      var before_price = 0;
      foodDetails.map((food) => (before_price += food.dp * food.qty));
      //console.log(foodDetails);
      var index = foodDetails.findIndex((obj) => obj.id == food.id);

      if (index >= 0) {
        if (type == "inc") {
          if (foodDetails[index].qty == 10) {
            swal("", "Max qtuantity 10 allowed per item", "info");
            return false;
          }
          foodDetails[index].qty = foodDetails[index].qty + 1;
          console.log(foodDetails[index].qty);
        } else {
          if (foodDetails[index]["qty"] > 1) {
            foodDetails[index]["qty"] = foodDetails[index]["qty"] - 1;
          } else {
            //foodDetails.splice(food.id, 1);
            foodDetails.splice(index, 1);
          }
        }
        this.setState({ foodDetails: foodDetails });
      } else {
        var fd = {
          id: food.id,
          dp: food.dp,
          ho: food.ho,
          veg: food.veg,
          h: food.h,
          qty: 1,
        };
        foodDetails.push(fd);

        this.setState({ foodDetails: foodDetails });
      }
      console.log("Size of Food Details ----->" + foodDetails.length);
      console.log(foodDetails);

      let total_food_price = 0.0;
      foodDetails.map((food) => {
        total_food_price += food.dp * food.qty;
        food_string += food_string != "" ? "#" : "";
        food_string +=
          food.h +
          "|" +
          food.id +
          "|" +
          food.qty +
          "|" +
          food.dp +
          "|" +
          food.ho;
      });
      this.setState({ foodString: food_string });
      localStorage.setItem('foodString', food_string);
      let total_price = Number(
        parseFloat(this.state.totalPrice) +
        parseFloat(total_food_price) -
        parseFloat(before_price)
      ).toFixed(3);
      this.setState({ totalPrice: total_price });
      // End
      // }
      //console.log(this.setState.foodDetails);
    };

    const foodRender2 = this.state.categories.map(function (category, i) {
      return (
        <>
          <React.Fragment key={i}>
            <TabPanel>
              {foodsArray.map((food, j) => {
                if (food.ct == category) {
                  return (
                    <div class="foods_box">
                      <div class="foods_img_box">
                        {/* <img class="img-responsive" src={require('../assets/food_image.jpg').default} alt="img" /> */}
                      </div>
                      <div class="foods_txt_box">
                        <div class="_cont_box ">
                          {/* <img class="img-responsive" src={require('../assets/veg_icon.svg').default} alt="img" /> */}
                        </div>
                        <div class="_cont_box">
                          <h4 class="_food_name">{food.h}</h4>
                        </div>
                        <div>
                          <div class="_cont_box">
                            {/* <i class="fa fa-plus _circle_round food-count-hide" aria-hidden="true"></i>
                    <span class="_increase_number">ADD</span>
                    <i class="fa fa-minus _circle_round food-count-hide" aria-hidden="true"></i> */}

                            <button
                              className={`btn btn_blue ${"add" + food.id}`}
                              onClick={() => addFood(food, 1)}
                            >
                              Add{" "}
                            </button>
                            <div className={`hide ${"remove" + food.id}`}>
                              <i
                                class="fa fa-minus _circle_round"
                                aria-hidden="true"
                                onClick={() => addFood(food, 1, "dec")}
                              ></i>
                              <span className={"qty" + food.id}></span>
                              <i
                                class="fa fa-plus _circle_round "
                                aria-hidden="true"
                                onClick={() => addFood(food, 1, "inc")}
                              ></i>
                            </div>
                          </div>
                          <div class="_cont_box _float_right ">
                            <div class="p15px_">
                              <i class="fa fa-inr __price_food"></i>
                              <del class="__price_food"> {food.dp}</del>
                            </div>
                            <div class="p15px_">
                              <i class="fa fa-inr __price_food"></i>
                              <span class="__price_food"> {food.dp}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </TabPanel>
          </React.Fragment>
        </>
      );
    });

    const tckDetailsRender = this.state.tckDetails.map(function (tck, i) {
      if (tck.it.length > 0) {
        return (
          <>
            <React.Fragment key={i}>
              {tck.it.map((det, j) => {
                return (
                  <li>
                    <div class="_food_item_name">{det.n}</div>
                    <div class="_food_item_name">
                      {/* {det.n.startsWith("GST Number") ? "" : "OMR"}  */}
                      {det.v}
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          </>
        );
      } else
        return (
          <li>
            <div class="_food_item_name">{tck.n}</div>
            <div class="_food_item_name">
              {/* {det.n.startsWith("GST Number") ? "" : "OMR"}  */}
              {tck.v}
            </div>
          </li>
        );
    });

    const foodDetailsRender = this.state.foodDetails.map(function (food, i) {
      return (
        <>
          <React.Fragment key={i}>
            {
              <li>
                <div class="_food_item_name">
                  {food.qty} x {food.h}
                </div>
                <div class="_food_item_name">
                  {Number(food.qty * food.dp).toFixed(3)} OMR{" "}
                </div>
              </li>
            }
          </React.Fragment>
        </>
      );
    });

    const backButtonClicked = () => {
      this.props.history.goBack();
    };

    //const index = foodDetails.findIndex(obj => obj.id == food.id);
    const food_details = this.state.foodDetails;
    return (
      <>
        <div className="container food-section">
          <div className="row">
            {/* booking summary  */}
            <div class="movie-info">
              <div class="booking_summary _35px">
                <h3 class="_summary_txt">
                  {localStorage.getItem("language") != "ar" ? (
                    "Booking Summary"
                  ) : (
                    <Text>ملخص الكتاب</Text>
                  )}
                </h3>
                {/* <img class="img-responsive" src={this.state.tckDetailsOutput.imh} alt="img" /> */}
                <div class="_summary_details _border__">
                  <div class="_item_list">
                    <p class="_name__">
                      {localStorage.getItem("language") != "ar" ? (
                        "Movie"
                      ) : (
                        <Text>فيلم</Text>
                      )}
                    </p>
                    <h4 class="movie_name__">
                      {this.state.tckDetailsOutput.moviename}
                      {" | "}
                      {this.state.tckDetailsOutput.mlanguage}
                      {" | "}
                      {this.state.tckDetailsOutput.mcensor}
                    </h4>
                  </div>
                  <div class="_item_list">
                    <p class="_name__">
                      {localStorage.getItem("language") != "ar" ? (
                        "Location"
                      ) : (
                        <Text>موقع</Text>
                      )}
                    </p>
                    <h4 class="movie_name__">
                      {this.state.tckDetailsOutput.cinemaname}
                    </h4>
                  </div>

                  <div class="_item_list">
                    <p class="_name__">
                      {localStorage.getItem("language") != "ar" ? (
                        "Show Time"
                      ) : (
                        <Text>موعد العرض</Text>
                      )}
                    </p>
                    <h4 class="movie_name__">
                      {" "}
                      {this.state.tckDetailsOutput.showtime}
                    </h4>
                  </div>

                  {/* <div class="_item_list">
                    <p class="_name__">Time</p>
                    <h4 class="movie_name__">
                      {this.state.tckDetailsOutput.t}
                    </h4>
                  </div> */}
                </div>
              </div>
              <div class="ticket_prints _35px">
                <span class="_name__">
                  {localStorage.getItem("language") != "ar" ? (
                    "SEATS"
                  ) : (
                    <Text>مقاعد</Text>
                  )}
                </span>
                <span class="_info__ _float_right">
                  {/* <img src={require('../assets/information.png').default} /> */}
                </span>
                <div class="_print_tickets">
                  <ul>{selectSeatRender}</ul>
                </div>
              </div>
              <div class="foods_details_with_coupon">
                <ul class="food_list_selected_">
                  {tckDetailsRender}
                  {foodDetailsRender}
                </ul>
              </div>
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              ></div>
              <div class="total-amount _item_list">
                <span class="_name__">
                  {localStorage.getItem("language") != "ar" ? (
                    "Total amount"
                  ) : (
                    <Text>المبلغ الإجمالي</Text>
                  )}
                </span>
                <span class="amount-value">{this.state.totalPrice} OMR</span>
              </div>
              <div class="booking-button _item_list">
                <button class="_btn btn-back" onClick={backButtonClicked}>
                  {localStorage.getItem("language") != "ar" ? (
                    "Back"
                  ) : (
                    <Text>عودة</Text>
                  )}
                </button>
                {this.state.foodDetails.length > 0 ? (
                  <button class="_btn btn-next" onClick={this.setFood}>
                    {localStorage.getItem("language") != "ar" ? (
                      "Next"
                    ) : (
                      <Text>التالي</Text>
                    )}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* booking summary end */}

            {/* <div class="bottom_sticky">
                    <button onClick={backButtonClicked} class="btn btn_outline btn_text"><i class="fa fa-long-arrow-left" aria-hidden="true">&nbsp;&nbsp;</i>
Back</button>
                    {this.state.foodDetails.length > 0 ? (
                        <button onClick={this.setFood} class="btn btn_blue _float_right btn_text">Next &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </button>) : ('')
                    }
            
                </div> */}
            {/* <Header /> */}

            <div class="showtimes foodcontainer">
              <div class="row food-heading">
                <div class="heading-content">
                  <h3>
                    {localStorage.getItem("language") != "ar" ? (
                      "Food & Beverages"
                    ) : (
                      <Text>مأكولات ومشروبات</Text>
                    )}
                  </h3>
                  {this.state.foodDetails.length == 0 ? (
                    <button class="btn btn-ghost btn-skip" onClick={this.skip}>
                      {localStorage.getItem("language") != "ar" ? (
                        "SKIP"
                      ) : (
                        <Text>يتخطى</Text>
                      )}
                    </button>
                  ) : (
                    "")}
                  {this.state.foodDetails.length > 0 ? (
                    <div class="booking-button _item_list " style={{ width: "50%" }}>


                      <>
                        <button class="_btn btn-ghost btn" onClick={this.skip}>
                          {localStorage.getItem("language") != "ar" ? (
                            "Skip"
                          ) : (
                            <Text>عودة</Text>
                          )}
                        </button>
                        <button class="_btn btn-next btn" onClick={this.setFood}>
                          {localStorage.getItem("language") != "ar" ? (
                            "Next"
                          ) : (
                            <Text>التالي</Text>
                          )}
                        </button>
                      </>


                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row food-type">
                <div class="col-sm-12 col-xs-12">
                  <Tabs>
                    <div class="tab-content _50px">
                      <TabPanel>
                        <div className="food-preview-collection">
                          {this.state.foods.map((food, i) => (
                            <div class="food-preview">
                              <div class="food-img">
                                <img
                                  src={food.i.length > 0 ? food.i : foodimage}
                                  alt="img"
                                />
                                <p>{food.h}</p>
                              </div>
                              {/* <p class="order-info">
                                                    (1 small coke 350ml + 1 large nachos 80gm)
                                                </p> */}
                              <div class="row">
                                <p class="total-rate">{food.dp} OMR </p>
                                <div class="food-counter-btn">
                                  <i
                                    class="fa fa-plus _circle_round food-count-hide"
                                    onClick={() => addFood(food, 1, "inc")}
                                    aria-hidden="true"
                                  ></i>
                                  <p class="food-count">
                                    {food_details[
                                      food_details.findIndex(
                                        (obj) => obj.id == food.id
                                      )
                                    ] !== undefined
                                      ? food_details[
                                        food_details.findIndex(
                                          (obj) => obj.id == food.id
                                        )
                                      ].qty
                                      : 0}
                                  </p>
                                  <i
                                    class="fa fa-minus _circle_round food-count-hide"
                                    onClick={() => addFood(food, 1, "dec")}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>

                      {this.state.categories.map((category, i) => (
                        <TabPanel>
                          {foodsArray
                            .filter((food) => food.ct == category)
                            .map((food, j) => (
                              <div class="food-preview">
                                <div class="food-img">
                                  <img
                                    src={food.i.length > 0 ? food.i : foodimage}
                                    alt="img"
                                  />
                                  <p>{food.h}</p>
                                </div>

                                <div class="row">
                                  <p class="total-rate">{food.op} </p>
                                  <div class="food-counter-btn">
                                    <i
                                      class="fa fa-plus _circle_round food-count-hide"
                                      aria-hidden="true"
                                    ></i>
                                    <p class="food-count"> 1 </p>
                                    <i
                                      class="fa fa-minus _circle_round food-count-hide"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>

                              //                     <div class="foods_box">
                              //                         <div class="foods_img_box">
                              //                             <img class="img-responsive" src={(food.iw!= null)?food.iw:"../../assets/food_image.jpg"} alt="img" />
                              //                         </div>
                              //                         <div class="foods_txt_box">
                              //                             <div class="_cont_box ">
                              //                                 <img class="img-responsive" src={require('../../assets/veg_icon.svg').default} alt="img" />
                              //                             </div>
                              //                             <div class="_cont_box">
                              //                                 <h4 class="_food_name">{food.h}</h4>
                              //                             </div>
                              //                             <div>
                              //                                 <div class="_cont_box">
                              //                                     {/* <i class="fa fa-plus _circle_round food-count-hide" aria-hidden="true"></i>
                              // <span class="_increase_number">ADD</span>
                              // <i class="fa fa-minus _circle_round food-count-hide" aria-hidden="true"></i> */}

                              //                                     {food_details.findIndex(obj => obj.id == food.id) >= 0 ? (
                              //                                         <div className={`${'remove' + food.id}`}>
                              //                                             <i class="fa fa-minus _circle_round" aria-hidden="true" onClick={() => addFood(food, 1, 'dec')}></i>
                              //                                             <span className={'qty' + food.id}>{food_details[food_details.findIndex(obj => obj.id == food.id)].qty}</span>
                              //                                             <i class="fa fa-plus _circle_round " aria-hidden="true" onClick={() => addFood(food, 1, 'inc')}></i>
                              //                                         </div>
                              //                                     ) : (
                              //                                             <button className={`btn btn_blue ${'add' + food.id}`} onClick={() => addFood(food, 1)}  >Add </button>
                              //                                         )
                              //                                     }
                              //                                 </div>
                              //                                 <div class="_cont_box _float_right ">
                              //                                     <div class="p15px_">
                              //                                         {(food.op != 0) ? <><i class="fa fa-inr __price_food"></i>
                              //                                         <del class="__price_food"> {food.op / 100}</del></> : ''}
                              //                                     </div>
                              //                                     <div class="p15px_">
                              //                                         <i class="fa fa-inr __price_food"></i>
                              //                                         <span class="__price_food"> {food.dp / 100}</span>
                              //                                     </div>

                              //                                 </div>

                              //                             </div>
                              //                         </div>
                              //                     </div>
                            ))}
                        </TabPanel>
                      ))}
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLogin ? (
          <>
            {/* <Modal show={this.state.showLogin} className="login-modal"> */}
            <Modal isOpen={this.state.showLogin} portalClassName="login-modal">
              <div class="cross">
                <img
                  src={require("../../assets/cross@2x.png").default}
                  alt="img"
                  onClick={() => this.setState({ showLogin: false })}
                />
              </div>
              <Login />
            </Modal>
          </>
        ) : (
          ""
        )}
        {/* <Footer /> */}
      </>
    );
  }
}

export default Food;
