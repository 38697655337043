import React from "react";
import "./comingsoon.style.css";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="container">
          <div className="comingsoon-container">
            {/* <h1>Error</h1> */}
            <img src={require("../../assets/best-404-pages.png").default} />
            <div className="error-contain">
              The link is broken or the page has been moved.
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ErrorPage;
