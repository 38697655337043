import React from "react";
import "./MovieGenre.style.css";
import { withRouter, Link } from "react-router-dom";
import play from "../../assets/play.png";
import TrailerModal from "../trailer/TrailerModal.component";
import Modal from "react-modal";
import Text from "react-text";

const MovieGenre = ({ genre, history, linkUrl, match, trailer }) => (
  <>
    {" "}
    <div className="movie-genre">
      <p>{genre}</p>

      <div className="book">
        <a href={linkUrl} className="club book-now">
          {localStorage.getItem("language") != "ar" ? (
            "BOOK NOW"
          ) : (
            <Text>احجز الآن</Text>
          )}
        </a>
        <a href="#">
          <img className="icon" src={play} alt="img" />
        </a>
      </div>
      <div className="trailer-btn">
        {trailer != "" ? (
          <button className="club book-now">
            <a>Watch Trailer</a>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
    {this.state.showTrailer ? (
      <>
        <Modal
          isOpen={this.state.showTrailer}
          contentLabel="Example Modal"
          portalClassName="trai-modal"
        >
          {" "}
          <button
            className="trailer-cross"
            onClick={() => this.setState({ showTrailer: false })}
          >
            <i class="fas fa-times"></i>
          </button>
          <TrailerModal trailerUrl={this.state.trailerUrl} />
        </Modal>
      </>
    ) : (
      ""
    )}
  </>
);
export default withRouter(MovieGenre);
