import React from "react";
import Modal from "react-modal";

class TrailerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerModal: true,
      trailerUrl: this.props.trailerUrl,
    };
  }

  render() {
    return (
      // <Modal
      //   isOpen={this.state.trailerModal}
      //   contentLabel="Example Modal"
      //   portalClassName="trai-modal"
      // >
      // {/* <h2>{this.state.facultyModalName}</h2> */}

      <div className="video-player">
        <iframe
          src={`https://www.youtube.com/embed/${this.state.trailerUrl}`}
          title="YouTube video player"
          frameborder="0"
          width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      // </Modal>
    );
  }
}

export default TrailerModal;
