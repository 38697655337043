import React from "react";
import Carousel from "react-multi-carousel";
import MoviePreview from "../movie-preview/MoviePreview.component";
import UPCOMINGMOVIE_DATA from "./upcomingmovies.data.js";
import "react-multi-carousel/lib/styles.css";
import "./UpcomingMovies.style.css";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Text from "react-text";
import moment from "moment";
import play from "../../assets/play.png";
import TrailerModal from "../trailer/TrailerModal.component";
import Modal from "react-modal";
import { FaLongArrowAltRight } from "react-icons/fa";

class UpcomingMovies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upcoming: [],
      count: 10,
      collections: UPCOMINGMOVIE_DATA,
      loading: 1,
      showTrailer: false,
      trailerUrl: "",
      viewAll: true,
      upcomingUrl: "",
      language: this.props.language ? this.props.language : "",
    };
  }
  componentDidMount() {
    let formDataUP = new FormData(); //formdata object
    formDataUP.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataUP.append("av", Constants.av);
    formDataUP.append("pt", Constants.pt);
    formDataUP.append("did", Constants.did);
    formDataUP.append("userid", 0);
    formDataUP.append("lang", "ALL");

    const upcomingRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/comingsoon", formDataUP, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let upcomingArray = [];
              Object.keys(resp.data.output).forEach(function (key) {
                upcomingArray.push(resp.data.output[key]);
              });

              this.setState({
                upcoming: upcomingArray,
              });
              console.log("comingsoon");
              console.log(this.state.upcoming);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    upcomingRequest();
  }

  render() {
    let count = 0;
    let language = this.state.language;
    const MovieGenre = ({ genre, trailer, release }) => (
      <>
        {" "}
        {/* <div className="movie-genre">
          <p>{genre}</p> */}
        <div className="up-genre-contain">
          {trailer != "" ? (
            <a
              onClick={() => {
                this.setState({ showTrailer: true, trailerUrl: trailer });
              }}
            >
              {/* <img className="icon" src={play} alt="img" /> */}
              <p className="up-genre">{genre}</p>
              <button className="club">
                {localStorage.getItem("language") != "ar" ? (
                  "Show trailer"
                ) : (
                  <Text>عرض مقطورة</Text>
                )}
              </button>
            </a>
          ) : (
            ""
          )}
        </div>
        {/* </div> */}
      </>
    );
    let c = this.state.count;

    const upcoming = this.state.upcoming
      .filter((item, idx) => idx < c)
      .map(function (item, i) {
        const otherCollectionProps = {
          id: i,
          title: item.n,
          language: item.lng,
          genre: item.genre,
          director: item.mdirector,
          writers: item.writer,
          starring: item.martists,
          details: item.mlength,
          imageUrl: item.miv,
          //  imageUrl: "NTTD.jpg",
          slug: "MIB",
          linkUrl: "",
          releasing: item.releaseDate,
          trailer: item.t,
        };
        count = count + 1;
        let upcomingUrl = `/upcomingdetails/${item.id}`;
        let lng = item.lng;
        if (
          language != "" &&
          lng != undefined &&
          language.toLowerCase().includes(lng.toLowerCase()) == true
        ) {
          count = count + 1;
          if (count <= c) {
            return (
              <>
                <div className="release">
                  <div className="img-showing">
                    <a href={upcomingUrl}>
                      <img src={item.miv} />
                    </a>

                    <MovieGenre
                      genre={item.genres}
                      trailer={item.t}
                      release={item.releaseDate}
                    />
                  </div>
                  <h4>{item.n}</h4>
                  {item.releaseDate !== undefined ? (
                    <>
                      <p className="releasing">
                        <span className="release-on">
                          {localStorage.getItem("language") != "ar" ? (
                            "Releasing On"
                          ) : (
                            <Text>الافراج في</Text>
                          )}
                        </span>{" "}
                        <br />
                        <span className="release-date">
                          {moment(item.releaseDate).format("dd MMMM YYYY")}
                        </span>
                      </p>
                      {/* <a href={upcomingUrl} className="upmore-info">
                    {localStorage.getItem("language") != "ar" ? (
                      "More Info"
                    ) : (
                      <Text>مزيد من المعلومات</Text>
                    )}
                    &nbsp;
                    <FaLongArrowAltRight />
                  </a> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          }
        }
        if (language == "") {
          return (
            <>
              <div className="release">
                <div className="img-showing">
                  <a href={upcomingUrl}>
                    <img src={item.miv} />
                  </a>

                  <MovieGenre
                    genre={item.genres}
                    trailer={item.t}
                    release={item.releaseDate}
                  />
                </div>
                <h4>{item.n}</h4>
                {item.releaseDate !== undefined ? (
                  <>
                    <p className="releasing">
                      <span className="release-on">
                        {localStorage.getItem("language") != "ar" ? (
                          "Releasing On"
                        ) : (
                          <Text>الافراج في</Text>
                        )}
                      </span>{" "}
                      <br />
                      <span className="release-date">
                        {moment(item.releaseDate).format("DD MMMM YYYY")}
                      </span>
                    </p>
                    {/* <a href={upcomingUrl} className="upmore-info">
                      {localStorage.getItem("language") != "ar" ? (
                        "More Info"
                      ) : (
                        <Text>مزيد من المعلومات</Text>
                      )}
                      &nbsp;
                      <FaLongArrowAltRight />
                    </a> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
      });
    const upcoming2 = this.state.upcoming.map(function (item, i) {
      const otherCollectionProps = {
        id: i,
        title: item.n,
        language: item.lng,
        genre: item.genre,
        director: item.mdirector,
        writers: item.writer,
        starring: item.martists,
        details: item.mlength,
        imageUrl: item.miv,
        //  imageUrl: "NTTD.jpg",
        slug: "MIB",
        linkUrl: "",
        releasing: item.releaseDate,
        trailer: item.t,
      };
      count = count + 1;
      let upcomingUrl = `/upcomingdetails/${item.id}`;
      return (
        <>
          <div className="release">
            <div className="img-showing">
              <a href={upcomingUrl}>
                <img src={item.miv} />
              </a>

              <MovieGenre
                genre={item.genres}
                trailer={item.t}
                release={item.releaseDate}
              />
            </div>
            <h4>{item.n}</h4>
            {item.releaseDate !== undefined ? (
              <>
                <p className="releasing">
                  <span className="release-on">
                    {localStorage.getItem("language") != "ar" ? (
                      "Releasing On"
                    ) : (
                      <Text>الافراج في</Text>
                    )}
                  </span>{" "}
                  <br />
                  <span className="release-date">
                    {moment(item.releaseDate).format("d MMMM YYYY")}
                  </span>
                </p>
                {/* <a href={upcomingUrl} className="upmore-info">
                  More Info <FaLongArrowAltRight />
                </a> */}
              </>
            ) : (
              ""
            )}
          </div>
        </>
      );
    });
    const { collections } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const {
        carouselState: { currentSlide },
      } = rest;
      return (
        <div className="carousel-button-group">
          <button
            className={currentSlide === 0 ? "disable" : "left-btn-slide"}
            onClick={() => previous()}
          >
            {" "}
            &#8592;{" "}
          </button>
          <button className="right-btn-slide" onClick={() => next()}>
            {" "}
            &#8594;{" "}
          </button>
        </div>
      );
    };

    return (
      <>
        <div className="upcoming-movies upcom-nowhowing">
          {/* <h3>
          {localStorage.getItem("language") != "ar" ? (
            "Upcoming Movies"
          ) : (
            <Text>الأفلام القادمة</Text>
          )}
        </h3> */}

          {/* <div className="show-upcoming"> */}
          {/* <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            > */}

          <div className="show-upcoming">{upcoming}</div>
          {this.state.upcoming.length > 0 && this.state.language == "" ? (
            this.state.upcoming.length > 10 && this.state.viewAll ? (
              <div
                className="row view-button"
                onClick={() =>
                  this.setState({
                    count: 100,
                    viewAll: false,
                    language: "",
                  })
                }
              >
                <button className="btn btn-ghost view-btn">
                  <a className="pointer-cursor" href="#now-show">
                    {localStorage.getItem("language") != "ar" ? (
                      "VIEW ALL"
                    ) : (
                      <Text>عرض الكل</Text>
                    )}

                    <span className="icons-arr">
                      <FaLongArrowAltRight />
                    </span>
                  </a>
                </button>
              </div>
            ) : (
              <div
                className="row view-button"
                onClick={() => this.setState({ count: 10, viewAll: true })}
              >
                <button className="btn btn-ghost view-btn">
                  <a className="pointer-cusror">
                    {localStorage.getItem("language") != "ar" ? (
                      "VIEW LESS"
                    ) : (
                      <Text>عرض أقل</Text>
                    )}
                    <span className="icons-arr">
                      <FaLongArrowAltRight />
                    </span>
                  </a>
                </button>
              </div>
            )
          ) : (
            ""
          )}
          {/* </Carousel> */}
          {/* </div> */}
        </div>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.trailerUrl} />
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default UpcomingMovies;
