import React from "react";
import './comingsoon.style.css';

class ComingSoon extends React.Component{
    constructor(props){
        super(props);
        this.state={
    
        };
    }
    render(){
        return(
            <>
            <div className="container">
                <div className="comingsoon-container">
                    <h1>Coming Soon with Great Offers...</h1>
                </div>
            </div>
            </>
        );
    }
}

export default ComingSoon;