import React from "react";
import Homepage from "./pages/homepage/homepage.pages";
import Booking from "./pages/booking/Booking.pages";
import { Route, Switch } from "react-router-dom";
import "./App.css";

import Navbar from "./components/navbar/navbar.component";
import MovieSession from "./components/moviesessions/MovieSession";
import SeatLayout from "./components/seat-layout/SeatLayout.component";
import BookingSuccess from "./components/bookingsuccess/BookingSuccess.component";
import Loyalty from "./components/loyalty/Loyalty.component";
import AboutUs from "./components/about/about.component";
import Food from "./components/food/food";
import Location from "./components/locations/location";

import Payment from "./components/payment/payment.component";
import Tnc from "./components/about/tnc.component";
import AppTnc from "./components/about/apptnc.component";
import AppFaq from "./components/about/appfaq.component";
import AppAboutUs from "./components/about/appabout.component";
import Faq from "./components/about/faq.component";
import Contact from "./components/contact-us/contact.component";
import swal from "sweetalert";
import Offers from "./components/offers/Offers.component";
import Careers from "./components/contact-us/careers.component";
import GoldClass from "./components/factors/GoldClass.component";
import Comfy from "./components/factors/Comfy.component";

import ComingSoon from "./components/comingsoon/comingsoon.component";
import UpcomingDetails from "./components/upcomingdetails/UpcomingDetails.component";
import WowXL from "./components/factors/WowXL.component";
import Kids from "./components/factors/Kids.component";
import ErrorPage from "./components/comingsoon/ErrorPage.component";
import offerDetail from "./components/offerdetails/offerdetail.component";
import Appwowclub from "./components/about/appwowclub.component";
import AppPrivacy from "./components/about/appprivacy.component";
function App() {
  return navigator.onLine ? (
    <div>
      {window.location.href.indexOf("/app") == -1 ? <Navbar /> : ""}
      <Switch>
        <Route exact path="/" component={Homepage} />
        {/* <Route path='/booking/:title' component={Booking} /> */}
        <Route
          exact
          path="/moviesessions/:moviename/:amcode"
          component={MovieSession}
        />
        <Route
          exact
          path="/seatlayout/:cinemaname/:moviename"
          component={SeatLayout}
        />
        <Route exact path="/food/:cinemaname" component={Food} />
        <Route exact path="/booking" component={BookingSuccess} />
        <Route exact path="/loyalty" component={Loyalty} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/terms-and-conditions" component={Tnc} />
        <Route exact path="/privacy-policy" component={Tnc} />
        <Route exact path="/locations" component={Location} />

        <Route exact path="/faq" component={Faq} />
        <Route exact path="/app/tnc" component={AppPrivacy} />
        <Route exact path="/app/privacy" component={AppTnc} />

        <Route exact path="/app/faq" component={AppFaq} />
        <Route exact path="/app/about-us" component={AppAboutUs} />
        <Route exact path="/error-404" component={ErrorPage} />

        <Route exact path="/payment/:ccode/:moviename" component={Payment} />
        <Route exact path="/contactus" component={Contact} />
        <Route exact path="/app/wowclub" component={Appwowclub} />

        <Route
          path="/private-screening"
          exact
          component={() => <Contact type={`ps`} />}
        />
        <Route
          path="/now-showing"
          exact
          component={() => <Homepage tab={"ns"} />}
        />

        <Route
          path="/upcoming"
          exact
          component={() => <Homepage tab={"up"} />}
        />

        {/* <Route exact path="/offers" component={Offers} /> */}
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/app/offers" component={Offers} />
        <Route exact path="/wow-factors/gold-class" component={GoldClass} />
        <Route exact path="/wow-factors/comfy" component={Comfy} />
        <Route exact path="/app/wow-factors/gold-class" component={GoldClass} />
        <Route exact path="/app/wow-factors/comfy" component={Comfy} />
        <Route exact path="/wow-factors/wow-xl" component={WowXL} />
        <Route exact path="/app/wow-factors/wow-xl" component={WowXL} />
        <Route exact path="/wow-factors/kids" component={Kids} />
        <Route exact path="/app/wow-factors/kids" component={Kids} />

        <Route exact path="/careers" component={Careers} />
        <Route
          exact
          path="/upcomingdetails/:movieid"
          component={UpcomingDetails}
        />
        <Route exact path="/offerdetail/:offerid" component={offerDetail} />

        {/* <Route exact path="/offersnew" component={Offers} /> */}
      </Switch>
    </div>
  ) : (
    <div>{swal("", "Please check your network connection")}</div>
  );
}

export default App;
