import React from "react";
import FACTORS_DATA from "./factors.data";
import FactorsPreview from "../factors-preview/FactorsPreview.component";
import "./Factors.style.css";
import Text from "react-text";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import axios from "axios";
class Factors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: FACTORS_DATA,
      factors: [],
    };
  }
  componentDidMount() {
    const factors = async () => {
      try {
        const resp = await axios
          .get(Constants.api + "getfactor?id=0", "", {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);

              this.setState({
                factors: resp.data.output,
              });
              console.log("factors");
              console.log(this.state.upcoming);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    factors();
  }
  render() {
    const { collections } = this.state;
    return (
      <div className="factors container" id="wow-factor">
        <h3>
          {localStorage.getItem("language") != "ar" ? (
            <Text>wow factors</Text>
          ) : (
            <Text>عوامل مدهشة</Text>
          )}
        </h3>
        <div className="factors-preview-collection">
          {this.state.factors.map(function (item, i) {
            let otherAdditionalProps = {};
            if (localStorage.getItem("language") != "ar") {
              otherAdditionalProps = {
                title: item.title,
                description: item.description,
                imageUrl: item.i,
                url: item.url,
              };
            } else {
              otherAdditionalProps = {
                title: item.title_ar,
                description: item.description_ar,
                imageUrl: item.i,
                url: item.url,
              };
            }
            return <FactorsPreview key={i} {...otherAdditionalProps} />;
          })}
        </div>
      </div>
    );
  }
}
export default Factors;
