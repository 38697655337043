import React, { Component } from "react";
// import Header from './Header';
// import Footer from './Footer';
import * as Constants from "../../Constants/index";
import Loading from "../../Constants/Loading";
import ProgressBar from "react-bootstrap/ProgressBar";
// import '../App.css';
import axios from "axios";
import "./MovieSession.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { FaClock, FaShareAlt } from "react-icons/fa";
import TrailerModal from "../trailer/TrailerModal.component";
import "../movie-details/MovieDetails.style.css";
// import MovieDetails from "../movie-details/MovieDetails.component";
// import DisplayWeek from '../display-week/DisplayWeek.component';
import MovieInfoDetails from "../movie-info-detail/MovieInfoDetails.component";
import swal from "sweetalert";
import Text from "react-text";
// import ShowTime from '../show-time/ShowTime.component';
import Modal from "react-modal";

class MovieSession extends Component {
  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: "",
    lang: "ALL",
    format: "ALL",
    price: "ALL",
    time: "ALL",
    cinetype: "ALL",
    special: "ALL",
    hc: "ALL",
    output: {},
    lngs: [],
    cinemas: [],
    days: [],
    selectedDate: "",
    selectedDay: "",
    showTrailer: false,
  };

  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataNS.append("mid", this.state.amcode);
    formDataNS.append("lat", this.state.lat);
    formDataNS.append("lng", this.state.lng);
    formDataNS.append("date", this.state.date);
    // formDataNS.append('date', '2020-12-09');
    formDataNS.append("userid", 0);
    formDataNS.append("lang", this.state.lang);
    formDataNS.append("format", this.state.format);
    formDataNS.append("price", this.state.price);
    formDataNS.append("time", this.state.time);
    formDataNS.append("cinetype", this.state.cinetype);
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);

    const movieSessionRequest = async () => {
      try {
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "content/msessionsfilters", formDataNS, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                output: resp.data.output,
              });
              let lngsArray = [];
              Object.keys(resp.data.output.lngs).forEach(function (key) {
                lngsArray.push(resp.data.output.lngs[key]);
              });

              let daysArray = [];
              Object.keys(resp.data.output.dys).forEach(function (key) {
                daysArray.push(resp.data.output.dys[key]);
              });

              let sDay = "";
              let sD = "";
              Object.keys(daysArray).forEach(function (item, index) {
                if (index == 0) {
                  console.log(daysArray[index].wd);
                  sDay = daysArray[index].wd;
                  sD = daysArray[index].d;
                  return;
                }
              });

              let cinemasArray = [];
              Object.keys(resp.data.output.cinemas).forEach(function (key) {
                cinemasArray.push(resp.data.output.cinemas[key]);
              });

              this.setState({
                lngs: lngsArray,
                cinemas: cinemasArray,
                days: daysArray,
                selectedDay: sDay,
                selectedDate: sD,
              });
            } else {
              console.log(resp.data.msg);
              alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    movieSessionRequest();
  }

  render() {
    const selectDate = (dateSelected, daySelected, ddSelected) => {
      console.log(this.state.date);
      console.log(dateSelected);
      console.log(daySelected);
      let formDataDS = new FormData(); //formdata object
      formDataDS.append("mid", this.state.amcode);
      formDataDS.append("lat", this.state.lat);
      formDataDS.append("lng", this.state.lng);
      formDataDS.append("date", dateSelected);
      // formDataDS.append('date', '2020-12-09');
      formDataDS.append("userid", 0);
      formDataDS.append("lang", this.state.lang);
      formDataDS.append("format", this.state.format);
      formDataDS.append("price", this.state.price);
      formDataDS.append("time", this.state.time);
      formDataDS.append("cinetype", this.state.cinetype);
      formDataDS.append("av", Constants.av);
      formDataDS.append("pt", Constants.pt);
      formDataDS.append("did", Constants.did);
      try {
        this.setState({
          loading: 1,
        });
        // const resp =
        axios
          .post(Constants.api + "content/msessionsfilters/", formDataDS, {
            headers: { "Accept-Language": localStorage.getItem("language") },
          })
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let cinemasArray = [];
              Object.keys(resp.data.output.cinemas).forEach(function (key) {
                cinemasArray.push(resp.data.output.cinemas[key]);
              });

              this.setState({
                cinemas: cinemasArray,
              });

              console.log(this.state.cinemas);
              var element = document.getElementById("otherDateFilter");
              element.classList.remove("active");
              // var element = document.getElementById('otherDateFilter');
              // element.classList.remove("active");
              this.setState({ selectedDay: daySelected });
              this.setState({ selectedDate: ddSelected });
            } else {
              console.log(resp.data.msg);
              alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    var seld = this.state.selectedDate;

    const daysRender2 = this.state.days
      .filter((item, idx) => idx < 7)
      .map(function (item, i) {
        var id = "otherDateFilter";

        if (seld == item.d) var c = "btn btn-week active";
        else var c = "btn btn-week";
        return (
          <>
            <button
              className={c}
              id={id}
              onClick={() => selectDate(item.dt, item.wd, item.d)}
            >
              {item.wd} <br />
              {item.d}
            </button>
          </>
        );
      });

    const outputs = this.state.output;
    console.log(outputs);
    console.log(this.state.selectedDate);
    console.log(this.state.selectedDay);
    // console.log(JSON.stringify(days));
    const MovieDetails = ({ nm, len, gnr, lng, lngs, mih, miv, vdo, cert }) => {
      return (
        <div
          className="movie-details container mobile-container"
          // style={{ backgroundImage: `url(${mih})` }}
        >
          <div className="booking-poster">
            <img src={miv} alt="img" />
          </div>
          <div className="booking-movie-details">
            <h3>{nm}</h3>
            <p>
              {len ? <FaClock /> : ""} {len} | {lng} | {cert} | {gnr}
            </p>
            {vdo != "" ? (
              <div>
                <button
                  className="btn btn-normal btn-watch-trailer"
                  onClick={() => this.setState({ showTrailer: true })}
                >
                  <a href="#">
                    {localStorage.getItem("language") != "ar" ? (
                      "Watch Trailer"
                    ) : (
                      <Text>مشاهدة اعلان</Text>
                    )}
                  </a>
                </button>{" "}
                <FaShareAlt className="share hidden" />
              </div>
            ) : (
              ""
            )}
            {/* <button className="btn btn-normal"><a href="#">Watch Trailer</a></button> <FaShareAlt className="share"/> */}
          </div>


         
           
        

          

        </div>
      );
    };
    const cinemaRender = this.state.cinemas.map(function (mcinema, i) {
      // '/seatlayout/' + mcinema.cn.toString().toLowerCase().replaceAll(' ', '-') + '/' + this.state.mname + '?mcode=' + show.mc
      ///seatlayout/pvr-sapphire-pacific-d21-dwarka/suraj-pe-mangal-bhari?mcode=HO00017429&sessionid=756&cinemacode=PASA&screenId=5&bundleAllowed=true
      return (
        <>
          <div class="cinema-holder" key={i}>
            <div class="cinema-title">
              <h4 class="title">{mcinema.cn}</h4>
            </div>
            {mcinema.childs.map((cinema, j) => {
              return (
                <>
                  {/* {cinema.ccid} */}
                  {cinema.sws.map((sws, k) => {
                    return (
                      <>
                        <ul class="type-time-slots">
                          {sws.s.map((show, l) => {
                            let showClass = "slot ";
                            if (show.ss == 1) {
                              showClass += "text-success";
                            } else if (show.ss == 2) {
                              showClass += "text-warning";
                            } else if (show.ss == 3) {
                              showClass += "text-danger";
                            } else {
                              showClass += "text-default";
                            }
                            let availableBar =
                              ((show.ts - show.as) / show.ts) * 100;
                            console.log(availableBar);
                            return (
                              <>
                                <li>
                                  <p className="cin-type">{cinema.ccn}</p>

                                  <p className="lang-time">{sws.lng}</p>
                                  <div className="timing-box">
                                    {show.ss == 0 || show.ss == 3 ? (
                                      <a disabled="disabled" class={showClass}>
                                        {show.st}
                                      </a>
                                    ) : (
                                      <a
                                        href={
                                          "/seatlayout/" +
                                          mcinema.cn
                                            .toString()
                                            .toLowerCase()
                                            .replaceAll(" ", "-") +
                                          "/movie?mcode=" +
                                          show.mc +
                                          "&sessionid=" +
                                          show.sid +
                                          "&cinemacode=" +
                                          cinema.ccid
                                        }
                                        class={showClass}
                                      >
                                        {show.st}
                                      </a>
                                    )}
                                    <ProgressBar
                                      now={availableBar}
                                      className="seats-bar"
                                    />
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        </>
      );
    });

    const dateHover = () => {
      var element = document.getElementById("otherDateFilter");
      element.classList.add("active");
    };

    return (
      <>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.output.vdo} />
            </Modal>

            {/* <div className="trailer-box">
              <button
                className="cross-trailer"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.output.vdo} />
            </div> */}
          </>
        ) : (
          ""
        )}
        <div className="booking">
          <div
            className="booking-upper"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url(${this.state.output.mih})`,
            }} /* style={{backgroundImage: `url(${this.state.output.mih})`}} */
          >
            {<MovieDetails {...outputs} />}
            <div className="current-week">
              <div className="cureent-container container">
                {/* <DisplayWeek sdate={this.state.selectedDate} sday={this.state.selectedDay}/> */}
                <div className="display-week">
                  <div className="filling-data">{daysRender2}</div>
                  <div className="filling-details">
                    <p>
                      {localStorage.getItem("language") != "ar" ? (
                        "AVAILABLE"
                      ) : (
                        <Text>متوفرة</Text>
                      )}
                    </p>
                    <p>
                      {localStorage.getItem("language") != "ar" ? (
                        "FILLING FAST"
                      ) : (
                        <Text>ملء سريع</Text>
                      )}
                    </p>
                    <p>
                      {localStorage.getItem("language") != "ar" ? (
                        "SOLD OUT"
                      ) : (
                        <Text>نفذ</Text>
                      )}
                    </p>
                    <p>
                      {localStorage.getItem("language") != "ar" ? (
                        "LAPSED"
                      ) : (
                        <Text>انقضت</Text>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-lower hidden-mobile">
            <div className="bookin-lower-contain container hidden-mobile">
              <div className="movie-info hidden-mobile">
                <h4>
                  {localStorage.getItem("language") != "ar" ? (
                    "movie info"
                  ) : (
                    <Text>معلومات الفيلم</Text>
                  )}
                </h4>
                {<MovieInfoDetails {...outputs} />}
              </div>

              {this.state.loading == 1 ? (
                <Loading />
              ) : (
                <div className="showtimes">
                  <Tabs
                    defaultActiveKey="showtimes"
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      eventKey="showtimes"
                      title={
                        localStorage.getItem("language") != "ar" ? (
                          "showtimes"
                        ) : (
                          <Text>أوقات العرض</Text>
                        )
                      }
                      tabClassName="booking-time"
                    >
                      {cinemaRender}
                    </Tab>
                    <Tab
                      eventKey="synopsis"
                      title={
                        localStorage.getItem("language") != "ar" ? (
                          "Synopsis"
                        ) : (
                          <Text>ملخص</Text>
                        )
                      }
                      tabClassName="booking-time"
                    >
                      <p>{this.state.output.p}</p>
                    </Tab>


                    <Tab
                      eventKey="Movie-info"
                      title={
                        localStorage.getItem("language") != "ar" ? (
                          "Movie-info"
                        ) : (
                          <Text>ملخص</Text>
                        )
                      }
                      tabClassName="booking-time"
                    >
                      <div className="bookin-lower-contain container booking-mobile">
              <div className="movie-infos booking-mobile">
                <h4>
                  {localStorage.getItem("language") != "ar" ? (
                    ""
                  ) : (
                    <Text>معلومات الفيلم</Text>
                  )}
                </h4>
                {<MovieInfoDetails {...outputs} />}
              </div>

             
            </div>
                    </Tab>



                  </Tabs>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <Header />  */}

        {/* <Footer /> */}
        {/* <Modal show={this.state.setShow} animation={false}>
    <Modal.Header closeButton onClick={closetrailer}>
        <Modal.Title>{this.state.movietitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>

        <><iframe width="100%" height="400"
            src={this.state.videoUrl}>
        </iframe></>
    </Modal.Body>
    <Modal.Footer>
        {/* <img src={require('../assets/logo1.png').default} alt="img" width="200" /> */}
        {/* </Modal.Footer> */}

        {/* </Modal> */}
      </>
    );
  }
}

export default MovieSession;
