import React from "react";
import "./about.style.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import Text from "react-text";

class AppPrivacy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div className="header">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-banner"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="banner-custom-dot-list-style"
          >
            <img
              src={require("../../assets/about_four.png").default}
              className="d-block w-100"
              alt="img"
            />
          </Carousel>
          <div className="about_wow">
            <div className="know_more">

              <Text>
                {" "}
                <h3>Cancellation Policy</h3>
                <ul>
                  <li>
                    Tickets once purchased cannot be cancelled, exchanged or
                    refunded.
                  </li>
                  <li>
                    Tickets purchased for a particular multiplex are valid for
                    that multiplex only.
                  </li>
                </ul>
                <h3>Privacy Policy</h3>
                <ul>
                  <li>
                    Children below the age of 18 years cannot be admitted for
                    movies certified 'R18'.
                  </li>
                  <li>
                    Please carry proof of age for movies certified 'R18'.
                  </li>
                  <li>
                    In case a ticket is lost or misplaced, a duplicate ticket
                    cannot be issued.
                  </li>
                  <li>
                    The holder of a physical ticket/E-Ticket with registered
                    Mobile Number of that particular transaction is deemed to
                    be the owner of the ticket.
                  </li>
                  <li>
                    Wow Cinemas does not take responsibility for loss or
                    damage to articles that patrons may unknowingly carry
                    inside the Auditorium.
                  </li>
                </ul>
                <h3>Terms and Conditions</h3>
                <ul>
                  <li>
                    Please purchase tickets for children above the age of 3
                    years.
                  </li>
                  <li>
                    Outside food and beverages are not allowed inside the
                    cinema premises.
                  </li>
                  <li>
                    A convenience fee per ticket is levied on all tickets
                    booked online.
                  </li>
                  <li>
                    Ticket holders are required to abide by the policies laid
                    down by the Wow Cinemas Management.
                  </li>
                  <li>Entry is allowed only for valid ticket holders.</li>

                  <li>
                    Patrons under the influence of alcohol or drugs will not
                    be allowed inside the Cinema Premises.
                  </li>
                  <li>
                    Seat Layout Page for Wow Cinemas is for representational
                    purpose only and actual seat layout might vary.
                  </li>
                  <li>
                    Items like laptop, cameras, knives, weapons, lighter,
                    match box, cigarettes, firearms and all types of
                    inflammable objects are strictly prohibited.
                  </li>
                  <li>
                    Items like carry-bags, eatables, helmets, handbags are not
                    allowed inside the theatres and are strictly prohibited.
                  </li>
                  <li>
                    Entry will be denied for patron/s with any symptom related
                    to COVID-19.
                  </li>
                  <li>
                    All patrons shall be solely responsible and liable for any
                    risks pertaining to the Covid-19 virus upon entry to
                    theatre premises. Wow Cinemas or its Directors, officers,
                    employees or its group companies or insurers and any other
                    related parties shall not be liable directly or indirectly
                    in any manner whatsoever if any patron at any time is
                    infected with the Covid-19 virus or for any injury whether
                    personal or incidental or otherwise and/or for any
                    special, indirect, indirect, punitive, incidental, or
                    consequential damages or any damages whatsoever.
                  </li>
                  <li>Please wear a mask at all times.</li>
                  <li>
                    Non-invasive temperature check is mandatory at Cinema
                    entry.
                  </li>
                  <li>Please follow all social distancing norms.</li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AppPrivacy;
