import React from "react";
import MOVIE_DATA from "../now-showing/movie.data";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./Top.style.css";
//import MoviePreview from "../movie-preview/MoviePreview.component";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import play from "../../assets/play.png";
import TrailerModal from "../trailer/TrailerModal.component";
import Modal from "react-modal";

class Top extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     collections: MOVIE_DATA
    // }
    this.state = {
      collections: MOVIE_DATA,
      output: {},
      nowShowing: [],
      loading: 1,
      genre: "",
      trailerUrl: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);
    formDataNS.append("userid", 0);
    formDataNS.append("dtmsource", "local");
    formDataNS.append("mobile", "");

    const nowShowingRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "content/nowshowing", formDataNS)
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp);
              let tmArray = [];
              Object.keys(resp.data.output.tm).forEach(function (key) {
                tmArray.push(resp.data.output.tm[key]);
              });
              let nowShowingArray = [];
              Object.keys(resp.data.output.mv).forEach(function (key) {
                nowShowingArray.push(resp.data.output.mv[key]);
              });
              if (tmArray.length < 3)
                this.setState({
                  nowShowing: nowShowingArray,
                });
              else
                this.setState({
                  nowShowing: tmArray,
                });
              console.log("nowShowing");
              console.log(this.state.nowShowing);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
        this.setState({
          loading: 1,
        });
      }
    };
    nowShowingRequest();
  }
  handleChange = (event) => {
    this.setState({
      genre: event.target.value,
    });
  };
  render() {
    const MovieGenre = ({ genre, history, linkUrl, match, trailer }) => (
      <>
        {" "}
        <div className="movie-genre">
          <p>{genre}</p>

          <div className="book">
            <a href={linkUrl} className="club book-now">
              {localStorage.getItem("language") != "ar" ? (
                "BOOK NOW"
              ) : (
                <Text>احجز الآن</Text>
              )}
            </a>
            <a
              onClick={() =>
                this.setState({ showTrailer: true, trailerUrl: trailer })
              }
            >
              <img className="icon" src={play} alt="img" />
            </a>
          </div>
          <div className="trailer-btn">
            {trailer != "" ? (
              <button className="club book-now">
                <a>
                  {localStorage.getItem("language") != "ar" ? (
                    "Watch Trailer"
                  ) : (
                    <Text>مشاهدة اعلان</Text>
                  )}
                </a>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
    const MoviePreview = ({
      title,
      language,
      release,
      imageUrl,
      genre,
      linkUrl,
      trailer,
    }) => (
      <div className={`${release ? "release" : ""} movie-preview`}>
        <div className="img-showing">
          <img src={imageUrl} />
          <MovieGenre genre={genre} linkUrl={linkUrl} trailer={trailer} />
        </div>
        <h4>{title}</h4>
        {release ? (
          <p className="releasing">
            Releasing On <br />
            <span className="release-date">{release}</span>
          </p>
        ) : (
          <p>{language}</p>
        )}
      </div>
    );
    const { outputs } = this.state.output;
    const nowshowing = this.state.nowShowing
      .filter((item, idx) => idx < 3)
      .map(function (item, i) {
        let nameForUrl = item.n.toString().toLowerCase().replaceAll(" ", "-");
        const otherCollectionProps = {
          id: i,
          title: item.n,
          language: item.lng,
          genre: item.genre,
          director: item.mdirector,
          writers: "Christopher Markus and Stephen McFeely",
          starring: item.martists,
          details: item.mlength,
          imageUrl: item.miv,
          slug: "MIB",
          linkUrl:
            "/moviesessions/" +
            nameForUrl +
            "/" +
            item.id +
            "?language=" +
            item.lng,

          releasing: item.mopeningdate,
          trailer: item.t,
        };
        return (
          <>
            <div className="rank">
              <span>#{i + 1}</span>
              <MoviePreview key={i} {...otherCollectionProps} />
            </div>
          </>
        );
      });
    return (
      <>
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal trailerUrl={this.state.trailerUrl} />
            </Modal>
          </>
        ) : (
          ""
        )}
        <div className="top container">
          <div className="top-content">
            {localStorage.getItem("language") != "ar" ? (
              <Text>
                <h3>Top 3</h3>
                <h4>Movies of the week</h4>
              </Text>
            ) : (
              <Text>
                <h3>أفضل 3 أفلام في الأسبوع</h3>
              </Text>
            )}
            <p></p>
            {/* <button className="btn btn-ghost cinemas-btn">
            <a href="#">
              CINEMAS{" "}
              <span className="icons-arr">
                <FaLongArrowAltRight />
              </span>
            </a>
          </button> */}
          </div>
          {
            // outputs.filter((item,idx) => idx < 3).map(({id, ...otherAdditionalProps}) => (
            //     <div className="rank">
            //     <span>#{id}</span>
            //         <MoviePreview key={id} {...otherAdditionalProps}/>
            //     </div>
            // ))
            nowshowing
          }
        </div>
      </>
    );
  }
}

export default Top;
